import React, { useState, useEffect, Fragment } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import Header from "../components/headerNA";
import Footer from "../components/footer";
import { configStyle } from '../services/configStyle'
import { status, statusError, sendEventClick, generateZip } from '../services/data';
import Ayuda from './ayuda';
import Loader from '../components/loader';
import { isIOS } from 'react-device-detect';

const PreparacionHuella = () => {
    const history = useHistory();
    const [dataOtorgante, setDataOtorgante] = useState([])
    const [showCancel, setShowCancel] = useState(false)
    const [apiKey, setApiKey] = useState('')
    const { ruta } = useParams();
    const [showHelp, setShowHelp] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {

        let apikeyOtorgante = localStorage.getItem("apikeyOtorgante")
        if (apikeyOtorgante !== null) {
            setApiKey(apikeyOtorgante)
        }

        let dataOtorganteLS = localStorage.getItem("dataOtorgante")
        if (dataOtorganteLS != null) {
            setDataOtorgante(JSON.parse(dataOtorganteLS))
        }
        status("preparacion_huellas", "Preparacion Huellas");
    }, [])

    useEffect(() => {
        configStyle(dataOtorgante)
    }, [dataOtorgante])

    const statusE = async () => {
        setLoading(true);
        sendEventClick('Preparacion Huellas','SI', {status: 'CANCELADO'});
        let data = "Cancelado"
        localStorage.setItem('flag', 'cancelado');
        await generateZip('TRUNCOS', 'trunco', isIOS);
        statusError("preparacion_huellas", data, "cancelado");
        setTimeout(() => {
            history.push("/" + (ruta !== "pdf_video_token" ? apiKey : `pdf_token?uuid=${localStorage.getItem("uuidUser")}&idOtorgante=${localStorage.getItem("idOtorgante")}&fecha=${localStorage.getItem("fechavigencia")}`));
            setLoading(false);
        }, 300);
    }

    return (
        <>
            {(showCancel) ?
                <div className="modal fade show" style={{ display: "block" }} role="dialog">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Cancelar proceso</h5>
                                <button onClick={e => setShowCancel(false)} className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <p>En este momento se cancelará el proceso, ningún dato será guardado y perderás el avance, esta acción no podrá deshacerse <br /> ¿Deseas cancelar?</p>
                            </div>
                            <div className="modal-footer">
                                <button onClick={e => {setShowCancel(false); sendEventClick('Preparacion Huellas','NO', {});}} className="btn btn-secondary" data-dismiss="modal">NO</button>
                                <button type="button" onClick={e => statusE()} className="btn btn-raised btn-primary main_bg_color">SI</button>
                            </div>
                        </div>
                    </div>
                </div> : ("")}
            <div className="main_gradient">
                <Header ruta="preparacion_huellas" show={() => setShowHelp(true)}/>
                <div>
                    <div className="main_text_container">
                        <h1 className="animate__animated">Captura tus huellas</h1>
                        <p className="animate__animated">Es <b>importante</b> que <b>tus huellas</b> se puedan <b>capturar</b> con <b>claridad</b>, <b>limpias</b> y <b>sin recortes</b> recientes.</p>
                        <img src="images/cap_huellas_web.gif" width="100%" alt="" />
                    </div>
                </div>

                <div className="action_buttons noscroll_screen">
                    <Link to={{
                        pathname: "/captura_huellas"
                    }} className="btn btn-raised btn-primary forcewidth100 main_bg_color"
                    onClick={() => {
                        sendEventClick('Preparacion Huellas','CAPTURAR', {});                    
                    }} >
                        CAPTURAR</Link>
                    <button onClick={e => {
                        setShowCancel(true);
                        sendEventClick('Preparacion Huellas','CANCELAR', {});                    
                    }} className="btn btn-primary forcewidth100 main_color">
                        CANCELAR</button>
                </div>
                {/* <Footer /> */}
            </div>
            <div className={(showCancel ? "modal-backdrop show" : "")}></div>
            {showHelp ? <Ayuda hide={() => setShowHelp(false)} /> : ""}
            {loading && <Loader/>}
        </>
    )

}


export default PreparacionHuella