import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { obtenerIceServers, mapearEvento } from '../../services/data';
import { evento } from '../../services/data';
import { mapearError, mapearRespuesta } from '../../components/captura_identificacion/utilities/response/map_response';
import { revisarTurno, actualizarTurno } from '../../services/api.js';
import Loader from '../../components/loader';
import useNavigatorOnLine from '../../utilities/isNavigatorOnline'

var socket = null;
var myPeerConnection;

const Receiver = () => {
	const history = useHistory();
	const isOnline = useNavigatorOnLine();
	const [stream, setStream] = useState();
	const [newStream, setNewStream] = useState(false);
	const [loading, setLoading] = useState(false);
	const [nombreAgente, setNombreAgente] = useState('');
	const [isAudioEnable, setIsAudioEnable] = useState(true);
	const [isVideoEnable, setIsVideoEnable] = useState(true);
	const [errorNetwork, setErrorNetwork] = useState(false);
	const [reconnectVideoCall, setReconnectVideoCall] = useState(false);
	const [estadoConexion, setEstadoConexion] = useState('');
	const myVideo = useRef()
	const userVideo = useRef()
	const wss = 'wss://api.devdicio.net:8444/v1/sec_dev_signal/call/'
	const apikey = localStorage.getItem('apikeyOtorgante');
	const uuidTransaccion = localStorage.getItem('uuidTrx');

	useEffect(() => {
		myPeerConnection = null;
		startCall();
		revisarFinalizar();
		return () => {
			let localVideo = myVideo.current;
			if (localVideo) {
				localVideo.srcObject.getTracks().forEach(track => track.stop());
				localVideo.removeAttribute("src");
			}
		}
	}, []);

	useEffect(() => {
		if (isOnline) {
			setErrorNetwork(false);
			evento('Estado navegador', 'Videollamada', { conexion: isOnline }, true);
			if (!estadoConexion) {
                setEstadoConexion('conectado');
            } else if ('desconectado') {
                setEstadoConexion('restablecida');
            }
		} else {
			setEstadoConexion('desconectado');
			//console.log('Estado navegador', isOnline);
			setErrorNetwork(true);
			//evento('Estado navegador', 'Videollamada', { conexion: isOnline }, false);
		}
		return () => { };
	}, [isOnline]);


	useEffect(() => {
        if (estadoConexion === 'restablecida') {
            
        }
        return () => { };
    }, [estadoConexion]);

	const startCall = async () => {
		try {
			const response = await obtenerIceServers();
			if (response) {
				const { data, status } = response;
				if (status && status === 200) {
					evento('Generar Ice Servers', 'Success', mapearRespuesta(status, data), true);
					let nuevosServer = data.v.iceServers;
					socket = new WebSocket(wss + uuidTransaccion + "?apikey=" + apikey);
					// Connection opened
					socket.addEventListener('open', (event) => {
						evento('Conexión abierta', 'Videollamada', { evento: mapearEvento(event) }, true);
						setTimeout(() => {
							invite(nuevosServer)
						}, 100);
					});
					// Listen for messages
					socket.addEventListener('message', (event) => {
						//console.log('Message from server', event.data);
						event.data.text().then(async (res) => {
							//console.log("JSON_res:", JSON.parse(res));
							const dato = JSON.parse(res);
							evento('Respuesta del mensaje', 'Videollamada', dato, true);
							switch (dato.type) {
								case "SessionDescription":
									//console.log("SessionDescription", dato.payload);
									//myPeerConnection.setRemoteDescription(dato.payload)
									if (dato.payload.type === "offer") {
										createAnswer(dato)
									}
									//console.log("peerConnA",myPeerConnection);
									break;
								case "IceCandidate":
									myPeerConnection.addIceCandidate({
										candidate: dato.payload.sdp,
										sdpMid: dato.payload.sdpMid, // don't make it up, you get this in onicecandidate
										sdpMLineIndex: dato.payload.sdpMLineIndex, // don't make it up, you get this in onicecandidate
									})
									break;
								case "Connection":
									setTimeout(async () => {
										let objeto = { idStatusTurno: 'EN_LLAMADA' };
										const response = await actualizarTurno(objeto);
										evento('Actualizar turno', 'Videollamada', response, true);
									}, 2000);
									break;
								default:
									break;
							}
						});

					});
				}
			}
		} catch (error) {
			let errorMapeado = mapearError(error);
			evento('Generar Ice Servers', errorMapeado.tipoError, errorMapeado.objetoError, false);
			startCall();
		}

	}

	const createAnswer = async (dato) => {
		//console.log("*** CreateAnswer", dato);
		evento('Crear respuesta', 'Videollamada', { data: dato }, true);
		try {

			//console.log("---> Setting remote description to the answer");
			evento('Configuración de la descripción remota', 'Videollamada', { payload: dato.payload }, true);
			await myPeerConnection.setRemoteDescription(dato.payload);

			//console.log("---> Creating Answer")

			const answer = await myPeerConnection.createAnswer();
			evento('Respuesta', 'Videollamada', { respuesta: answer }, true);
			//console.log("answer", answer);

			/*
			if (myPeerConnection.signalingState != "stable") {
				console.log("     -- The connection isn't stable yet; postponing...")
				return;
			}
			*/

			//console.log("---> setLocalDescription");
			myPeerConnection.setLocalDescription(answer)

			//console.log("---> Sending the answer to the remote peer");
			sendToServer({
				payload: answer,
				type: "SessionDescription",
			});

		} catch (error) {
			//console.log("*** The following error occurred while handling the negotiationneeded event:" + err);
			evento('Crear respuesta', 'Error', { error: error }, true);

		};
	}

	const invite = async (servers) => {
		if (myPeerConnection) {
			//alert("You can't start a call because you already have one open!");
			evento('Conexión Peer', 'Videollamada', { peerConnection: myPeerConnection }, true);
		} else {
			createPeerConnection(servers);
			const audioId = await getConnectedDevices('audioinput', 'Speakerphone');
			const cameraId = await getConnectedDevices('videoinput');
			const mediaStream = await openCamera(cameraId, audioId);
			if (mediaStream) {
				setLoading(false);
				let objeto = { idStatusTurno: 'EN_LLAMADA' };
				const response = await actualizarTurno(objeto);
				evento('Actualizar turno', 'Videollamada', response, true);
				setStream(mediaStream);
				myVideo.current.srcObject = mediaStream;
				mediaStream.getTracks().forEach(track => myPeerConnection.addTrack(track, mediaStream));
			}
		}
	}

	const createPeerConnection = (servers) => {
		let turnServers = [];
		for (let index = 1; index < servers.urls.length; index++) {
			turnServers.push(servers.urls[index])
		}
		//console.log("Setting up a connection...");
		myPeerConnection = new RTCPeerConnection(
			{
				iceServers: [{ urls: [servers.urls[0]] }, {
					username: servers.username,
					credential: servers.credential,
					urls: turnServers
				}]
			}
		)
		evento('Nueva conexión Peer', 'Videollamada', {
			peerConnection: {
				iceServers: [{ urls: [servers.urls[0]] }, {
					username: servers.username,
					credential: servers.credential,
					urls: turnServers
				}]
			}
		}, true);
		myPeerConnection.onicecandidate = handleICECandidateEvent;
		myPeerConnection.ontrack = handleTrackEvent;
		myPeerConnection.onnegotiationneeded = handleNegotiationNeededEvent;
		myPeerConnection.onremovetrack = handleRemoveTrackEvent;
		myPeerConnection.oniceconnectionstatechange = handleICEConnectionStateChangeEvent;
		myPeerConnection.onicegatheringstatechange = handleICEGatheringStateChangeEvent;
		myPeerConnection.onsignalingstatechange = handleSignalingStateChangeEvent;
	}

	const handleICECandidateEvent = (event) => {
		if (event.candidate) {
			evento('Manejando evento ICE candidate', 'Videollamada', event.candidate, true);
			//console.log("eventIceCandidate",event);
			sendToServer({
				payload: {
					sdp: event.candidate.candidate,
					sdpMid: event.candidate.sdpMid,
					sdpMLineIndex: event.candidate.sdpMLineIndex
				},
				type: "IceCandidate"
			});
		}
	}

	const handleTrackEvent = (event) => {
		evento('Manejando evento Track', 'Videollamada', { evento: mapearEvento(event) }, true);
		setNewStream(true);
		if (userVideo.current) {
			userVideo.current.srcObject = event.streams[0];
		}
		//document.getElementById("hangup-button").disabled = false;
	}

	const handleNegotiationNeededEvent = async (event) => {
		evento('Manejando evento Negotiation Needed', 'Videollamada', { evento: mapearEvento(event) }, true);
	}

	const handleRemoveTrackEvent = (event) => {
		evento('Manejando evento Remove Track', 'Videollamada', { evento: mapearEvento(event) }, true);
		let tagVideo = userVideo.current;
		if (tagVideo) {
			let stream = tagVideo.srcObject;
			let trackList = stream.getTracks();
			if (trackList.length === 0) {
				closeVideoCall(true);
			}
		}
	}

	const handleICEConnectionStateChangeEvent = (event) => {
		switch (myPeerConnection.iceConnectionState) {
			case "closed":
				evento('Manejando evento ICE connection state change', 'Videollamada - Closed', { iceConnectionState: myPeerConnection.iceConnectionState, event: mapearEvento(event) }, true);
				break;
			case "failed":
				evento('Manejando evento ICE connection state change', 'Videollamada - Failed', { iceConnectionState: myPeerConnection.iceConnectionState, event: mapearEvento(event) }, true);
				break;
			case "disconnected":
				evento('Manejando evento ICE connection state change', 'Videollamada - Disconnected', { iceConnectionState: myPeerConnection.iceConnectionState, event: mapearEvento(event) }, true);
				closeVideoCall(true);
				break;
			default:
				evento('Manejando evento ICE connection state change', 'Videollamada', { iceConnectionState: myPeerConnection.iceConnectionState, event: mapearEvento(event) }, true);
				break;
		}
	}

	const handleICEGatheringStateChangeEvent = (event) => {
		evento('Manejando evento ICE gathering state change', 'Videollamada', { evento: mapearEvento(event) }, true);
		// Our sample just logs information to console here,
		// but you can do whatever you need.
	}

	const handleSignalingStateChangeEvent = (event) => {
		//console.log("*** WebRTC signaling state changed to: " + myPeerConnection.signalingState);
		evento('Manejando evento Signaling state change', 'Videollamada', { SignalingState: myPeerConnection.signalingState }, true);
		switch (myPeerConnection.signalingState) {
			case "closed":
				closeVideoCall();
				break;
			default:
				break;
		}
	};

	const sendToServer = (msg) => {
		//var msgJSON = JSON.stringify(msg);
		let msgJSON = Buffer.from(JSON.stringify(msg))
		evento('Enviar mensaje al servidor', 'Videollamada', { message: msgJSON }, true);
		socket.send(msgJSON);
	}

	const closeVideoCall = (reconnect = false) => {
		let remoteVideo = userVideo.current;
		let localVideo = myVideo.current;
		if (myPeerConnection) {
			evento('Colgando llamada', ' Videollamada', { peerConnection: myPeerConnection }, true);
			//console.log("qutando myPeerConnection ");
			myPeerConnection.ontrack = null;
			myPeerConnection.onremovetrack = null;
			myPeerConnection.onremovestream = null;
			myPeerConnection.onicecandidate = null;
			myPeerConnection.oniceconnectionstatechange = null;
			myPeerConnection.onsignalingstatechange = null;
			myPeerConnection.onicegatheringstatechange = null;
			myPeerConnection.onnegotiationneeded = null;
			if (remoteVideo && remoteVideo.srcObject) {
				remoteVideo.srcObject.getTracks().forEach(track => track.stop());
				remoteVideo.removeAttribute("src");
				remoteVideo.removeAttribute("srcObject");
				setNewStream(false);
			}
			if (localVideo && localVideo.srcObject) {
				localVideo.srcObject.getTracks().forEach(track => track.stop());
				localVideo.removeAttribute("src");
				setStream(null);
			}
			myPeerConnection.close();
			myPeerConnection = null;
		}
		if (reconnect) {
			setReconnectVideoCall(true);
			setLoading(true);
			setTimeout(() => {
				setReconnectVideoCall(false);
				startCall();
				revisarFinalizar();
			}, 3000);
		} else {
			setTimeout(() => {
				history.push('/finalizado');
			}, 500);
		}
	}

	const handleGetUserMediaError = (error) => {
		if (error.name === "NotFoundError" || error.name === "DevicesNotFoundError") {
			//required track is missing 
			evento('Videollamada', 'User Media', { error: error.name, status: 'NO SE ENCONTRO DISPOSITIVO Y/O TRACK' }, true);
		} else if (error.name === "NotReadableError" || error.name === "TrackStartError") {
			//webcam or mic are already in use 
			evento('Videollamada', 'User Media', { error: error.name, status: 'LOS DISPOSITVOS SOLICITADOS ESTÁN EN USO' }, true);
		} else if (error.name === "OverconstrainedError" || error.name === "ConstraintNotSatisfiedError") {
			//constraints can not be satisfied by avb. devices 
			evento('Videollamada', 'User Media', { error: error.name, status: 'EL DISPOSITIVO NO PUEDE ALCANZAR LOS CONSTRAINTS' }, true);
		} else if (error.name === "NotAllowedError" || error.name === "PermissionDeniedError") {
			//permission denied in browser 
			evento('Videollamada', 'User Media', { error: error.name, status: 'PERMISOS DENEGADOS' }, true);
		} else if (error.name === "TypeError" || error.name === "TypeError") {
			//empty constraints object 
			evento('Videollamada', 'User Media', { error: error.name, status: 'CONSTRAINTS VACÍOS' }, true);
		} else {
			//other errors 
			evento('Videollamada', 'User Media', { error: error.toString(), status: 'OTRO TIPO DE ERROR' }, true);
		}
		closeVideoCall();
	}

	const muteAudio = () => {
		setIsAudioEnable(!isAudioEnable);
		if (myVideo.current) myVideo.current.srcObject.getAudioTracks().forEach(track => {
			track.enabled = !track.enabled
		});
	}

	const muteVideo = () => {
		setIsVideoEnable(!isVideoEnable);
		if (myVideo.current) myVideo.current.srcObject.getVideoTracks().forEach(track => {
			track.enabled = !track.enabled
		});
	}

	const getConnectedDevices = async (type, label = '') => {
		const devices = await navigator.mediaDevices.enumerateDevices();
		return devices.filter(device => device.kind === type && (label && (device.label === label)));
	}

	async function openCamera(cameraId, audioId) {
		const constraints = {
			'audio': {
				'echoCancellation': true,
				'deviceId': audioId ? audioId : 'default',
			},
			'video': {
				'deviceId': cameraId,
				'width': 320,
				'height': 240,
				'frameRate': {
					'ideal': 8,
					'max': 12,
				},
			},
		}
		return await navigator.mediaDevices.getUserMedia(constraints).catch(handleGetUserMediaError);
	}

	const revisarFinalizar = async () => {
		let tipo = '';
		let informacion = {};
		revisarTurno().then(async (response) => {
			if (response.status === 200) {
				informacion.status = response.status;
				informacion.payload = response.data.payload;
				setNombreAgente(response.data.payload.nombreAgente);
				evento('Asignacion de Turno', 'Success', informacion, true);
				if (response.data.payload.idStatusTurno === "TERMINADO") {
					closeVideoCall()
				} else {
					setTimeout(() => {
						revisarFinalizar();
					}, 3000);
				}
			}
		}).catch((error) => {
			if (error.response) {
				let { data, status } = error.response
				informacion.data = data;
				informacion.status = status;
				tipo = 'Error';
			} else {
				informacion.error = error.toString();
				tipo = 'Exception';
			}
			evento('Asignacion de Turno', tipo, informacion, false);
			setTimeout(() => {
				revisarFinalizar();
			}, 2000);
		})
	}

	return (
		<>
			<div id='videocall-container'>
				<div className="app">

					<div className='videos videos-grid'>
						<div className='video-container mirrored' style={{ flexBasis: newStream ? '50%' : '100%' }}>
							{stream && <>
								<video id="local_video" playsInline muted ref={myVideo} autoPlay />
								<div className='video-footer'>
									<input className="nickname" type="text" value="Tú" readOnly />
								</div>
								<div className="side align left children" style={{ alignItems: 'flex-end', zIndex: 2 }}>
									<div className="toolbar active">
										<a className={['button mute-audio', (!isAudioEnable) ? 'on' : ''].join(' ')} onClick={() => muteAudio()}>
											<span className={['icon material-icons', (isAudioEnable) ? 'icon-mic' : 'icon-mic_off'].join(' ')}>
											</span>
											<span className="tooltip">Encender/apagar micrófono</span>
										</a>
										<a className={['button mute-video', (!isVideoEnable) ? 'on' : ''].join(' ')} onClick={() => muteVideo()}>
											<span className={['icon material-icons', (isVideoEnable) ? 'icon-videocam' : 'icon-videocam_off'].join(' ')}>
											</span>
											<span className="tooltip">Encender/apagar camara</span></a>
									</div>
								</div>
							</>}
						</div>
						{newStream && <div className='video-container' style={{ flexBasis: '50%' }}>
							<video id="received_video" playsInline ref={userVideo} autoPlay />
							<div className='video-footer'>
								<input className="nickname" type="text" defaultValue={nombreAgente} readOnly />
							</div>
						</div>}
					</div>
				</div>
			</div>
			{(loading) ? <Loader /> : ("")}
			{errorNetwork &&
				<div className="modal fade show animate__animated animate__fadeIn" style={{ display: "block", color: "#212529", textAlign: "left", fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif", fontWeight: "400" }} role="dialog">
					<div className="modal-dialog" role="document">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title">Error de red</h5>
								<button onClick={e => setErrorNetwork(false)} className="close" data-dismiss="modal" aria-label="Close">
									<span aria-hidden="true">&times;</span>
								</button>
							</div>
							<div className="modal-body">
								<p>Se perdió la conexión del navegador.</p>
							</div>
						</div>
					</div>
				</div>}
			{reconnectVideoCall &&
				<div className="modal fade show animate__animated animate__fadeIn" style={{ display: "block", color: "#212529", textAlign: "left", fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif", fontWeight: "400" }} role="dialog">
					<div className="modal-dialog" role="document">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title">Reconectando</h5>
								<button onClick={e => setReconnectVideoCall(false)} className="close" data-dismiss="modal" aria-label="Close">
									<span aria-hidden="true">&times;</span>
								</button>
							</div>
							<div className="modal-body">
								<p>Intentando reconectar la videollamada.</p>
							</div>
						</div>
					</div>
				</div>}
		</>
	)
}

export default Receiver
