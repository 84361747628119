import {
    actualizarEstado, enviarEvento, enviarHologramaIdentificacion,
    enviarImagenEvaluar, sendFile, enviarInformacionNoco, enviarVideoRostroPDF,
    enviarIne, obtenerResultadoINE, enviarRenapo, obtenerResultadoRenapo,
    enviarZipAmarillos, enviarCorreoAceptacion, finalUpdateScore, actualizarScoreInicioFlujo,
    cancelarTurno, actualizarTurno, generarIceServers, pedirTurno,
} from '../services/api.js';
import {
    v4 as uuidv4
} from 'uuid';
import {
    obtenerValorConfig,
    obtenerValorUser
} from './configStyle';
import moment from 'moment';
import { deviceDetect } from 'react-device-detect';


let objetoFinal = {};
let contadorRENAPO = 0, contadorINE = 0;

export const agregarInfo = (dataUser, elemento) => {
    let dataUserLS = JSON.parse(localStorage.getItem("data_user"))
    const elementoEncontrado = dataUserLS.findIndex(function (item) {
        return item.description === elemento.description
    })
    ////console.log("elementoEncontrado" + elementoEncontrado + " item:"+elemento);
    if (elementoEncontrado !== -1) {
        dataUserLS[elementoEncontrado] = elemento
    } else {
        dataUserLS.push(elemento)
    }
    localStorage.setItem("data_user", JSON.stringify(dataUserLS))
}


export const hayExcepcion = () => {
    const uuidExcepcion = ["Wco5CTXw7e0JoKR9p7TB9fqOjpUS7iuu", "VCVi90WBhqtRPWboXtfpHBQlLPO00jd4", "MQZq6jzleILkHbQhGULIdwPhn5Jv19HD"];
    let respuesta = false;
    uuidExcepcion.forEach(function (index) {
        if (index === localStorage.getItem("apikeyOtorgante")) {
            respuesta = true
        }
    });
    return respuesta
}

export const initStatus = (nombreOtorgante) => {
    //console.log("entre a initStatus");
    updateInitStatus(nombreOtorgante);
    if (window.opener) {
        window.opener.postMessage(JSON.stringify({
            step: "inicio_page",
            result: "OK",
            //data: { transaccion: localStorage.getItem("uuidTrx")}
        }), new URL(window.location).searchParams.get("referer"))
        //console.log("hay referer");
        window.opener.postMessage(JSON.stringify({
            step: "inicio",
            result: "OK",
            data: {
                transaccion: localStorage.getItem("uuidTrx")
            }
        }), new URL(window.location).searchParams.get("referer"))
        localStorage.setItem("externalReference", new URL(window.location).searchParams.get("referer"))
        localStorage.setItem("externalJWT", new URL(window.location).searchParams.get("jwt"))
    }
    if (new URL(window.location).searchParams.get("referer")) {
        localStorage.setItem("externalReference", new URL(window.location).searchParams.get("referer"))
    }
    if (new URL(window.location).searchParams.get("schema")) {
        localStorage.setItem("package", new URL(window.location).searchParams.get("schema"))
    }
}

const updateInitStatus = async (nombreOtorgante) => {
    if (localStorage.getItem("completado") === "false") {
        let tipo = '';
        let informacion = {};
        let trx_id = localStorage.getItem("uuidTrx");
        if (trx_id === null) {
            //console.log("se creo nuevo trx");
            trx_id = uuidv4();
            localStorage.setItem("uuidTrx", trx_id);
        }
        let json = {
            "status": "OK",
            "step": "inicio"
        }
        let company_id = localStorage.getItem("apikeyOtorgante");
        let client_id = localStorage.getItem("uuidUser");
        let event = JSON.stringify(json);
        let objetoStatus = {
            'trx_id': trx_id,
            'company_id': company_id,
            'client_id': client_id,
            'step': "inicio_page",
            'event': event,
            'origin': "cub",
            'nombreOtorgante': nombreOtorgante
        }
        //console.log("uuidtx:", localStorage.getItem("uuidTrx"));
        actualizarEstado(objetoStatus).then((data) => {
            if (data.status === 200) {
                //console.log("Se actualizo status INIT");
                informacion.status = data.status;
                informacion.dataLifeCycle = objetoStatus;
                tipo = 'Update Cycle';
                evento('Inicio', tipo, informacion, true);
                initUpdateScore();
            }
        }).catch((error) => {
            //console.log("No se pudo actualizar init Status. "+e);
            //setLoading(false)
            if (error.response) {
                let { data: { message }, status } = error.response
                informacion.mensaje = message;
                informacion.status = status;
                tipo = 'Error Update Cycle';
            } else {
                informacion.error = error.toString();
                tipo = 'Exception Update Cycle';
            }
            evento('Inicio', tipo, informacion, false);
        })
    } else {
        return
    }
}

export const status = (paso, updatepaso) => {
    let json = {
        "status": "OK",
        "step": updatepaso
    }
    let event_t = JSON.stringify(json);

    let objetoStatus = {
        'step': paso,
        'event': event_t
    }
    updateStatus(objetoStatus);
    if (window.opener) {
        window.opener.postMessage(JSON.stringify({
            step: paso,
            result: "OK"
        }), localStorage.getItem("externalReference"))
    }
}

export const statusData = (paso, datos) => {
    var json = {};
    json = {
        "status": "OK",
        "step": paso,
        "description": datos
    }
    let event_t = JSON.stringify(json)

    let objetoStatus = {
        'step': paso,
        'event': event_t
    }
    updateStatus(objetoStatus);
    if (window.opener) {
        window.opener.postMessage(JSON.stringify({
            step: paso,
            result: "OK",
            data: datos
        }), localStorage.getItem("externalReference"))
    }
}

export const statusError = (paso, datos, tipo) => {
    //console.log("entre a status cancelar");
    var json = {};
    let status = "INCOMPLETED"
    if (tipo !== "cancelado") {
        status = "FAIL"
    }
    json = {
        "status": status,
        "step": paso,
        "description": datos
    }
    let event_t = JSON.stringify(json);

    let objetoStatus = {
        'step': paso,
        'event': event_t
    }
    updateStatus(objetoStatus);
    if (window.opener) {
        window.opener.postMessage(JSON.stringify({
            step: paso,
            status: status,
            type: "PROCESS",
            error_text: datos,
        }), localStorage.getItem("externalReference"))
        if (tipo === "cancelado")
            window.close()
    }
}

const updateStatus = async (objetoInformacion) => {
    if (localStorage.getItem("completado") === "false") {
        let tipo = '';
        let informacion = {};
        let dataEvent = null;
        let trx_id = localStorage.getItem("uuidTrx");
        let company_id = localStorage.getItem("apikeyOtorgante");
        let client_id = localStorage.getItem("uuidUser");
        const dataOtorgante = (JSON.parse(localStorage.getItem("dataOtorgante")));
        const dataUser = (JSON.parse(localStorage.getItem("data_user")));
        const bandera = localStorage.getItem("flag");

        let nombre = dataUser ? obtenerValorUser(dataUser, "nombre", "") + " " : '';
        let apellidoPaterno = dataUser ? obtenerValorUser(dataUser, "apellidoPaterno", "") + " " : '';
        let apellidoMaterno = dataUser ? obtenerValorUser(dataUser, "apellidoMaterno", "") : '';
        let telefono = dataUser ? obtenerValorUser(dataUser, "telefono") : '';
        let correo = dataUser ? obtenerValorUser(dataUser, "email") : '';
        // let fullName = nombre + apellidoPaterno + apellidoMaterno;
        let fullName = dataUser ? obtenerValorUser(dataUser, "nombreCompleto", "") : '';
        let flag = bandera ? bandera : '';
        let tipoIdentificacion = localStorage.getItem("typeID");

        let objetoStatus = {
            'trx_id': trx_id,
            'company_id': company_id,
            'client_id': client_id,
            'origin': "cub",
            'nombreOtorgante': obtenerValorConfig(dataOtorgante, 'NOMBRE_CORTO', ""),
            'nombreCompleto': fullName,
            'telefono': telefono,
            'correoElectronico': correo,
            'bandera': flag,
            'typeID': tipoIdentificacion ? tipoIdentificacion : '',
        }

        objetoFinal = {
            ...objetoStatus,
            ...objetoInformacion
        }

        dataEvent = JSON.parse(objetoInformacion.event)

        return actualizarEstado(objetoFinal).then((data) => {
            if (data.status === 200) {
                //console.log("Se actualizo status:",event_t);
                informacion.status = dataEvent.status;
                informacion.dataLifeCycle = objetoFinal;
                tipo = 'Update Cycle';
                evento(dataEvent.step, tipo, informacion, true);
            }
        }).catch((error) => {
            // console.log("No se pudo actualizar Status. " + e);
            //setLoading(false)
            if (error.response) {
                let { data: { message }, status } = error.response
                informacion.mensaje = message;
                informacion.status = status;
                tipo = 'Error Update Cycle';
            } else {
                informacion.error = error.toString();
                tipo = 'Exception Update Cycle';
            }
            evento(dataEvent.step, tipo, informacion, false);
        })
    } else {
        return
    }
}

/* Validacioenss */

export const hayLetras = (input) => {
    let result = true
    var expreg = /[a-z]/gi;
    //var result = input.match(expreg);
    if (!input.match(expreg))
        result = false
    return result
}

export const esNumLetras = (input) => {
    let result = true
    var expreg = /^[A-Za-z0-9]+$/g;
    //var result = input.match(expreg);
    if (!input.match(expreg))
        result = false
    //console.log("esNumLetras",result);
    return result
}

export const esSoloLetras = (input) => {
    let result = true
    let expreg = /^[A-Za-z-zÀ-ú\s]+$/g;
    //var result = input.match(expreg);
    if (!input.match(expreg))
        result = false
    //console.log("esSoloLetras",result);
    return result
}

export const esNumeros = (input) => {
    let result = true
    var expreg = /^[0-9]+$/g;
    //var result = input.match(expreg);
    if (!input.match(expreg))
        result = false
    //console.log("esnum",result);
    return result
}

export const esValidoEmail = (input) => {
    let result = true
    var expreg = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    //var result = input.match(expreg);
    if (!input.match(expreg))
        result = true
    //console.log("esmail",result);
    return false
}

export const statusReintento = (paso, datos, tipo) => {
    var json = {};
    let status = tipo
    json = {
        "status": status,
        "step": paso,
        "description": datos
    }

    let event_t = JSON.stringify(json)

    let objetoStatus = {
        'step': paso,
        'event': event_t
    }

    return updateStatus(objetoStatus);
}

export const sendData = (objetoStatus) => {
    updateStatus(objetoStatus);
}

export const calculateX = (innerWidth, percent, tipo) => {
    //console.log("Device Ratio:",ratio);

    if (tipo === "string") {
        return (Math.ceil(innerWidth * percent / 100)).toString() + "px";
    } else {
        return (Math.ceil(innerWidth * percent / 100));
    }
};

export const calculateY = (innerHeight, percent, tipo) => {
    //console.log("Device Ratio:",ratio);

    if (tipo === "string") {
        return (Math.ceil(innerHeight * percent / 100)).toString() + "px";
    } else {
        return (Math.ceil(innerHeight * percent / 100));
    }
};

export const calculateWidth = (innerWidth, percent, tipo) => {
    //console.log("Device Ratio:",ratio);
    if (tipo === "string") {
        return (Math.ceil(innerWidth * percent / 100)).toString() + "px";
    } else {
        return (Math.ceil(innerWidth * percent / 100));
    }
};

export const calculateHeight = (innerHeight, percent, tipo) => {
    //console.log("Device Ratio:",ratio);

    if (tipo === "string") {
        return (Math.ceil(innerHeight * percent / 100)).toString() + "px";
    } else {
        return (Math.ceil(innerHeight * percent / 100));
    }
};

export const formatoFechaOcr = (fecha) => {
    let formatosP = ['DD/MM/YYYY', 'DD/MM/YY']
    let nacimientoC = fecha
    if (fecha.includes(' '))
        nacimientoC = fecha.replaceAll(' ', '/')
    if (moment(nacimientoC, formatosP, true).isValid())
        return nacimientoC;
    else {
        return "";
    }
}

export const formatoFechaCuatroDigitos = (fecha) => {
    let añoActualInt = new Date().getFullYear().toString().substr(-2);
    let añoFechaOcr = fecha.substr(-2);
    let añoFechaOcrInt = fecha.substr(-2);
    let fechaSinAño = fecha.substr(0, 6);
    let fechaOcr = '';
    if (añoFechaOcrInt >= añoActualInt) {
        console.log("entre 19");
        fechaOcr = fechaSinAño + '19' + añoFechaOcr;
    } else {
        let mayoriaDeEdad = añoActualInt - 18;
        if (añoFechaOcrInt <= mayoriaDeEdad) {
            console.log("entre 20");
            fechaOcr = fechaSinAño + '20' + añoFechaOcr;
        } else {
            fechaOcr = fechaSinAño + '20' + añoFechaOcr;
        }

    }
    return fechaOcr;
}

export const validarValoresFormulario = (valores) => {
    let validacion = {
        errores: {},
        correcto: true
    };
    Object.entries(valores).forEach(([key, value]) => {
        let campoValidado = validarCampo(value, key);
        if (!campoValidado.correcto) {
            validacion.errores[key] = campoValidado.errores[key];
            validacion.correcto = false;
            return validacion;
        }
    });

    return validacion;
};

export const validarCampo = (valor, campo) => {
    let validacion = {
        errores: {},
        correcto: false
    };
    let formatosFecha = ['DD/MM/YYYY'];
    switch (campo) {
        case "nombreCompleto":
            if (!valor.trim()) {
                validacion.errores.nombreCompleto = 'Debes ingresar un valor válido';
            } else if (valor.length < 10 || !esSoloLetras(valor)) {
                validacion.errores.nombreCompleto = 'Nombre no válido';
            } else {
                validacion.correcto = true;
            }
            break;
        case "nombres":
            if (!valor.trim()) {
                validacion.errores.nombres = 'Debes ingresar un valor válido';
            } else if (!esSoloLetras(valor)) {
                validacion.errores.nombres = 'Nombre no válido';
            } else {
                validacion.correcto = true;
            }
            break;
        case "apellidoPaterno":
            if (!valor.trim()) {
                validacion.errores.apellidoPaterno = 'Debes ingresar un valor válido';
            } else if (!esSoloLetras(valor)) {
                validacion.errores.apellidoPaterno = 'Apellido paterno no válido';
            } else {
                validacion.correcto = true;
            }
            break;
        case "apellidoMaterno":
            validacion.correcto = true;
            /* if (!valor.trim()) {
                validacion.errores.apellidoMaterno = 'Debes ingresar un valor válido';
            } else if (!esSoloLetras(valor)) {
                validacion.errores.apellidoMaterno = 'Apellido materno no válido';
            } else {
                validacion.correcto = true;
            } */
            break;
        case "fechaNacimiento":
            if (!valor.trim()) {
                validacion.errores.fechaNacimiento = 'Debes ingresar un valor válido';
            } else if (moment(valor, formatosFecha, true).isValid()) {
                validacion.correcto = true;
            } else {
                validacion.errores.fechaNacimiento = 'Revisa el formato dd/mm/aaaa';
            }
            break;
        case "clave": //validacion.correcto = true;
            if (!valor.trim()) {
                validacion.errores.clave = 'Debes ingresar un valor válido';
            } else if (!esNumLetras(valor) || valor.length < 17 || valor.length > 20) {
                validacion.errores.clave = 'Clave de elector no válida';
            } else {
                validacion.correcto = true;
            }
            break;
        case "fechaRegistro": //validacion.correcto = true;
            if (!valor.trim()) {
                validacion.errores.fechaRegistro = 'Debes ingresar un valor válido';
            } else if (!esNumeros(valor) || valor.length !== 4) {
                validacion.errores.fechaRegistro = 'Revisa que el año sea 4 dígitos';
            } else {
                validacion.correcto = true;
            }
            break;
        case "numeroEmision": //validacion.correcto = true;
            if (!valor.trim()) {
                validacion.errores.numeroEmision = 'Debes ingresar un valor válido';
            } else if (!esNumeros(valor) || valor.length !== 2) {
                validacion.errores.numeroEmision = 'Formato de Emisión no válido, 2 dígitos';
            } else {
                validacion.correcto = true;
            }
            break;
        case "cic": // validacion.correcto = true;
            if (!valor.trim()) {
                validacion.errores.cic = 'Debes ingresar un valor válido';
            } else if (!esNumeros(valor) || valor.length < 8 || valor.length > 13) {
                validacion.errores.cic = 'Formato de CIC/OCR no válido';
            } else {
                validacion.correcto = true;
            }
            break;
        case "curp":
            if (!valor.trim()) {
                validacion.errores.curp = 'Debes ingresar un valor válido';
            } else if (valor.length < 18 || !esNumLetras(valor) || valor.length > 18 || !validarCurp(valor)) {
                validacion.errores.curp = 'Formato de CURP no válido';
            } else if (valor.length === 18 && !validarCurp(valor)) {
                validacion.errores.curp = 'Formato de CURP no válido';
            } else {
                validacion.correcto = true;
            }
            break;
        case "vigencia":
            let today = new Date();
            let year = today.getFullYear();
            if (!valor.trim()) {
                validacion.errores.vigencia = 'Debes ingresar un valor válido';
            } else if ((valor.length !== 4)) {
                validacion.errores.vigencia = 'Revisa que el año sea 4 dígitos';
            } else if (!esNumeros(valor)) {
                validacion.errores.vigencia = 'Formato de vigencia no válido';
            } else if (Number(valor) < year) {
                validacion.errores.vigencia = 'Vigencia de identificación no válida';
            } else {
                validacion.correcto = true;
            }
            break;
        case "vigenciaPasaporte":
            if (!valor.trim()) {
                validacion.errores.vigenciaPasaporte = 'Debes ingresar un valor válido';
            } else if (moment(valor, formatosFecha, true).isValid()) {
                validacion.correcto = true;
            } else {
                validacion.errores.vigenciaPasaporte = 'Revisa el formato dd/mm/aaaa';
            }
            break;
        case "dni":
            if (!valor.trim()) {
                validacion.errores.dni = 'Debes ingresar un valor válido';
            } else if (valor.length < 3 || !esNumLetras(valor)) {
                validacion.errores.dni = 'Formato de DNI no válido';
            } else {
                validacion.correcto = true;
            }
            break;
        case "codigoPostal":
            if (!valor.trim()) {
                validacion.errores.codigoPostal = 'Debes ingresar un valor válido';
            } else if (!esNumeros(valor)) {
                validacion.errores.codigoPostal = 'Código postal no válido';
            } else {
                validacion.correcto = true;
            }
            break;
        case "pais":
            if (!valor.trim()) {
                validacion.errores.pais = 'Debes ingresar un valor válido';
            } else {
                validacion.correcto = true;
            }
            break;
        case "estado":
            if (!valor.trim()) {
                validacion.errores.estado = 'Debes ingresar un valor válido';
            } else {
                validacion.correcto = true;
            }
            break;
        case "delegacion":
            if (!valor.trim()) {
                validacion.errores.delegacion = 'Debes ingresar un valor válido';
            } else {
                validacion.correcto = true;
            }
            break;
        case "colonia":
            if (!valor.trim()) {
                validacion.errores.colonia = 'Debes ingresar un valor válido';
            } else {
                validacion.correcto = true;
            }
            break;
        case "otraColonia":
            if (!valor.trim()) {
                validacion.errores.otraColonia = 'Debes ingresar un valor válido';
            } else {
                validacion.correcto = true;
            }
            break;
        case "calle":
            if (!valor.trim()) {
                validacion.errores.calle = 'Debes ingresar un valor válido';
            } else {
                validacion.correcto = true;
            }
            break;
        case "numeroExterior":
            if (!valor.trim()) {
                validacion.errores.numeroExterior = 'Debes ingresar un valor válido';
            } else {
                validacion.correcto = true;
            }
            break;
        case "numeroInterior":
            validacion.correcto = true;
            break;
        case "rfc":
            if (!valor.trim()) {
                validacion.errores.rfc = 'Debes ingresar un valor válido';
            } else if (valor.length < 10 || !esNumLetras(valor)) {
                validacion.errores.rfc = 'Formato de RFC no válido';
            } else {
                validacion.correcto = true;
            }
            break;
        case "email":
            if (!valor.trim()) {
                validacion.errores.email = 'Debes ingresar un valor válido';
            } else {
                validacion.correcto = true
            }
            break;
        case "ocupacion":
            if (!valor.trim()) {
                validacion.errores.ocupacion = 'Debes ingresar un valor válido';
            } else {
                validacion.correcto = true
            }
            break;
        case "telefono":
            if (!valor.trim()) {
                validacion.errores.telefono = 'Debes ingresar un valor válido';
            } else if (valor.length < 8 || !esNumeros(valor) || valor.length !== 10) {
                validacion.errores.telefono = 'Formato de Teléfono no válido';
            } else {
                validacion.correcto = true
            }
            break;
        case "ocr":
            if (!valor.trim() || valor === "") {
                validacion.errores.ocr = 'Debes ingresar un valor válido';
            } else if (!esNumeros(valor) || valor.length < 12 || valor.length > 13) {
                validacion.errores.ocr = 'Formato de OCR no válido';
            } else {
                validacion.correcto = true;
            }
            /*
            let dataUser = JSON.parse(localStorage.getItem("ocrFront"));
            if (!valor.trim() || valor === "") {
                validacion.errores.ocr = 'Debes ingresar un valor válido';
            }else{
                if(dataUser.modeloIdentificacion === "A" || dataUser.modeloIdentificacion === "B" || dataUser.modeloIdentificacion === "C"){
                    if (!esNumeros(valor) || valor.length !== 12) {
                        validacion.errores.ocr = 'Formato de OCR no válido';
                    } else {
                        validacion.correcto = true;
                    }
                }else{
                    if (!esNumeros(valor) || valor.length !== 13) {
                        validacion.errores.ocr = 'Formato de OCR no válido';
                    } else {
                        validacion.correcto = true;
                    }
                }
            }
            */
            break;
        default:
            validacion.correcto = true
            break;
    }
    //console.log("validacion",validacion);
    return validacion;
};

export const evento = (paso, tipo, data, finished) => {
    const dato = JSON.stringify(data);
    let fecha = "";
    let date = new Date();
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();
    let hour = date.getHours();
    let min = date.getMinutes();
    let seconds = date.getSeconds();
    if (month < 10) {
        fecha = `${year}-0${month}-${day}_${hour}:${min}:${seconds}`
    } else {
        fecha = `${year}-${month}-${day}_${hour}:${min}:${seconds}`
    }
    let uuidUser = localStorage.getItem("uuidUser");
    let uuidTrx = localStorage.getItem("uuidTrx");
    let apikeyOtorgante = localStorage.getItem("apikeyOtorgante");
    let uuidOtorgante = localStorage.getItem("uuidOtorgante");
    let objeto = {
        uuid_clt: uuidUser,
        uuid_trx: uuidTrx,
        uuid_oto: uuidOtorgante,
        apikey: apikeyOtorgante,
        paso: paso,
        tipo: tipo,
        dato: dato,
        finished: finished,
        time_stamp: fecha
    }
    enviarEvento(objeto).then(response => {
        //console.log('RESPONSE', response);
        return
    }).catch(error => console.log('ERROR', error));
}

export const endpointHolograma = (file, isIOS) => {
    let tipo = '';
    let informacion = {};
    enviarHologramaIdentificacion(file, isIOS).then(({ status, data: { payload } }) => {
        if (status === 200) {
            informacion.status = status;
            informacion.payload = payload;
            tipo = 'Success';
            evento('Captura Holograma', tipo, informacion, true);
        }
    }).catch(error => {
        console.log('ERROR', error);
        if (error.response) {
            let { data: { message }, status } = error.response
            informacion.mensaje = message;
            informacion.status = status;
            tipo = 'Error';
        } else {
            informacion.error = error.toString();
            tipo = 'Exception';
        }
        evento('Captura Holograma', tipo, informacion, false);
    })
}

export const getX = () => {
    let innerWidth = window.innerWidth;
    let innerHeight = window.innerHeight;

    if (innerWidth >= 424 || innerWidth < 424) {
        return 2;
    } else {
        return 2;
    }
}

export const getY = () => {
    let innerWidth = window.innerWidth;
    let innerHeight = window.innerHeight;

    if (innerWidth > 424) {
        return 24;
    } else if (innerWidth === 424) {
        if (innerHeight > 728) {
            return 24;
        } else {
            return 29;
        }
    } else if (innerWidth >= 412) {
        if (innerHeight > 728) {
            return 24;
        } else {
            return 32;
        }
    } else if (innerWidth >= 360) {
        if (innerHeight > 728) {
            return 24;
        } else {
            return 27;
        }
    } else {
        return 27;
    }
}

export const getWidth = () => {
    let innerWidth = window.innerWidth;
    let innerHeight = window.innerHeight;

    if (innerWidth >= 424) {
        return 97;
    } else if (innerWidth >= 412) {
        return 96;
    } else if (innerWidth >= 360) {
        return 96;
    } else {
        return 96;
    }
}

export const getHeight = () => {
    let innerWidth = window.innerWidth;
    let innerHeight = window.innerHeight;

    if (innerWidth >= 424) {
        return 45;
    } else if (innerWidth >= 412) {
        return 38;
    } else if (innerWidth >= 360) {
        if (innerHeight <= 640) {
            return 45;
        } else if (innerHeight <= 657) {
            return 37;
        } else if (innerHeight <= 664) {
            return 45;
        } else {
            return 35;
        }
    } else {
        return 35;
    }
}

export const sendEventClick = (paso, description, objectInformation = {}) => {
    let objeto = { description: description };
    let objetoEvento = {
        ...objeto,
        ...objectInformation
    }
    evento(paso, 'Click', objetoEvento, true);
}

export const sendImage = async (imagen, modelo, nombre, tipoImagen, lado) => {
    let tipo = '';
    let informacion = {};
    enviarImagenEvaluar(imagen, modelo, nombre, lado).then(({ status, data: { payload } }) => {
        if (status === 200) {
            informacion.status = status;
            informacion.payload = payload;
            evento(tipoImagen, 'Success', informacion, true);
        }
    }).catch((error) => {
        if (error.response) {
            let { data: { message_client }, status } = error.response
            informacion.mensaje = message_client;
            informacion.status = status;
            tipo = 'Error';
        } else {
            informacion.error = error.toString();
            tipo = 'Exception';
        }
        evento(tipoImagen, tipo, informacion, false);
    })
}

export const regresarJson = () => {
    const uuidExcepcion = ["iQNTQ2qoBLfAy0iH4V2OUM5l36BIUoCt"];
    let respuesta = false;
    uuidExcepcion.forEach(function (index) {
        if (index === localStorage.getItem("apikeyOtorgante")) {
            respuesta = true
        }
    });
    return respuesta
}

export const enviarImagenHuella = (archivo, ruta, nombreArchivo, indice) => {
    let tipo = '';
    let informacion = {};
    sendFile(archivo, ruta, nombreArchivo).then(({ status, data: { payload } }) => {
        if (status === 200) {
            informacion.status = status;
            informacion.payload = payload;
            informacion.indice = indice;
            tipo = 'Success';
            evento('Envío imagen huella', tipo, informacion, true);
        }
    }).catch(error => {
        if (error.response) {
            let { data: { message }, status } = error.response
            informacion.mensaje = message;
            informacion.status = status;
            tipo = 'Error Response';
        } else if (error.request) {
            informacion.error = error.request;
            tipo = 'Error Request';
        } else {
            informacion.error = error.message;
            tipo = 'Exception';
        }
        evento('Captura Holograma', tipo, informacion, false);
    })
}

export const enviarHuellas = (rutaIndiceIzquierdo, rutaIndiceDerecho) => {
    const dataUser = JSON.parse(localStorage.getItem("data_user"));
    const dispositivo = JSON.stringify(deviceDetect());
    let uuidUser = localStorage.getItem("uuidUser") || '';
    let uuidTrx = localStorage.getItem("uuidTrx") || '';
    let cic = obtenerValorUser(dataUser, 'cic', null);
    let ocr = obtenerValorUser(dataUser, 'ocr', null);
    let claveElector = obtenerValorUser(dataUser, 'claveElector', null);
    let numeroEmision = obtenerValorUser(dataUser, 'numeroEmision', null);
    let objeto = {
        uuid: uuidUser,
        transaccion: uuidTrx,
        Ruta_indice_izq: rutaIndiceIzquierdo,
        Ruta_indice_der: rutaIndiceDerecho,
        CIC: cic,
        OCR: ocr,
        Clave: claveElector,
        Emision: numeroEmision,
        dispositivo: dispositivo,
    }
    enviarInformacionNoco(objeto).then(response => {
        //console.log('RESPONSE', response);
        return
    }).catch(error => console.log('ERROR', error));
}

/* Video token pdf */
export const enviarVideoTokenPdf = (facefile, Acreated, Aexpire, isIOS, service_call) => {
    let so = isIOS ? 'ios' : 'android';
    let data = new FormData();
    let headers = {};
    let FECHA_UNICA = new Date().getTime();
    let VIDEO_OUTPUT_FILE = FECHA_UNICA + 'recording.webm';

    data.append("uuidUser", localStorage.getItem("uuidUser"));
    data.append("created", Acreated);
    data.append("expire", Aexpire);
    data.append("originOS", so);
    data.append("service_call", service_call);
    data.append("videotoken", facefile, VIDEO_OUTPUT_FILE);

    headers = {
        'Content-Type': 'multipart/form-data',
        'X-Consumer-Custom-ID': localStorage.getItem("uuidOtorgante"),
        'transaccion': localStorage.getItem("uuidTrx"),
        'cliente': localStorage.getItem("uuidUser"),
        'apikey': localStorage.getItem("apikeyOtorgante"),
    }

    return enviarVideoRostroPDF(headers, data);
}

/* Validación RENAPO */
const validarCurp = (curp) => {
    let result = true
    var expreg = /^[A-Z]{1}[AEIOU]{1}[A-Z]{2}[0-9]{2}(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1])[HM]{1}(AS|BC|BS|CC|CS|CH|CL|CM|DF|DG|GT|GR|HG|JC|MC|MN|MS|NT|NL|OC|PL|QT|QR|SP|SL|SR|TC|TS|TL|VZ|YN|ZS|NE)[B-DF-HJ-NP-TV-Z]{3}[0-9A-Z]{1}[0-9]{1}$/;
    //var result = input.match(expreg);
    if (!curp.match(expreg))
        result = false
    //console.log("RESULT",result);
    return result
}

export const validarRenapo = async (jobID) => {
    if (contadorRENAPO < 15) {
        let tipo = '';
        let informacion = {};
        obtenerResultadoRenapo(jobID).then(async ({ data: { payload: { item } }, status, data: { payload } }) => {
            if (status === 200) {
                if (item && item.length > 0) {
                    informacion.status = status;
                    informacion.payload = payload;
                    //localStorage.setItem("validacionRENAPO", true);
                    let responseItem = item[0];
                    if (responseItem.status.trim() === 'VALIDADO') {
                        statusData('validacion_renapo', { validacion: true, status: responseItem.status });
                        let objeto = { renapo: responseItem.status.trim() }
                        actualizarTurno(objeto);
                        contadorRENAPO = 0; 
                    } else if (responseItem.status.trim() === 'INVALIDO') {
                        statusData('validacion_renapo', { validacion: false, status: responseItem.status });
                        let objeto = { renapo: responseItem.status.trim() }
                        actualizarTurno(objeto);
                        contadorRENAPO = 0; 
                    } else {
                        setTimeout(() => {
                            validarRenapo(jobID);
                            contadorRENAPO++; 
                        }, 3000);
                    }
                } else {
                    setTimeout(() => {
                        validarRenapo(jobID);
                        contadorRENAPO++; 
                    }, 3000);
                }
                evento('Validación RENAPO', 'Success', informacion, true);
            }
        }).catch(async error => {
            if (error.response) {
                let { data, status } = error.response
                informacion.data = data;
                informacion.status = status;
                tipo = 'Error';
            } else {
                informacion.error = error.toString();
                tipo = 'Exception';
            }
            evento('Validación RENAPO', tipo, informacion, false);
            setTimeout(() => {
                if (error.toString() === 'Cancel') {
                    contadorRENAPO = 0; 
                } else {
                    validarRenapo(jobID);
                    contadorRENAPO++; 
                }
            }, 3000);
        });
    } else {
        contadorRENAPO = 0;
        return;
    }
}

export const encolarPeticionRenapo = (idTurno) => {
    if (contadorRENAPO <10) {
        let tipo = '';
        let informacion = {};
        const dataUser = (JSON.parse(localStorage.getItem("data_user")));
        let curp = obtenerValorUser(dataUser, "curp", '');
        return enviarRenapo(curp, idTurno).then(({ status, data: { payload }, data: { payload: { job_id } } }) => {
            informacion.status = status;
            informacion.payload = payload;
            evento('Encolar petición RENAPO', 'Success', informacion, true);
            contadorRENAPO = 0;
            validarRenapo(job_id);
        }).catch(error => {
            if (error.response) {
                let { data: { message_client }, status } = error.response
                informacion.mensaje = message_client;
                informacion.status = status;
                tipo = 'Error';
            } else {
                informacion.error = error.toString();
                tipo = 'Exception';
            }
            evento('Encolar petición RENAPO', tipo, informacion, false);
            if (error.toString() === 'Cancel') {
                contadorRENAPO = 0;
            } else {
                encolarPeticionRenapo(idTurno);
                contadorRENAPO++;
            }
        })
    } else {
        contadorRENAPO = 0;
        return;
    }
}

/* Validación INE */

export const validarINE = async jobID => {
    if (contadorINE < 15) {
        let tipo = '';
        let informacion = {};
        obtenerResultadoINE(jobID).then(async ({ data: { payload: { message } }, status, data: { payload } }) => {
            if (status === 200) {
                if (message && message.length > 0) {
                    informacion.status = status;
                    informacion.payload = payload;
                    let responseItem = message[0];
                    if (responseItem.estatus.trim() === 'VALIDADO') {
                        statusData('validacion_ine', { validacion: true, status: responseItem.estatus });
                        let objeto = { ine: responseItem.estatus.trim() }
                        actualizarTurno(objeto);
                        contadorINE = 0;
                    } else if (responseItem.estatus.trim() === 'INVALIDO') {
                        statusData('validacion_ine', { validacion: false, status: responseItem.estatus });
                        let objeto = { ine: responseItem.estatus.trim() }
                        actualizarTurno(objeto);
                        contadorINE = 0;
                    } else {
                        setTimeout(() => {
                            validarINE(jobID);
                            contadorINE++;
                        }, 3000);
                    }
                } else {
                    setTimeout(() => {
                        validarINE(jobID);
                        contadorINE++;
                    }, 3000);
                }
                evento('Validación INE', 'Success', informacion, true);
            }
        }).catch(async error => {
            if (error.response) {
                let { data, status } = error.response
                informacion.mensaje = data;
                informacion.status = status;
                tipo = 'Error';
            } else {
                informacion.error = error.toString();
                tipo = 'Exception';
            }
            evento('Validación INE', tipo, informacion, false);
            setTimeout(() => {
                if (error.toString() === 'Cancel') {
                    contadorINE = 0;
                } else {
                    validarINE(jobID);
                    contadorINE++;
                }
            }, 3000);
        });
    } else {
        contadorINE = 0;
        return;
    }
}

export const encolarPeticionINE = (idTurno) => {
    if (contadorINE < 10) {
        let tipo = '';
        let informacion = {};
        let ocrFront = null
        let dataOCRFrontLS = localStorage.getItem("ocrFront");
        if (dataOCRFrontLS) ocrFront = JSON.parse(dataOCRFrontLS);
        const dataUser = (JSON.parse(localStorage.getItem("data_user")));
        let ocr = obtenerValorUser(dataUser, "ocr", '');
        let idCiudadano = obtenerValorUser(dataUser, "idCiudadano", '');
        let claveElector = obtenerValorUser(dataUser, "claveElector", '');
        let numeroEmision = obtenerValorUser(dataUser, "numeroEmision", '');
        let modeloIdentificacion = 'A';
        if (ocrFront.identificacionElectoral) {
            return enviarIne(ocr, idCiudadano, claveElector, numeroEmision, modeloIdentificacion, idTurno).then(
                ({ status, data: { payload }, data: { payload: { message } } }) => {
                    informacion.status = status;
                    informacion.payload = payload;
                    evento('Encolar petición INE', tipo, informacion, true);
                    contadorINE = 0;
                    validarINE(message.data);
                }).catch(error => {
                    if (error.response) {
                        let { data, status } = error.response
                        informacion.data = data;
                        informacion.status = status;
                        tipo = 'Error';
                    } else {
                        informacion.error = error.toString();
                        tipo = 'Exception';
                    }
                    evento('Encolar petición INE', tipo, informacion, false);
                    if (error.toString() === 'Cancel') {
                        contadorINE = 0;
                    } else {
                        encolarPeticionINE(idTurno);
                        contadorINE++;
                    }
                })
        } else {
            return
        }
    } else {
        contadorINE = 0;
        return;
    }
}

const initUpdateScore = () => {
    let tipo = '';
    let informacion = {};
    actualizarScoreInicioFlujo().then(({ status, data: { payload } }) => {
        if (status === 200) {
            informacion.status = status;
            informacion.payload = payload;
            tipo = 'Success';
            evento('Actualizar Score', tipo, informacion, true);
        }
    }).catch(error => {
        console.log('ERROR', error);
        if (error.response) {
            let { data: { message }, status } = error.response
            informacion.mensaje = message;
            informacion.status = status;
            tipo = 'Error';
        } else {
            informacion.error = error.toString();
            tipo = 'Exception';
        }
        evento('Actualizar Score', tipo, informacion, false);
    })
}

export const generateZip = async (tipoEnvio, bandera, isIOS) => {
    let objeto = {}
    let dataOtorgante = [];
    let dataUser = [];
    let uuid = '';
    let uuidTrx = '';
    let tipo = '';
    let nombreEvento = '';
    let informacion = {};

    let dataOtorganteLS = localStorage.getItem("dataOtorgante");
    if (dataOtorganteLS) {
        dataOtorgante = JSON.parse(dataOtorganteLS);
    }
    let uuidUser = localStorage.getItem("uuidUser");
    if (uuidUser) {
        uuid = uuidUser;
    }
    let uuidTrxLS = localStorage.getItem("uuidTrx");
    if (uuidTrxLS) {
        uuidTrx = uuidTrxLS;
    }
    let dataUserLS = localStorage.getItem("data_user")
    if (dataUserLS) {
        dataUser = JSON.parse(dataUserLS);
    }
    let sendZipTruncos = obtenerValorConfig(dataOtorgante, "SEND_ZIP_TRUNCOS", 'false');
    let sendZipSemaforo = obtenerValorConfig(dataOtorgante, "SEND_ZIP_SEMAFORO", 'false');
    let fecha = ""
    let date = new Date()
    let day = date.getDate()
    let month = date.getMonth() + 1
    let year = date.getFullYear()
    if (month < 10) {
        fecha = `${day}0${month}${year}`
    } else {
        fecha = `${day}${month}${year}`
    }
    let partner = obtenerValorConfig(dataOtorgante, "PARTNER", "")
    partner = partner.replace(" ", "_");
    if (partner !== "") {
        partner = partner + "_"
    }
    let name_otorgante = obtenerValorConfig(dataOtorgante, "NOMBRE_CORTO", "")
    name_otorgante = name_otorgante.replace(" ", "_");

    objeto.tipo_envio = tipoEnvio;
    objeto.semaforo = bandera;
    objeto.uuidUser = uuid;
    objeto.originOS = isIOS ? 'ios' : 'android';
    objeto.custom_uuid = partner + name_otorgante + "_" + fecha + "_" + uuidTrx.split("-")[0];

    switch (bandera) {
        case 'trunco':

            nombreEvento = 'Generacíon de Nom 151 trunco';
            objeto.ocrFront = null;
            objeto.ocrBack = null;
            objeto.data_user = null;
            break;

        default:

            nombreEvento = 'Generacíon de Nom 151 con Bandera';
            objeto.name = obtenerValorUser(dataUser, "nombreCompleto");
            objeto.email = '';
            objeto.fNac = obtenerValorUser(dataUser, "nacimiento");
            objeto.curp = (hayExcepcion()) ? obtenerValorUser(dataUser, "dni") : obtenerValorUser(dataUser, "curp");
            objeto.vigencia = obtenerValorUser(dataUser, "vigencia");
            objeto.ocrFront = JSON.parse(localStorage.getItem("ocrFront")) || {};
            objeto.ocrBack = JSON.parse(localStorage.getItem("ocrBack")) || {};
            objeto.data_user = JSON.parse(localStorage.getItem("data_user")) || [];
            objeto.coincidencia = "Requiere validación";

            break;
    }
    if (((tipoEnvio === 'TRUNCOS') && (sendZipTruncos === 'true')) || ((tipoEnvio === 'SEMAFORO') && (sendZipSemaforo === 'true'))) {
        return enviarZipAmarillos(objeto).then((response) => {
            if (response.status === 200) {
                informacion.status = response.status;
                informacion.payload = response.data.payload;
                evento(nombreEvento, 'Success', informacion, true);
            }
        }).catch((error) => {
            if (error.response) {
                let { data, status } = error.response
                informacion.data = data;
                informacion.status = status;
                tipo = 'Error';
            } else {
                informacion.error = error.toString();
                tipo = 'Exception';
            }
            evento(nombreEvento, tipo, informacion, false);
        })
    } else
        return
}


export const sendZipFlag = async (tipoEnvio, bandera, isIOS) => {
    let objeto = {};
    let tipo = '';
    let informacion = {};
    let uuid = '';
    let uuidTrx = '';
    let dataUser = [];
    let dataOtorgante = [];
    let uuidUser = localStorage.getItem("uuidUser")
    if (uuidUser) {
        uuid = uuidUser;
    }
    let uuidTrxLS = localStorage.getItem("uuidTrx")
    if (uuidTrxLS) {
        uuidTrx = uuidTrxLS;
    }
    let dataUserLS = localStorage.getItem("data_user")
    if (dataUserLS) {
        dataUser = JSON.parse(dataUserLS);
    }
    let dataOtorganteLS = localStorage.getItem("dataOtorgante")
    if (dataOtorganteLS) {
        dataOtorgante = JSON.parse(dataOtorganteLS);
    }
    objeto.tipo_envio = tipoEnvio;
    objeto.semaforo = bandera;
    objeto.uuidUser = uuid;
    objeto.name = obtenerValorUser(dataUser, "nombreCompleto");
    objeto.email = '';
    objeto.fNac = obtenerValorUser(dataUser, "nacimiento");
    objeto.curp = (hayExcepcion()) ? obtenerValorUser(dataUser, "dni") : obtenerValorUser(dataUser, "curp");
    objeto.vigencia = obtenerValorUser(dataUser, "vigencia");
    objeto.ocrFront = JSON.parse(localStorage.getItem("ocrFront")) || {};
    objeto.ocrBack = JSON.parse(localStorage.getItem("ocrBack")) || {};
    objeto.data_user = JSON.parse(localStorage.getItem("data_user")) || [];
    let fecha = ""
    let date = new Date()
    let day = date.getDate()
    let month = date.getMonth() + 1
    let year = date.getFullYear()
    if (month < 10) {
        fecha = `${day}0${month}${year}`
    } else {
        fecha = `${day}${month}${year}`
    }
    let partner = obtenerValorConfig(dataOtorgante, "PARTNER", "")
    partner = partner.replace(" ", "_");
    if (partner !== "") {
        partner = partner + "_"
    }
    let name_otorgante = obtenerValorConfig(dataOtorgante, "NOMBRE_CORTO", "")
    name_otorgante = name_otorgante.replace(" ", "_");
    objeto.custom_uuid = partner + name_otorgante + "_" + fecha + "_" + uuidTrx.split("-")[0];
    objeto.originOS = isIOS ? 'ios' : 'android';
    objeto.coincidencia = "Requiere validación";
    return enviarCorreoAceptacion(objeto).then((response) => {
        if (response.status === 200) {
            informacion.status = response.status;
            informacion.payload = response.data.payload;
            evento('Generacíon de Nom 151 con Bandera', 'Success', informacion, true);
        }
    }).catch((error) => {
        if (error.response) {
            let { data: { message_client }, status } = error.response
            informacion.mensaje = message_client;
            informacion.status = status;
            tipo = 'Error';
        } else {
            informacion.error = error.toString();
            tipo = 'Exception';
        }
        evento('Generacíon de Nom 151 con Bandera', tipo, informacion, false);
    })
}

export const flowIncompleted = (objetoInformacion, flag) => {
    let tipo = '';
    let informacion = {};
    let objeto = { createScore: true }
    return finalUpdateScore(objeto).then(async ({ status, data: { payload } }) => {
        if (status === 200) {
            informacion.status = status;
            informacion.payload = payload;
            evento('Actualizar Score', 'Success', informacion, true);
        }
        setFlagStatus(objetoInformacion, flag);
    }).catch(error => {
        if (error.response) {
            let { data: { message }, status } = error.response
            informacion.mensaje = message;
            informacion.status = status;
            tipo = 'Error';
        } else {
            informacion.error = error.toString();
            tipo = 'Exception';
        }
        evento('Actualizar Score', tipo, informacion, false);
        setFlagStatus(objetoInformacion, flag);
    });
}

export const setFlagStatus = (objetoInformacion, flag) => {
    if (localStorage.getItem("completado") === "false") {
        let tipo = '';
        let informacion = {};
        let dataEvent = null;
        let trx_id = localStorage.getItem("uuidTrx");
        let company_id = localStorage.getItem("apikeyOtorgante");
        let client_id = localStorage.getItem("uuidUser");
        const dataOtorgante = (JSON.parse(localStorage.getItem("dataOtorgante")));
        const dataUser = (JSON.parse(localStorage.getItem("data_user")));

        let telefono = localStorage.getItem("movil") ? localStorage.getItem("movil") : '';
        let correo = localStorage.getItem("correoElectronico") ? localStorage.getItem("correoElectronico") : '';
        let fullName = dataUser ? obtenerValorUser(dataUser, "nombreCompleto", "") : '';
        let tipoIdentificacion = localStorage.getItem("typeID");

        let objetoStatus = {
            'trx_id': trx_id,
            'company_id': company_id,
            'client_id': client_id,
            'origin': "celd",
            'nombreOtorgante': obtenerValorConfig(dataOtorgante, 'NOMBRE_CORTO', ""),
            'nombreCompleto': fullName,
            'telefono': telefono,
            'correoElectronico': correo,
            'bandera': flag,
            'typeID': tipoIdentificacion ? tipoIdentificacion : '',
        }

        objetoFinal = {
            ...objetoStatus,
            ...objetoInformacion
        }

        dataEvent = JSON.parse(objetoInformacion.event)

        actualizarEstado(objetoFinal).then(({ status }) => {
            if (status === 200) {
                //console.log("Se actualizo status INIT");
                informacion.status = dataEvent.status;
                informacion.dataLifeCycle = objetoFinal;
                tipo = 'Update Cycle';
                evento(dataEvent.step, tipo, informacion, true);
                localStorage.setItem("completado", "true");
            }
        }).catch((error) => {
            //console.log("No se pudo actualizar init Status. "+e);
            //setLoading(false)
            if (error.response) {
                let { data: { message }, status } = error.response
                informacion.mensaje = message;
                informacion.status = status;
                tipo = 'Error Update Cycle';
            } else {
                informacion.error = error.toString();
                tipo = 'Exception Update Cycle';
            }
            evento(dataEvent.step, tipo, informacion, false);
            localStorage.setItem("completado", "true");
        });
        if (window.opener) {
            window.opener.postMessage(JSON.stringify({
                step: objetoInformacion.step,
                status: 'INCOMPLETED',
                type: "PROCESS",
                error_text: 'Finalizado por reintentos',
            }), localStorage.getItem("externalReference"))
        }
    } else {
        return
    }
}

export const enviarTurnoCancelar = (idTurno) => {
    let tipo = '';
    let informacion = {};
    return cancelarTurno(idTurno).then(({ status, data: { payload } }) => {
        if (status === 200) {
            informacion.status = status;
            informacion.payload = payload;
            tipo = 'Success';
            evento('Cancelar Turno', tipo, informacion, true);
        }
    }).catch(error => {
        if (error.response) {
            let { data, status } = error.response
            informacion.data = data;
            informacion.status = status;
            tipo = 'Error';
        } else {
            informacion.error = error.toString();
            tipo = 'Exception';
        }
        evento('Cancelar Turno', tipo, informacion, false);
    })
}

/*--- Generar iceServers---*/
export const obtenerIceServers = () => {
    return generarIceServers();
}

/*--- Mapear evento ---*/
export const mapearEvento = (event) => {
    let objetoEvento = {
        isTrusted: event?.isTrusted,
        type: event?.type,
        currentTarget: event?.currentTarget,
    }
    return objetoEvento;
}

export const generarTurno = () => {

    let data = {};

    const dataUser = (JSON.parse(localStorage.getItem("data_user")));
    const wss = 'wss://api.devdicio.net:8444/v1/sec_dev_signal/call/'
    const apikey = localStorage.getItem('apikeyOtorgante');
    const uuidTransaccion = localStorage.getItem('uuidTrx');
    const uuidOtorgante = localStorage.getItem('uuidOtorgante');
    const uuidUser = localStorage.getItem('uuidUser');

    let nombre = obtenerValorUser(dataUser, "nombres") || "";
    let apellidoPaterno = obtenerValorUser(dataUser, "apellidoPaterno") || "";
    let apellidoMaterno = obtenerValorUser(dataUser, "apellidoMaterno") || "";
    let telefono = obtenerValorUser(dataUser, "telefono") || "";
    let correo = obtenerValorUser(dataUser, "email") || "";
    let nombreCompleto = `${nombre} ${apellidoPaterno} ${apellidoMaterno}`;
    const linkVideoLlamada = wss + uuidTransaccion + "?apikey=" + apikey;

    data = {
            clienteTransaccion: uuidTransaccion,
            clienteUuid: uuidUser,
            idOtorgante: uuidOtorgante,
            clienteVideoLink: linkVideoLlamada,
            nombre: nombre,
            apellidoPaterno: apellidoPaterno,
            apellidoMaterno: apellidoMaterno,
            telefono: telefono,
            correo: correo,
            nombreCompleto: nombreCompleto,
            idStatusTurno:'TURNO_NUEVO',
    }

    return pedirTurno(data)
}