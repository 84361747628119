import React, { useState, useEffect, Fragment } from "react";
import { Link, useHistory } from "react-router-dom";
import { configStyle } from '../../services/configStyle'
import { obtenerPais } from '../../services/api';
import NumberFormat from 'react-number-format';
import { hayExcepcion, agregarInfo, statusData, formatoFechaOcr, formatoFechaCuatroDigitos, validarCampo, validarValoresFormulario, evento, validarRenapo, validarINE } from '../../services/data'


let FormularioIneSegundaParte = (props) => {
    const history = useHistory();
    const [dataOtorgante, setDataOtorgante] = useState([])
    const [dataOCRBack, setDataOCRBack] = useState({})
    const [dataOCRFront, setDataOCRFront] = useState({})
    const [focusNombre, setFocusNombre] = useState(false)
    const [focusNacimiento, setFocusNacimiento] = useState(false)
    const [focusCurp, setFocusCurp] = useState(false);
    // const [focusVigencia, setFocusVigencia] = useState(false);
    const [dataUser, setDataUser] = React.useState([])
    const [inputActive, setInputActive] = useState(false)
    const [esPassport, setEsPassport] = useState(false);
    const [esCLaboral, setCLaboral] = useState(true);
    const [noOCR, setNoOCR] = useState(false);
    const [apiKey, setApiKey] = useState('');
    const [inputsValue, setInputsValue] = useState({
        nombres: '',
        apellidoPaterno: '',
        apellidoMaterno: '',
        fechaNacimiento: '',
        rfc: '',
        email:'',
        ocupacion:'',
        telefono:'',
    });
    const [inputsErrors, setInputsErrors] = useState({});
    const [esVigente, setEsVigente] = useState(true)
    const [fechaNacimiento, setfechaNacimiento] = useState("");
    const [focusApellidoPaterno, setFocusApellidoPaterno] = useState(false);
    const [focusApellidoMaterno, setFocusApellidoMaterno] = useState(false);
    const [sendForm, setSendForm] = useState(false);
    const [Genero, setGenero] = useState('');
    const [Pais, setPais] = useState('');
    const [Nacionalidad, setNacionalidad] = useState('');
    const [focusRFC, setFocusRFC] = useState(false)
    const [focusTelefono, setFocusTelefono] = useState(false)
    const [focusEmail, setFocusEmail] = useState(false)
    const [focusOcupacion, setFocusOcupacion] = useState(false)
    const [dataPaises, setDataPaises] = useState([])

    
    useEffect(() => {

        let no_OCR = localStorage.getItem("intentsData")
        if (no_OCR !== null) {
            if (no_OCR === "OCR")
                setNoOCR(true)
        }

        let apikeyOtorgante = localStorage.getItem("apikeyOtorgante")
        if (apikeyOtorgante !== null) {
            setApiKey(apikeyOtorgante)
        }

        let dataOtorganteLS = localStorage.getItem("dataOtorgante")
        if (dataOtorganteLS != null) {
            setDataOtorgante(JSON.parse(dataOtorganteLS))
        }
        let dataUserLS = localStorage.getItem("data_user")
        if (dataUserLS != null) {
            setDataUser(JSON.parse(dataUserLS))
        }
        let dataOCRFrontLS = localStorage.getItem("ocrFront")
        const ocrFront = JSON.parse(dataOCRFrontLS)
        if (dataOCRFrontLS != null) {
            setDataOCRFront(ocrFront);
            if (ocrFront.nombres)
                setInputsValue(inputsValue => ({ ...inputsValue, ["nombres"]: ocrFront.nombres || '' }));
            if (ocrFront.apellidoPaterno)
                setInputsValue(inputsValue => ({ ...inputsValue, ["apellidoPaterno"]: ocrFront.apellidoPaterno || '' }));
            if (ocrFront.apellidoMaterno)
                setInputsValue(inputsValue => ({ ...inputsValue, ["apellidoMaterno"]: ocrFront.apellidoMaterno || '' }));
            if (ocrFront.fechaNacimiento) {
                if (ocrFront.fechaNacimiento.length > 6 && ocrFront.fechaNacimiento.length < 10) {
                    let fechaOcr = formatoFechaCuatroDigitos(ocrFront.fechaNacimiento);
                    let fechaNacimiento = formatoFechaOcr(fechaOcr);
                    setInputsValue(inputsValue => ({ ...inputsValue, ["fechaNacimiento"]: fechaNacimiento }));
                } else {
                    let fechaNacimiento = formatoFechaOcr(ocrFront.fechaNacimiento);
                    setInputsValue(inputsValue => ({ ...inputsValue, ["fechaNacimiento"]: fechaNacimiento }));
                }
                setfechaNacimiento(ocrFront.fechaNacimiento)
            }
            if (ocrFront.curp)
                if (ocrFront.curp.length > 14)
                    setInputsValue(inputsValue => ({ ...inputsValue, ["rfc"]: (ocrFront.curp).trim().substr(0, 10)}))

            let sexo = (ocrFront.sexo === "H") ? "Hombre" : "Mujer"
            setGenero(sexo)
            setPais("México");
            setNacionalidad("MEXICANA");
        }

        obtenerPais().then((response) => {
            if (response.status === 200) {
                //console.log(response);
                setDataPaises(response.data.payload.countries);
            }
        }).catch((error) => {
            console.log(error);
        });
    }, [])

    useEffect(() => {
        configStyle(dataOtorgante)
    }, [dataOtorgante]);

    useEffect(() => {
        localStorage.setItem("data_user", JSON.stringify(dataUser))
    }, [dataUser]);

    const sendData = (infoFormulario) => {
        var jsonObj = {};
        jsonObj.infoPersonal = infoFormulario;
        statusData("datos_personales_ocr", jsonObj)
    }

    const handleChange = (event) => {
        event.persist();
        let value = event.target.value.trim();
        let validacion = validarCampo(value, event.target.name);
        if (event.target.name === "fechaNacimiento") {
            setfechaNacimiento(value)
        }
        if (validacion.correcto) {
            if (event.target.name === "curp") {
                setInputsValue(inputsValue => ({ ...inputsValue, [event.target.name]: value.toUpperCase()}));
            } else {
                setInputsValue(inputsValue => ({ ...inputsValue, [event.target.name]: value }));
            }
            setInputsErrors(inputsErrors => ({ ...inputsErrors, [event.target.name]: '' }));
        } else {
            setInputsValue(inputsValue => ({ ...inputsValue, [event.target.name]: event.target.value }));
            setInputsErrors(inputsErrors => ({ ...inputsErrors, ...validacion.errores }));
        }

    };

    useEffect(() => {
        //console.log("inputsValueM",inputsValue);
    }, [inputsValue]);

    const validarFormulario = (event) => {
        if (event) event.persist();event.preventDefault();event.stopPropagation();
        setSendForm(true);
        //console.log("valores",valores);
        let continuar = validarValoresFormulario(inputsValue);
        if (continuar.correcto) {
            evento('Datos personales OCR', 'Click', { description: 'MIS DATOS SON CORRECTOS', status: 'OK' }, true);

            let infoFormulario = []
            let fechaActual = inputsValue.vigencia
            agregarInfo(dataUser, { description: "nombres", value: inputsValue.nombres });
            infoFormulario.push({ description: "nombres", value: inputsValue.nombres });
            agregarInfo(dataUser, { description: 'apellidoPaterno', value: inputsValue.apellidoPaterno });
            infoFormulario.push({ description: "apellidoPaterno", value: inputsValue.apellidoPaterno });
            agregarInfo(dataUser, { description: "apellidoMaterno", value: inputsValue.apellidoMaterno });
            infoFormulario.push({ description: "apellidoMaterno", value: inputsValue.apellidoMaterno });
            let nombreCompleto = `${inputsValue.nombres} ${inputsValue.apellidoPaterno} ${inputsValue.apellidoMaterno}`
            agregarInfo(dataUser, { description: "nombreCompleto", value: nombreCompleto });
            infoFormulario.push({ description: "nombreCompleto", value: nombreCompleto });
            let fechaNacimiento = document.getElementById("fechaNacimiento").value;
            agregarInfo(dataUser, { description: "nacimiento", value: fechaNacimiento });
            infoFormulario.push({ description: "nacimiento", value: fechaNacimiento });
            agregarInfo(dataUser, { description: "rfc", value: inputsValue.rfc });
            infoFormulario.push({ description: "rfc", value: inputsValue.rfc });
            agregarInfo(dataUser, { description: "email", value: inputsValue.email });
            infoFormulario.push({ description: "email", value: inputsValue.email });
            agregarInfo(dataUser, { description: "ocupacion", value: inputsValue.ocupacion });
            infoFormulario.push({ description: "ocupacion", value: inputsValue.ocupacion });
            agregarInfo(dataUser, { description: "telefono", value: inputsValue.telefono });
            infoFormulario.push({ description: "telefono", value: inputsValue.telefono });
                setTimeout(() => {
                        history.push('/identificacion_edit');
                }, 300);
        } else {
            evento('Datos personales OCR', 'Click', { description: 'MIS DATOS SON CORRECTOS', status: 'Error', Errores: continuar.errores }, false);
            setInputsErrors(inputsErrors => ({ ...inputsErrors, ...continuar.errores }));
            setSendForm(false);
        }
    }



    return (
        <Fragment>
            <form id="FormularioIneSegundaParte">
                <div className="ocr_data_display animate__animated">
                    <div className={["form-group", "bmd-form-group", ((focusNombre || inputsValue.nombres !== "") ? "is-focused" : ""), inputsErrors.nombres && 'error'].join(" ")}>
                        <label htmlFor="nombres" className="bmd-label-floating">Nombre(s):</label>
                        <input type="text" className="form-control none_border" id="nombres" name="nombres" defaultValue={inputsValue.nombres} onChange={handleChange} onFocus={e => {
                            setFocusNombre(true)
                            setInputActive(true)
                        }} onBlur={e => {
                            if (inputsValue.nombres === "") {
                                setFocusNombre(false)
                            }
                            setInputActive(false)
                        }} />
                        {inputsErrors.nombres && (
                            <span id="ht-nombres" className="helper-text ht" data-error="wrong" data-success="right">{inputsErrors.nombres}</span>
                        )}
                    </div>
                    <div className={["form-group", "bmd-form-group", ((focusApellidoPaterno || inputsValue.apellidoPaterno !== "") ? "is-focused" : ""), inputsErrors.apellidoPaterno && 'error'].join(" ")}>
                        <label htmlFor="apellidoPaterno" className="bmd-label-floating">Apellido Paterno:</label>
                        <input type="text" className="form-control none_border" id="apellidoPaterno" name="apellidoPaterno" defaultValue={inputsValue.apellidoPaterno} onChange={handleChange} onFocus={e => {
                            setFocusApellidoPaterno(true)
                            setInputActive(true)
                        }} onBlur={e => {
                            if (inputsValue.nombres === "") {
                                setFocusApellidoPaterno(false)
                            }
                            setInputActive(false)
                        }} />
                        {inputsErrors.apellidoPaterno && (
                            <span id="ht-apellidoPaterno" className="helper-text ht" data-error="wrong" data-success="right">{inputsErrors.apellidoPaterno}</span>
                        )}
                    </div>
                    <div className={["form-group", "bmd-form-group", ((focusApellidoMaterno || inputsValue.apellidoMaterno !== "") ? "is-focused" : ""), inputsErrors.apellidoMaterno && 'error'].join(" ")}>
                        <label htmlFor="apellidoMaterno" className="bmd-label-floating">Apellido Materno:</label>
                        <input type="text" className="form-control none_border" id="apellidoMaterno" name="apellidoMaterno" defaultValue={inputsValue.apellidoMaterno} onChange={handleChange} onFocus={e => {
                            setFocusApellidoMaterno(true)
                            setInputActive(true)
                        }} onBlur={e => {
                            if (inputsValue.apellidoMaterno === "") {
                                setFocusApellidoMaterno(false)
                            }
                            setInputActive(false)
                        }} />
                        {inputsErrors.apellidoMaterno && (
                            <span id="ht-apellidoMaterno" className="helper-text ht" data-error="wrong" data-success="right">{inputsErrors.apellidoMaterno}</span>
                        )}
                    </div>
                    <div className={["form-group", "bmd-form-group", ((focusNacimiento || inputsValue.fechaNacimiento) ? "is-focused" : ""), inputsErrors.fechaNacimiento && 'error'].join(" ")}>
                        <label htmlFor="fechaNacimiento" className="bmd-label-floating">Fecha de nacimiento <small>(DD/MM/AAAA)</small>:</label>
                        <NumberFormat format="##/##/####" className="form-control none_border" id="fechaNacimiento" name="fechaNacimiento" defaultValue={inputsValue.fechaNacimiento} value={fechaNacimiento} onChange={handleChange} onFocus={e => {
                            setFocusNacimiento(true)
                            setInputActive(true)
                        }
                        } onBlur={e => {
                            if (inputsValue.fechaNacimiento === "") {
                                setFocusNacimiento(false)
                            }
                            setInputActive(false)
                        }} />
                        {inputsErrors.fechaNacimiento && (
                            <span id="ht-nacimiento" className="helper-text ht" data-error="wrong" data-success="right">{inputsErrors.fechaNacimiento}</span>
                        )}
                    </div>
                    
                    <div className={["form-group", "bmd-form-group", "is-focused"].join(" ")}>
                        <label htmlFor="inputGeneroCompleto" className="bmd-label-floating">Género:</label>
                        <select className="custom-select" id="inputColS" onChange={e => setGenero(e.target.value)} value={Genero} >
                            <option value="" disabled="disabled" id="uno">Seleccionar:</option>
                            <option value="Mujer" id="M">Mujer</option>
                            <option value="Hombre" id="H">Hombre</option>
                        </select>
                        {inputsErrors.genero && (
                            <span id="ht-nacimiento" className="helper-text ht" data-error="wrong" data-success="right">{inputsErrors.genero}</span>
                        )}                    
                        </div>

                    <div className={["form-group", "bmd-form-group", "is-focused"].join(" ")}>
                        <label htmlFor="inputColS" className="bmd-label-floating">País:</label>
                        <select className="custom-select" id="inputColS" onChange={e => setPais(e.target.value)} value={Pais} >
                            <option value="" disabled="disabled" id="uno">Seleccionar:</option>
                            {(dataPaises) ?
                                dataPaises.map((item, index) => (
                                    <option value={item.name} id={item.code} key={index}>{item.name}</option>
                                ))
                                :
                                ""
                            }
                        </select>
                        {inputsErrors.pais && (
                            <span id="ht-nacimiento" className="helper-text ht" data-error="wrong" data-success="right">{inputsErrors.pais}</span>
                        )}   
                    </div>

                    <div className={["form-group", "bmd-form-group", "is-focused"].join(" ")}>
                        <label htmlFor="inputNacionalidadCompleto" className="bmd-label-floating">Nacionalidad:</label>
                        <select className="custom-select" id="inputColS" onChange={e => setNacionalidad(e.target.value)} value={Nacionalidad} >
                            <option value="" disabled="disabled" id="uno">Seleccionar:</option>
                            {(dataPaises) ?
                                dataPaises.map((item, index) => (
                                    <option value={item.demonym} id={item.code} key={index}>{item.demonym}</option>
                                ))
                                :
                                ""
                            }
                        </select>
                        {inputsErrors.nacionalidad && (
                            <span id="ht-nacimiento" className="helper-text ht" data-error="wrong" data-success="right">{inputsErrors.nacionalidad}</span>
                        )}   
                    </div>

                    <div className={["form-group", "bmd-form-group", ((focusRFC || inputsValue.rfc !== "") ? "is-focused" : ""), inputsErrors.rfc && 'error'].join(" ")}>
                        <label htmlFor="rfc" className="bmd-label-floating">RFC:</label>
                        <input type="text" className="form-control uppercase" id="rfc" name="rfc" maxLength="15" defaultValue={inputsValue.rfc} onChange={handleChange} onFocus={e => {
                            setFocusRFC(true)
                        }} onBlur={e => {
                            if (inputsValue.rfc === "") {
                                setFocusRFC(false)
                            }
                        }} />
                        {inputsErrors.rfc && (
                            <span id="ht-rfc" className="helper-text ht" data-error="wrong" data-success="right">{inputsErrors.rfc}</span>
                        )}                    
                    </div>

                    <div className={["form-group", "bmd-form-group", ((focusEmail || inputsValue.email !== "") ? "is-focused" : ""), inputsErrors.email && 'error'].join(" ")}>
                        <label htmlFor="email" className="bmd-label-floating">Correo electrónico:</label>
                        <input type="email" className="form-control" id="email" name="email" defaultValue={inputsValue.email} onChange={handleChange} onFocus={e => {
                            setFocusEmail(true)
                        }} onBlur={e => {
                            if (inputsValue.email === "") {
                                setFocusEmail(false)
                            }
                        }} />
                        {inputsErrors.email && (
                            <span id="ht-email" className="helper-text ht" data-error="wrong" data-success="right">{inputsErrors.email}</span>
                        )}                    
                    </div>

                    <div className={["form-group", "bmd-form-group", ((focusOcupacion || inputsValue.ocupacion !== "") ? "is-focused" : ""), inputsErrors.ocupacion && 'error'].join(" ")}>
                        <label htmlFor="ocupacion" className="bmd-label-floating">Ocupación, profesión, actividad o giro:</label>
                        <input type="text" className="form-control" id="ocupacion" name="ocupacion" defaultValue={inputsValue.ocupacion} onChange={handleChange} onFocus={e => {
                            setFocusOcupacion(true)
                        }} onBlur={e => {
                            if (inputsValue.ocupacion === "") {
                                setFocusOcupacion(false)
                            }
                        }} />
                        {inputsErrors.ocupacion && (
                            <span id="ht-ocupacion" className="helper-text ht" data-error="wrong" data-success="right">{inputsErrors.ocupacion}</span>
                        )}
                    </div>

                    <div className={["form-group", "bmd-form-group", ((focusTelefono || inputsValue.telefono !== "") ? "is-focused" : ""), inputsErrors.telefono && 'error'].join(" ")}>
                        <label htmlFor="telefono" className="bmd-label-floating">Número móvil:</label>
                        <input type="text" className="form-control" id="telefono" name="telefono" defaultValue={inputsValue.telefono} maxLength="10" onChange={handleChange} onFocus={e => {
                            setFocusTelefono(true)
                        }} onBlur={e => {
                            if (inputsValue.telefono === "") {
                                setFocusTelefono(false)
                            }
                        }} />
                        {inputsErrors.telefono && (
                            <span id="ht-telefono" className="helper-text ht" data-error="wrong" data-success="right">{inputsErrors.telefono}</span>
                        )}
                    </div>

                </div>
                <div className="action_buttons animate__animated">
                    <button type="button" className={["btn btn-raised btn-primary forcewidth100 main_bg_color"].join(" ")} onClick={event => validarFormulario(event)} disabled={sendForm}>MIS DATOS SON CORRECTOS</button>
                    <Link to={{
                        pathname: "/identificacion",
                        state: { passport: esPassport }
                    }} className="btn btn-primary forcewidth100 main_color" onClick={e => evento('Datos personales OCR', 'Click', { description: 'CAPTURAR NUEVAMENTE' }, true)}>CAPTURAR NUEVAMENTE</Link>
                </div>
            </form>
        </Fragment>
    )
}

export default FormularioIneSegundaParte