import React, { useState, useEffect, Fragment, useRef } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import Header from "../components/headerNA";
import Footer from "../components/footer";
import { configStyle, positionDiv } from '../services/configStyle'
import { hayExcepcion, status, statusError, evento, sendEventClick } from '../services/data'
import Ayuda from './ayuda';
import { isMobile } from 'react-device-detect';

const PreparacionIne = () => {
    const history = useHistory();
    const [dataOtorgante, setDataOtorgante] = useState([])
    const [showCancel, setShowCancel] = useState(false)
    const [apiKey, setApiKey] = useState('')
    const { ruta } = useParams();
    const divFooterRef = useRef(null);
    const [showHelp, setShowHelp] = useState(false);
    const [step, setStep] = useState(null);

    useEffect(() => {

        let apikeyOtorgante = localStorage.getItem("apikeyOtorgante")
        if (apikeyOtorgante !== null) {
            setApiKey(apikeyOtorgante)
        }

        let dataOtorganteLS = localStorage.getItem("dataOtorgante")
        if (dataOtorganteLS != null) {
            setDataOtorgante(JSON.parse(dataOtorganteLS))
        }
        status("preparacion_id", "Preparacion ID");
        setStep(isMobile ? '/holograma' : '/identificacion');

        let divFooter = divFooterRef.current;
        divFooter.style.position = positionDiv(window.innerWidth, window.innerHeight);
    }, [])

    useEffect(() => {
        configStyle(dataOtorgante)
    }, [dataOtorgante])

    const statusE = () => {
        sendEventClick('Preparacion ID','SI', {status: 'CANCELADO'});
        let data = "Cancelado"
        localStorage.setItem('flag', 'cancelado');
        statusError("preparacion_id_page", data, "cancelado");
        setTimeout(() => {
            history.push("/" + (ruta !== "pdf_video_token" ? apiKey : `pdf_token?uuid=${localStorage.getItem("uuidUser")}&idOtorgante=${localStorage.getItem("idOtorgante")}&fecha=${localStorage.getItem("fechavigencia")}`))
        }, 300);
    }

    return (
        <Fragment>
            {(showCancel) ?
                <div className="modal fade show" style={{ display: "block" }} role="dialog">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Cancelar proceso</h5>
                                <button onClick={e => setShowCancel(false)} className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <p>En este momento se cancelará el proceso, ningún dato será guardado y perderás el avance, esta acción no podrá deshacerse <br /> ¿Deseas cancelar?</p>
                            </div>
                            <div className="modal-footer">
                                <button onClick={e => {setShowCancel(false); sendEventClick('Preparacion ID','NO', {});}} className="btn btn-secondary" data-dismiss="modal">NO</button>
                                <button type="button" onClick={e => statusE()} className="btn btn-raised btn-primary main_bg_color">SI</button>
                            </div>
                        </div>
                    </div>
                </div> : ("")}
            <div className="main_gradient">
                <Header ruta="preparacion_identificacion" show={() => setShowHelp(true)}/>
                {(hayExcepcion()) ?
                    <div>
                        <div className="main_text_container">
                            <h1 className="animate__animated">Captura tu Documento Nacional de Identidad</h1>
                            <p className="animate__animated">Para capturar tu DNI asegúrate que se encuentre <b>vigente</b>, evita <b>reflejos</b>, de preferencia frente a una <b>superficie obscura</b> para mejorar el contraste.</p>
                        </div>
                        <div className="row id_type_bx">
                            <div className="col-12">
                                <div className="d-flex flex-column justify-content-center align-items-center">
                                    <h6>DNI</h6>
                                    <div className="maskface id_central"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    <div>
                        <div className="main_text_container custom_padding">
                            <h1 className="animate__animated">Tómale una foto a tu identificación oficial</h1>
                            <br /><br />
                            <p className="animate__animated">Pero antes, <b>considera</b> estos puntos:
                        <br />
                                <div className="spaceLi">- Que esté <b>vigente</b></div>
                                <div className="spaceLi">- Toma una foto <b>enfocada</b> y <b>sin reflejos</b></div>
                            </p>
                            <br />
                            <p className="animate__animated">Identificaciones <b>permitidas</b>:</p>
                            <div className="row id_type_bx">
                                <div className="col-12">
                                    <div className="d-flex flex-column justify-content-center align-items-center">
                                        <h6>INE/IFE</h6>
                                        <div className="maskface id_central"></div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                }
                <div className="div_bottom" ref={divFooterRef}>
                    <div className="action_buttons">
                        <Link to={{
                            // pathname: "/identificacion",
                            pathname: step,
                            state: { passport: false }
                        }} className="btn btn-raised btn-primary forcewidth100 main_bg_color" onClick={() => sendEventClick('Preparacion ID', 'CAPTURAR IDENTIFICACIÓN')}>
                            CAPTURAR IDENTIFICACIÓN</Link>
                        <button onClick={e => {
                            setShowCancel(true);
                            sendEventClick('Preparacion ID','CANCELAR', {});
                        }} className="btn btn-primary forcewidth100 main_color">
                            CANCELAR</button>
                    </div>
                    {/* <Footer /> */}
                </div>
            </div>
            <div className={(showCancel ? "modal-backdrop show" : "")}></div>
            {showHelp ? <Ayuda hide={() => setShowHelp(false)} /> : ""}
        </Fragment>
    )

}


export default PreparacionIne