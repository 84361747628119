import React, { useEffect } from 'react';
import {evento} from '../../services/data'


const Index = () => {
    const entries =  window.performance ? window.performance.getEntriesByType("navigation") : [];
    const data = entries.length > 0 ? entries[0].type : '';
    const ruta = window.location.pathname;

    useEffect(() => {
        if(data === 'reload'){
            evento('Recargar página web', 'Step', {ruta: ruta}, true);
        }
        return () => { };
    }, []);

    return (
        <></>
    );
}

export default Index;
