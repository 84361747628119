import React, { useState, useEffect, Fragment } from "react";
import Footer from "../components/footer";
import { configStyle, obtenerValorConfig } from '../services/configStyle'

const IndexDesktop = () => {

    const [dataOtorgante, setDataOtorgante] = useState([]);

    useEffect(() => {
        let dataOtorganteLS = localStorage.getItem("dataOtorgante")
        if (dataOtorganteLS != null) {
            setDataOtorgante(JSON.parse(dataOtorganteLS))
            configStyle(JSON.parse(dataOtorganteLS))
        }
    }, []);

    useEffect(() => {
        configStyle(dataOtorgante);
        let logo = (obtenerValorConfig(dataOtorgante, 'LOGO_OTORGANTE', ""));
        document.getElementById("imagenLogo").src = logo;
    }, [dataOtorgante])

    return (
        <Fragment>
            <div className="container">

                <div className="main_gradient nubgcont">

                    <div id="nuhead_container">

                    <img id="imagenLogo" className="comp_logo" src={(obtenerValorConfig(dataOtorgante, 'LOGO_OTORGANTE', "") != "") ? obtenerValorConfig(dataOtorgante, 'LOGO_OTORGANTE', process.env.PUBLIC_URL + '/images/wl/comp_logo.png') : process.env.PUBLIC_URL + '/images/wl/comp_logo.png'} alt="" />
                        {/* <a href="#" className="help_btn animate__animated"><img src="./images/help_icon.png" alt="" /></a> */}

                    </div>



                    <div className="numain_text_container">

                        <h1>¡Bienvenido!</h1>

                        <p>
                            En <b>DICIO</b> hemos creado una <b>experiencia</b> de <b>última generación</b> para ti, por favor <b>continua</b> en tu <b>teléfono móvil</b> para completar el proceso solicitado.

    <br /><br />

                            <b>Escanea</b> el código <b>QR</b> mostrado o ingresa desde tu <b>navegador</b> móvil a
    <br /><a href="#!">http://bit.ly/dicio_ce</a>

                        </p>
                        <br />
                        <p className="qr_mobile"><img src="images/dsktp_to_mb/qr_icon.svg" alt="" /></p>

                    </div>

                    <div className="nuqrbx">

                        <div className="center_qr">

                            <img className="qr_focus" src="images/dsktp_to_mb/qr_focus.svg" alt="" />

                            <div className="qr_bx">

                                <img src="images/dsktp_to_mb/qr.png" alt="" />

                            </div>

                        </div>

                    </div>



                    <Footer />

                </div>

            </div>

        </Fragment>
    );
}

export default IndexDesktop;