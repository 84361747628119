import React, { useState, useEffect } from "react";
import Header from "../components/header";
import { configStyle, obtenerValorConfig, obtenerValorUser, replaceValue } from '../services/configStyle';
import { Link } from "react-router-dom";

const ErrorIntentos = () => {
    const [apiKey, setApiKey] = useState('');
    const [dataUser, setDataUser] = React.useState([])
    const [userID, setUuid] = useState("");
    const [dataOtorgante, setDataOtorgante] = useState([]);
    const [dataFace, setDataFace] = useState({});

    useEffect(() => {
        let apikeyOtorgante = localStorage.getItem("apikeyOtorgante")
        if (apikeyOtorgante !== null) {
            setApiKey(apikeyOtorgante)
        }
        let dataOtorganteLS = localStorage.getItem("dataOtorgante")
        if (dataOtorganteLS != null) {
            setDataOtorgante(JSON.parse(dataOtorganteLS))
        }
        let uuidUser = localStorage.getItem("uuidUser")
        if (uuidUser != null) {
            setUuid(uuidUser)
        }

        let dataUserLS = localStorage.getItem("data_user");
        if (dataUserLS !== null) {
            let dataUser = JSON.parse(dataUserLS)
            setDataUser(JSON.parse(dataUserLS))
        }

        let dataFaceLS = localStorage.getItem("face")
        if (dataFaceLS != null) {
            setDataFace(JSON.parse(dataFaceLS))
        }

    }, [])

    useEffect(() => {
        configStyle(dataOtorgante)
    }, [dataOtorgante])

    useEffect(() => {
        //localStorage.setItem("data_user", JSON.stringify(dataUser))
        console.log("datauser");
    }, [dataUser])


    return (
        <div className="main_gradient animate__animated animate__slideInUp">
        <Header ruta="finalizado" />
        <div className="main_text_container">
            <h1 className="animate__animated animate__fadeIn animate__delay-1s">Proceso no exitoso</h1>
            <div className="animate__animated animate__fadeIn animate__delay-2s">
                <p>Has superado el número <b>máximo</b> de intentos para este proceso inténtalo nuevamente <b>más tarde</b>.</p>
                <p>Si deseas mayor información no dudes en contactarnos con el <b>ID</b> de proceso <b>{(userID).split("-")[0]}</b> </p>
                <br />
            </div>
        </div>
        <div>
            <div className="main_text_container animate__animated animate__fadeIn animate__delay-2s" style={{ padding: "0 10%" }}>
                <div className="help_container">
                    <p className="help_mail"><b>Escríbenos a:</b>
                        <br />
                        <br />
                        <img src={process.env.PUBLIC_URL + '/images/arroba.svg'} /><a href={"mailto:" + obtenerValorConfig(dataOtorgante, 'EMAIL_CONTACTO_PRINCIPAL')}>{obtenerValorConfig(dataOtorgante, "EMAIL_CONTACTO_PRINCIPAL")}</a>
                        <br />
                        <br />
                    </p>
                    <p className="help_phone"><b>Llámanos al:</b>
                        <br />
                        <br />
                        <img src={process.env.PUBLIC_URL + '/images/phone.svg'} /><a href={"tel:" + obtenerValorConfig(dataOtorgante, 'TEL_CONTACTO_PRINCIPAL')}>{obtenerValorConfig(dataOtorgante, 'TEL_CONTACTO_PRINCIPAL')}</a>
                        <br />
                        <br />
                    </p>
                    <p className="help_whats"><b>Hablemos en:</b>
                        <br />
                        <br />
                        <img src={process.env.PUBLIC_URL + '/images/whatsapp.svg'} /><a rel="external" href={"https://wa.me/0525567068439?text=Tuve%20problemas%20en%20el%20proceso%2C%20mi%20ID%20de%20transacci%C3%B3n%20es%20%3A%20" + userID.split("-")[0]}>WhatsApp</a>
                        <br />
                        <br />
                    </p>
                </div>
            </div>
            {/*
                (localStorage.getItem("package") !== null) ?
                    <div className="action_buttons">
                        <a className="btn btn-raised btn-primary forcewidth100 main_bg_color animate__animated animate__delay-3s" href={"" + localStorage.getItem("package") + "://folio=" + localStorage.getItem("uuidUser")}>
                            Volver al origen
                        </a>
                    </div>
                    :
                    (!window.opener) ?
                        <div className="action_buttons">
                            <a className="btn btn-raised btn-primary forcewidth100 main_bg_color animate__animated animate__delay-3s" href={"https://" + window.location.host + "/" + localStorage.getItem("apikeyOtorgante")}>
                                TERMINAR
                            </a>
                        </div>
                        :
                        null
            */}
        </div>
        <div className="action_buttons">
            <Link to={"/" + apiKey} className="btn btn-raised btn-primary forcewidth100 main_bg_color">ENTENDIDO</Link>
        </div>
    </div>
    )

}

export default ErrorIntentos