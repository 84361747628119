import React, { useState, useEffect, Fragment } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import Header from "../components/headerNA";
import Footer from "../components/footer";
import { configStyle } from '../services/configStyle'
import { status, statusError, sendEventClick, generateZip } from '../services/data';
import Ayuda from './ayuda';
import Loader from '../components/loader';
import { isIOS } from 'react-device-detect';

const PreparacionComprobante = () => {
    const history = useHistory();
    const [dataOtorgante, setDataOtorgante] = useState([])
    const [showCancel, setShowCancel] = useState(false)
    const [apiKey, setApiKey] = useState('')
    const { ruta } = useParams();
    const [showHelp, setShowHelp] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {

        let apikeyOtorgante = localStorage.getItem("apikeyOtorgante")
        if (apikeyOtorgante !== null) {
            setApiKey(apikeyOtorgante)
        }

        let dataOtorganteLS = localStorage.getItem("dataOtorgante")
        if (dataOtorganteLS != null) {
            setDataOtorgante(JSON.parse(dataOtorganteLS))
        }
        status("preparacion_comprobante", "Preparacion Comprobante");
    }, [])

    useEffect(() => {
        configStyle(dataOtorgante)
    }, [dataOtorgante])

    const statusE = async () => {
        setLoading(true);
        sendEventClick('Preparacion Comprobante','SI', {status: 'CANCELADO'});
        let data = "Cancelado"
        localStorage.setItem('flag', 'cancelado');
        await generateZip('TRUNCOS', 'trunco', isIOS);
        statusError("preparacion_comprobante", data, "cancelado");
        setTimeout(() => {
            history.push("/" + (ruta !== "pdf_video_token" ? apiKey : `pdf_token?uuid=${localStorage.getItem("uuidUser")}&idOtorgante=${localStorage.getItem("idOtorgante")}&fecha=${localStorage.getItem("fechavigencia")}`));
            setLoading(false);
        }, 300);
    }

    return (
        <>
            {(showCancel) ?
                <div className="modal fade show" style={{ display: "block" }} role="dialog">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Cancelar proceso</h5>
                                <button onClick={e => setShowCancel(false)} className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <p>En este momento se cancelará el proceso, ningún dato será guardado y perderás el avance, esta acción no podrá deshacerse <br /> ¿Deseas cancelar?</p>
                            </div>
                            <div className="modal-footer">
                                <button onClick={() => {setShowCancel(false);  sendEventClick('Preparacion Comprobante', 'NO');}} className="btn btn-secondary" data-dismiss="modal">NO</button>
                                <button type="button" onClick={() => {statusE();  sendEventClick('Preparacion Comprobante', 'SI')}} className="btn btn-raised btn-primary main_bg_color">SI</button>
                            </div>
                        </div>
                    </div>
                </div> : ("")}
            <div className="main_gradient">
                <Header ruta="preparacion_comprobante" show={() => setShowHelp(true)}/>
                <div>
                    <div className="main_text_container">
                        <h1 className="animate__animated">Envía tu comprobante de domicilio</h1>
                        <p className="animate__animated">Puedes capturar tu <b>recibo de luz</b>, <b>teléfono fijo</b>, <b>internet</b> o <b>gas</b>.</p>
                    </div>
                    <div className="main_icon_container">
                        <img src="images/bnw/doc_check_b.svg" alt="" />
                    </div>
                </div>

                <div className="action_buttons noscroll_screen">
                    <Link to={{
                        pathname: "/captura_comprobante",
                        state: { passport: false }
                    }} className="btn btn-raised btn-primary forcewidth100 main_bg_color"
                    onClick={() => sendEventClick('Preparacion Comprobante', 'CAPTURAR')}>
                        CAPTURAR</Link>
                    <button onClick={() => {setShowCancel(true);  sendEventClick('Preparacion Comprobante', 'CAPTURAR');}} className="btn btn-primary forcewidth100 main_color">
                        CANCELAR</button>
                </div>
                {/* <Footer /> */}
            </div>
            <div className={(showCancel ? "modal-backdrop show" : "")}></div>
            {showHelp ? <Ayuda hide={() => setShowHelp(false)} /> : ""}
            {loading && <Loader/>}
        </>
    )

}


export default PreparacionComprobante