import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import Header from "../components/headerNA";
import Footer from "../components/footer";
import { obtenerEstadoEnlace } from '../services/api';
import Ayuda from './ayuda';

const PdfTokenResult = () => {
    const [enlace] = useState(localStorage.getItem("enlace"));
    const history = useHistory();
    const [showHelp, setShowHelp] = useState(false);

    const descargaArchivo = () => {
        obtenerEstadoEnlace(enlace).then((response) => {
            console.info(response);
            if (response.status === 200) {
                //console.log("descarga");
                const a = document.createElement('a');
                a.style.display = 'none';
                a.href = enlace;
                a.download = "pdf_autorizacion.pdf";
                document.body.appendChild(a);
                a.click();
            }
        }).catch((error) => {
            console.error(error);
            //console.log("no descargar", error);
            history.push("/pdf_video_token");
        });
    };

    return (<div className="main_gradient">
        <Header ruta="pdf_token_result" show={() => setShowHelp(true)}/>
        <div className="main_text_container">
            <h1>Resultado de proceso </h1>
            <p>Tu documento <b>esta listo</b>, puedes <b>verlo</b> o <b>descargarlo</b> en este momento.</p>
            <br /><br />
            <small>*<b>Respalda</b> tu documento, solo se puede <b>consultar una vez</b>.</small>
        </div>
        <div className="action_buttons noscroll_screen">
            <button type="button" onClick={(e) => { descargaArchivo(); }} className="btn btn-raised btn-primary forcewidth100 main_bg_color">DESCARGAR DOCUMENTO</button>
            {/*  <a href={localStorage.getItem("enlace")} className="btn btn-raised btn-primary forcewidth100 main_bg_color">DESCARGAR DOCUMENTO</a> */}
        </div>
        <Footer />
        {showHelp ? <Ayuda hide={() => setShowHelp(false)} /> : ""}
    </div>)
}

export default PdfTokenResult