import React, { useEffect } from 'react';
import './App.css';
import {
  Router,
  Switch,
  Route
} from "react-router-dom";

import Ayuda from './pages/ayuda';
import ConsultaHistorial from './pages/consulta_historial';
import IdentificacionEdit from './pages/identificacion_edit';
import Finalizado from './pages/finalizado';
import PdfTokenInstructions from './pages/pdf_token_instructions';
import PdfTokenError from './pages/pdf_token_error';
import PdfTokenResult from './pages/pdf_token_result';
import PreparacionIne from './pages/preparacion_ine';
import ResultadosIdentificacion from './pages/resultados_identificacion';
import Terminos from './pages/terminos';
import Inicio from './pages/inicio';
import CancelarFlujo from './pages/cancelar_flujo';
import Clausula from './pages/clausula';
import ValidacionDocumento from './pages/validacion_documento';
import AutorizacionSolicitud from './pages/autorizacion_solicitud';
import AutorizacionDocumento from './pages/autorizacion_documento';
import CameraPhotoIOS from './components/cameraphoto_ios';
import CameraVideoTokenIOS from './components/cameravideotoken_ios';
import { createBrowserHistory } from "history";
import ErrorPantalla from './pages/error_pantalla';
import PasosConfiguracion from './pages/pasos_configuracion';
import PdfToken from './pages/pdf_token';
import PDFVideoToken from './components/pdfvideotoken';
import ValidarMovil from './pages/validar_movil';
import ValidarSMS from './pages/validar_sms';
import ResultadosIne from './pages/resultados_ine'
import PreparacionHuella from './pages/preparacion_huella'
import HuellasInvalidas from './pages/huellas_invalidas'
import PreparacionComprobante from './pages/preparacion_comprobante'
import CameraPhotoComprobante from "./components/cameraphotoComprobante_ios";
import AutorizacionLlamada from "./pages/autorizacion_llamada";
import ValidacionFinal from './pages/validacion_final';
import CameraPhotoHologram from './components/cameraphoto_hologram';
import CapturaHuellas from './pages/captura_huellas';
import IndexComponenteCapturaIdentificacion from './components/captura_identificacion/index';
import { OpenCvProvider } from 'opencv-react';
import Receiver from './pages/videollamada/receiver';
import NavigatorReload from './utilities/navigatorReload';
import { ReactInternetSpeedMeter } from 'react-internet-meter';
import 'react-internet-meter/dist/index.css';
import { isMobile } from 'react-device-detect';
import { evento } from './services/data';

const history = createBrowserHistory();

function App() {
  return (

    <Router history={history}>
      <Switch>
        <Route path="/terminos">
          <Terminos />
        </Route>
        <Route path="/pasos_configuracion">
          <PasosConfiguracion />
        </Route>
        <Route path="/preparacion_identificacion">
          <PreparacionIne />
        </Route>
        <Route path="/holograma" component={CameraPhotoHologram}>
        </Route>
        {/* <Route path="/identificacion" component={CameraPhotoIOS}>
        </Route> */}
        <Route path="/identificacion" >
          <OpenCvProvider>
            <IndexComponenteCapturaIdentificacion />
          </OpenCvProvider>
        </Route>
        <Route path="/video_token" component={CameraVideoTokenIOS}>
        </Route>
        <Route path="/pdf_video_token" component={PDFVideoToken}>
        </Route>
        <Route path="/resultados_identificacion">
          <ResultadosIdentificacion />
        </Route>
        <Route path="/identificacion_edit">
          <IdentificacionEdit />
        </Route>
        <Route path="/consulta_historial">
          <ConsultaHistorial />
        </Route>
        <Route path="/error_pantalla">
          <ErrorPantalla />
        </Route>
        <Route path="/validacion_documento">
          <ValidacionDocumento />
        </Route>
        <Route path="/autorizacion_solicitud">
          <AutorizacionSolicitud />
        </Route>
        <Route path="/autorizacion_documento">
          <AutorizacionDocumento />
        </Route>
        <Route path="/ayuda/:ruta">
          <Ayuda />
        </Route>
        <Route path="/finalizado">
          <Finalizado />
        </Route>
        <Route path="/pdf_token_instructions">
          <PdfTokenInstructions />
        </Route>
        <Route path="/pdf_token_error">
          <PdfTokenError />
        </Route>
        <Route path="/pdf_token_result">
          <PdfTokenResult />
        </Route>
        <Route path="/clausula">
          <Clausula />
        </Route>
        <Route path="/cancelar_flujo/:ruta">
          <CancelarFlujo />
        </Route>
        <Route path="/pdf_token" >
          <PdfToken />
        </Route>
        <Route path="/validar_movil">
          <ValidarMovil />
        </Route>
        <Route path="/validar_sms">
          <ValidarSMS />
        </Route>
        <Route path="/resultados_cic">
          <ResultadosIne />
        </Route>
        <Route path="/preparacion_huellas">
          <PreparacionHuella />
        </Route>
        <Route path="/huella_descripcion">
          <HuellasInvalidas />
        </Route>
        <Route path="/captura_huellas">
          <CapturaHuellas />
        </Route>
        <Route path="/preparacion_comprobante">
          <PreparacionComprobante />
        </Route>
        <Route path="/captura_comprobante">
          <CameraPhotoComprobante />
        </Route>
        <Route path="/autorizacion_llamada">
          <AutorizacionLlamada />
        </Route>
        <Route path="/validacion_final">
          <ValidacionFinal />
        </Route>
        <Route path="/videollamada">
          <Receiver />
        </Route>
        <Route path="/:apikey">
          <Inicio />
        </Route>
      </Switch>
      <NavigatorReload />
      <ReactInternetSpeedMeter
        txtSubHeading="Se detectó intermitencia en la red"
        outputType="alert"
        customClassName={null}
        txtMainHeading="Red lenta"
        pingInterval={4000} // milliseconds 
        thresholdUnit='megabyte' // "byte" , "kilobyte", "megabyte" 
        threshold={isMobile ? 2 : 5}
        imageUrl="https://i.postimg.cc/kgr10Ccb/apertura-logo.png"
        downloadSize="1781287"  //bytes
        //callbackFunctionOnNetworkDown={(speed) => console.log(`Internet speed is down ${speed}`)}
        callbackFunctionOnNetworkDown={(speed) => evento('Intermitencia en la red', 'Step', { velociodad: speed }, true)}
        callbackFunctionOnNetworkTest={(speed) => { return }}
      />
    </Router>
  );
}

export default App;
