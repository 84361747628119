import React, { useState, useEffect } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import Header from "../components/headerNA";
import Footer from "../components/footer";
import { actualizarEstado, revisarTurno, enviarCorreoAceptacion, getDocuments, finalUpdateScore } from '../services/api.js';
import { configStyle, obtenerValorConfig, obtenerValorUser, replaceValue } from '../services/configStyle'
import Loader from "../components/loader";
import { isMobile, isIOS } from 'react-device-detect';
import { hayExcepcion, status, statusError, statusData, regresarJson, sendData, evento, sendEventClick } from '../services/data';
import Ayuda from './ayuda';

const Finalizado = (props) => {
    const history = useHistory();
    const [loading, setLoading] = useState(true);
    const [apiKey, setApiKey] = useState('')
    const [uuid, setUuid] = useState('-')
    const [dataUser, setDataUser] = React.useState([])
    const [dataOtorgante, setDataOtorgante] = useState([])
    const [dataFace, setDataFace] = useState({})
    const [isFlag, setFlag] = useState('');
    const { ruta } = useParams();
    const [Veredicto, setVeredicto] = useState('');
    const [showHelp, setShowHelp] = useState(false);
    const intentos = 15;
    const [uuidTrx, setUuidTrx] = useState('-');
    const [finished, setFinished] = useState(false);
    const [correo, setCorreo] = useState('');

    useEffect(() => {
        let apikeyOtorgante = localStorage.getItem("apikeyOtorgante")
        if (apikeyOtorgante !== null) {
            setApiKey(apikeyOtorgante)
        }

        let uuidUser = localStorage.getItem("uuidUser")
        if (uuidUser != null) {
            setUuid(uuidUser)
        }

        let uuidTrx = localStorage.getItem("uuidTrx")
        if (uuidTrx) {
            setUuidTrx(uuidTrx)
        }

        let dataOtorganteLS = localStorage.getItem("dataOtorgante")
        if (dataOtorganteLS != null) {
            setDataOtorgante(JSON.parse(dataOtorganteLS))
        }

        let email = "";
        let dataUserLS = localStorage.getItem("data_user");
        if (dataUserLS !== null) {
            let dataUser = JSON.parse(dataUserLS)
            setDataUser(JSON.parse(dataUserLS))
            email = obtenerValorUser(dataUser, "email") || "";
        }

        let dataFaceLS = localStorage.getItem("face")
        if (dataFaceLS != null) {
            setDataFace(JSON.parse(dataFaceLS))
        }

        let bandera = localStorage.getItem('flag').toUpperCase()
        setFlag(bandera);
        setCorreo(email)
        revisarFinalizar();
        status("consulta_score_page", "Validación de Score");
    }, [])

    useEffect(() => {
        configStyle(dataOtorgante)
    }, [dataOtorgante])

    useEffect(() => {
        localStorage.setItem("data_user", JSON.stringify(dataUser))
        // console.log("datauser");
    }, [dataUser])

    const status = () => {
        if (window.opener) {
            let uuidUser = localStorage.getItem("uuidUser");////uuid.split("-")[0]
            let event_t = "OK"
            if (localStorage.getItem("apikeyOtorgante") === "iQNTQ2qoBLfAy0iH4V2OUM5l36BIUoCt") {
                let res = convertdataJSON();
                event_t = JSON.stringify(res)
            }
            //console.log("entre a status finish", uuidUser);
            window.opener.postMessage(JSON.stringify({
                step: "completed",
                result: event_t,
                uuid: uuidUser,
            }), localStorage.getItem("externalReference"))
            setTimeout(() => {
                window.close();
            }, 1500);
        } else {
            setTimeout(() => {
                //console.log("cerrando...");
                if (localStorage.getItem("package") !== null) {
                    if (isIOS) {
                        window.location.href = "" + localStorage.getItem("package") + "://folio=" + localStorage.getItem("uuidUser");
                        window.close();
                    } else {
                        //var myWindow = window.open('/cerrar', '_self');
                        //myWindow.document.write("<p>Puede cerrar el navegador</p>");
                        window.location.href = "" + localStorage.getItem("package") + "://folio=" + localStorage.getItem("uuidUser");
                        window.close();
                    }
                }

            }, 5000);
        }
        updateStatus();
    }

    const updateStatus = async () => {
        let res = convertdataJSON();
        let event_t = JSON.stringify(res)
        var json_final = {};
        let statu = "OK"
        if (isFlag === "YELLOW") {
            statu = "FAIL"
        }
        json_final = {
            "status": statu,
            "step": "finished",
            "description": event_t,
            "finished": true
        }
        let event = JSON.stringify(json_final);
        await sendData({ 'step': 'finalizado', 'event': event });
        localStorage.setItem("completado", "true");
        statusFinal();
    }

    const statusFinal = () => {
        setFinished(true);
        if (window.opener) {
            let uuidTrx = localStorage.getItem("uuidTrx");
            let event_t = "OK"

            if (regresarJson()) {
                let res = convertdataJSON();
                event_t = (res !== "") ? JSON.stringify(res) : ""
            }
            window.opener.postMessage(JSON.stringify({
                step: "completed",
                result: event_t,
                transaccion: uuidTrx,
            }), localStorage.getItem("externalReference"))
            setTimeout(() => {
                window.close();
            }, 1000);
        } else {
            setTimeout(() => {
                // history.push('/' + apiKey)
            }, 300);
        }
    }

    const convertdataJSON = () => {
        var fecha = ""
        let date = new Date()
        let day = date.getDate()
        let month = date.getMonth() + 1
        let year = date.getFullYear()
        if (month < 10) {
            fecha = `${day}0${month}${year}`
        } else {
            fecha = `${day}${month}${year}`
        }
        var jsonObj = {};
        jsonObj.uuidUser = uuid;
        jsonObj.uuidTrx = uuidTrx;
        let partner = obtenerValorConfig(dataOtorgante, "PARTNER", "")
        partner = partner.replace(" ", "_");
        if (partner !== "") {
            partner = partner + "_"
        }
        let name_otorgante = obtenerValorConfig(dataOtorgante, "NOMBRE_CORTO", "")
        name_otorgante = name_otorgante.replace(" ", "_");
        let uuidC = partner + name_otorgante + "_" + fecha + "_" + uuidTrx.split("-")[0];
        jsonObj.custom_uuid = uuidC;
        jsonObj.ocrBack = JSON.parse(localStorage.getItem("ocrBack"));
        jsonObj.ocrFront = JSON.parse(localStorage.getItem("ocrFront"));
        jsonObj.data_user = JSON.parse(localStorage.getItem("data_user"));
        jsonObj.links = JSON.parse(localStorage.getItem('links')) || "";
        jsonObj.detailsScore = JSON.parse(localStorage.getItem('fields')) || [];
        return jsonObj;
    }

    const revisarFinalizar = async () => {
        let tipo = '';
        let informacion = {};
        let interval = setInterval(() => {
            revisarTurno().then(async (response) => {
                if (response.status === 200) {
                    informacion.status = response.status;
                    informacion.payload = response.data.payload;
                    evento('Veredicto', 'Success', informacion, true);
                    if (response.data.payload.veredicto) {
                        clearInterval(interval);
                        setVeredicto(response.data.payload.veredicto);
                        setLoading(false);
                    }
                }
            }).catch((error) => {
                if (error.response) {
                    let { data, status } = error.response
                    informacion.data = data;
                    informacion.status = status;
                    tipo = 'Error';
                } else {
                    informacion.error = error.toString();
                    tipo = 'Exception';
                }
                evento('Veredicto', tipo, informacion, false);
                //console.log("algun error");
            });
        }, 500);
    }

    const enviarCorreo = (e) => {
        let tipo = '';
        let informacion = {};
        let objeto = {};
        if (isFlag === "GREEN") {
            objeto.tipo_envio = 'COMPLETO';
        } else {
            objeto.tipo_envio = 'SEMAFORO';
        }
        objeto.semaforo = isFlag.toLocaleLowerCase();
        objeto.uuidUser = uuid;
        objeto.name = obtenerValorUser(dataUser, "nombreCompleto");
        objeto.email = obtenerValorUser(dataUser, "email");
        objeto.fNac = obtenerValorUser(dataUser, "nacimiento");
        objeto.curp = (hayExcepcion()) ? obtenerValorUser(dataUser, "dni") : obtenerValorUser(dataUser, "curp");
        objeto.vigencia = obtenerValorUser(dataUser, "vigencia");
        objeto.ocrFront = JSON.parse(localStorage.getItem("ocrFront")) || {};
        objeto.ocrBack = JSON.parse(localStorage.getItem("ocrBack")) || {};
        objeto.data_user = JSON.parse(localStorage.getItem("data_user")) || [];
        let fecha = ""
        let date = new Date()
        let day = date.getDate()
        let month = date.getMonth() + 1
        let year = date.getFullYear()
        if (month < 10) {
            fecha = `${day}0${month}${year}`
        } else {
            fecha = `${day}${month}${year}`
        }
        let partner = obtenerValorConfig(dataOtorgante, "PARTNER", "")
        partner = partner.replace(" ", "_");
        if (partner !== "") {
            partner = partner + "_"
        }
        let name_otorgante = obtenerValorConfig(dataOtorgante, "NOMBRE_CORTO", "")
        name_otorgante = name_otorgante.replace(" ", "_");
        objeto.custom_uuid = partner + name_otorgante + "_" + fecha + "_" + uuidTrx.split("-")[0];
        objeto.originOS = isIOS ? 'ios' : 'android';
        objeto.coincidencia = (dataFace.match ? "Coincide" : "Requiere validación");
        enviarCorreoAceptacion(objeto).then((response) => {
            if (response.status === 200) {
                //console.log(response);
                informacion.status = response.status;
                informacion.payload = response.data.payload;
                evento('Envío Correo', 'Success', informacion, true);
                statusData("email_check", { email: obtenerValorUser(dataUser, "email") })
                if (regresarJson()) {
                    dataLinks()
                } else {
                    setTimeout(() => {
                        updateStatus();
                    }, 500);
                }
            }
            else {
                statusF(response.data.payload);
            }
        }).catch((error) => {
            if (error.response) {
                let { data, status } = error.response
                informacion.data = data;
                informacion.status = status;
                tipo = 'Error';
            } else {
                informacion.error = error.toString();
                tipo = 'Exception';
            }
            evento('Envío Correo', tipo, informacion, false);
            //console.log("algun error");
            statusF(error);
            if (regresarJson()) {
                dataLinks()
            } else {
                setTimeout(() => {
                    updateStatus();
                }, 500);
            }
        });
    }

    const dataLinks = async () => {
        //console.log(event_t);
        let tipo = '';
        let informacion = {};
        getDocuments().then((response) => {
            informacion.status = response.status;
            informacion.payload = response.data.payload;
            evento('Obtener Documentos', 'Success', informacion, true);
            if (response.status === 200) {
                if (response.data.payload.links) {
                    let asd = JSON.stringify(response.data.payload.links)
                    localStorage.setItem('links', asd);
                }
                setTimeout(() => {
                    updateStatus();
                }, 500);
            }
        }).catch((error) => {
            if (error.response) {
                let { data, status } = error.response
                informacion.data = data;
                informacion.status = status;
                tipo = 'Error';
            } else {
                informacion.error = error.toString();
                tipo = 'Exception';
            }
            evento('Obtener Documentos', tipo, informacion, false);

        })
    }

    const statusF = (error) => {
        //console.log("entre a status ine");
        statusError("email_check", error, "error")
    }

    useEffect(() => {
        if (uuid && uuidTrx && isFlag && dataUser.length > 0 && dataOtorgante.length > 0 && dataFace) enviarCorreo();
    }, [uuid, uuidTrx, isFlag, dataUser, dataOtorgante, dataFace]);

    return (
        <div className="main_gradient">
            <Header ruta="finalizado" show={() => setShowHelp(true)} />
            {Veredicto &&
                <>
                    <div className="main_text_container">
                        {
                            (Veredicto !== "NO_APROBADO") ?
                                <h1 className="animated fadeIn delay-1s">¡Muchas gracias!</h1>
                                :
                                <h1 className="animated fadeIn delay-1s">Proceso no exitoso</h1>
                        }
                        {
                            (Veredicto === "APROBADO") ?
                                <div className="animated fadeIn delay-2s">
                                    <p>Has completado tu solitud exitosamente, hemos enviado el resultado del proceso a: </p>
                                    <div className="row_ext">
                                        <div className={["form-group", "is-focused"].join(" ")}>
                                            <label htmlFor="emailInput" className="bmd-label-floating">Correo electrónico:</label>
                                            {/* <input type="email" inputMode="email" name="correo" disabled="disabled" className="form-control" id="emailInput" defaultValue={correo} onChange={e => setCorreo(e.target.value)} onFocus={e => {
                                         setFocusCorreo(true)
                                     }} onBlur={e => {
                                         if (correo === "") {
                                             setFocusCorreo(false)
                                         }
                                     }} /> */}
                                            <p>{correo}</p>
                                        </div>
                                    </div>
                                </div>
                                :
                                (Veredicto === "ESCALAR") ?
                                    <div className="animated fadeIn delay-2s">
                                        <p>Tu solicitud esta siendo procesada por nuestro equipo de expertos, te notificaremos el resultado en las proximas horas a: </p>
                                        <br />
                                        <div className="form">
                                            <div className={["form-group", "is-focused"].join(" ")}>
                                                <label htmlFor="emailInput" className="bmd-label-floating">Correo electrónico:</label>
                                                {/* <input type="email" inputMode="email" name="correo" disabled="disabled" className="form-control" id="emailInput" defaultValue={correo} onChange={e => setCorreo(e.target.value)} onFocus={e => {
                                         setFocusCorreo(true)
                                     }} onBlur={e => {
                                         if (correo === "") {
                                             setFocusCorreo(false)
                                         }
                                     }} /> */}
                                                <p>{correo}</p>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <div className="animated fadeIn delay-2s">
                                        <p>Te sugerimos <b>verificar tu documentación</b> e intentarlo más adelante siguiendo <b>detenidamente</b> las <b>instrucciones</b>.</p>
                                        <p>Si desea mayor información no dudes en contactarnos con el <b>ID</b> de proceso <b>{uuidTrx.split("-")[0]}</b>.</p>
                                        <br />

                                    </div>
                        }
                    </div>
                    {(Veredicto !== "NO_APROBADO") ?
                        <div className="main_icon_container animate__animated animate__fadeIn animate__delay-2s">
                            <br /><br />
                            <a id="linkApp" href={"" + localStorage.getItem("package") + "://folio=" + localStorage.getItem("uuidUser")}>
                                {((Veredicto === "ESCALAR") ? <img src={process.env.PUBLIC_URL + 'images/check_warning.svg'} alt="" /> : <img src={(obtenerValorConfig(dataOtorgante, 'ICONO_ID_CHECK', "") != "") ? obtenerValorConfig(dataOtorgante, 'ICONO_ID_CHECK', process.env.PUBLIC_URL + '/images/check.svg') : process.env.PUBLIC_URL + '/images/check.svg'} alt="" />)}
                            </a>
                            <br /><br />
                            <p className="text-center" >Folio: <br />
                                <b>{uuidTrx.split("-")[0]}</b>
                            </p>
                            {
                                (localStorage.getItem("package") !== null) ?
                                    <div className="action_buttons">
                                        <a className="btn btn-raised btn-primary forcewidth100 main_bg_color animate__animated animate__delay-3s" href={"" + localStorage.getItem("package") + "://folio=" + localStorage.getItem("uuidUser")}>
                                            Volver al origen
                                        </a>
                                    </div>
                                    :
                                    (!window.opener) ?
                                        <div className="action_buttons">
                                            {/* <a className="btn btn-raised btn-primary forcewidth100 main_bg_color animate__animated animate__delay-3s" href={"https://" + window.location.host + "/" + localStorage.getItem("apikeyOtorgante")}>
                                            TERMINAR
                                        </a> */}
                                            <button type="button" onClick={(e) => {
                                                sendEventClick('Validación de Score', 'TERMINAR', { status: 'FINALIZADO' });
                                                setTimeout(() => {
                                                    history.push('/' + apiKey)
                                                }, 300);
                                            }} className="btn btn-raised btn-primary forcewidth100 main_bg_color" disabled={!finished}>TERMINAR</button>
                                        </div>
                                        :
                                        null
                            }
                        </div>
                        :
                        <div>
                            <div className="main_text_container animate__animated animate__fadeIn animate__delay-2s" style={{ padding: "0 10%" }}>
                                <div className="help_container">
                                    <p className="help_mail"><b>Escríbenos a:</b>
                                        <br />
                                        <br />
                                        {/* <img src={process.env.PUBLIC_URL + '/images/arroba.svg'} /><a href={"mailto:" + obtenerValorConfig(dataOtorgante, 'EMAIL_CONTACTO_PRINCIPAL')}>{obtenerValorConfig(dataOtorgante, "EMAIL_CONTACTO_PRINCIPAL")}</a> */}
                                        <span className="d-flex align-items-center">
                                            <i className="arroba"></i><a href={"mailto:" + obtenerValorConfig(dataOtorgante, 'EMAIL_CONTACTO_PRINCIPAL')}>{obtenerValorConfig(dataOtorgante, "EMAIL_CONTACTO_PRINCIPAL")}</a>
                                        </span>
                                        <br />
                                        <br />
                                    </p>
                                    <p className="help_phone"><b>Llámanos al:</b>
                                        <br />
                                        <br />
                                        {/* <img src={process.env.PUBLIC_URL + '/images/phone.svg'} /><a href={"tel:" + obtenerValorConfig(dataOtorgante, 'TEL_CONTACTO_PRINCIPAL')}>{obtenerValorConfig(dataOtorgante, 'TEL_CONTACTO_PRINCIPAL')}</a> */}
                                        <span className="d-flex align-items-center">
                                            <i className="phone"></i><a href={"tel:" + obtenerValorConfig(dataOtorgante, 'TEL_CONTACTO_PRINCIPAL')}>{obtenerValorConfig(dataOtorgante, 'TEL_CONTACTO_PRINCIPAL')}</a>
                                        </span>
                                        <br />
                                        <br />
                                    </p>
                                    <p className="help_whats"><b>Hablemos en:</b>
                                        <br />
                                        <br />
                                        {/* <img src={process.env.PUBLIC_URL + '/images/whatsapp.svg'} /><a rel="external" href={"https://wa.me/0525567068439?text=Tuve%20problemas%20en%20el%20proceso%2C%20mi%20ID%20de%20transacci%C3%B3n%20es%20%3A%20" + uuidTrx.split("-")[0]}>WhatsApp</a> */}
                                        <span className="d-flex align-items-center">
                                            <i className="whatsapp"></i><a rel="external" href={"https://wa.me/" + obtenerValorConfig(dataOtorgante, 'TEL_CONTACTO_PRINCIPAL') + "?text=Tuve%20problemas%20en%20el%20proceso%20mi%20ID%20de%20transacci%C3%B3n%20es%20%20" + uuid.split("-")[0]}>WhatsApp</a>
                                        </span>
                                        <br />
                                        <br />
                                    </p>
                                </div>
                            </div>
                            {
                                (localStorage.getItem("package") !== null) ?
                                    <div className="action_buttons">
                                        <a className="btn btn-raised btn-primary forcewidth100 main_bg_color animate__animated animate__delay-3s" href={"" + localStorage.getItem("package") + "://folio=" + localStorage.getItem("uuidUser")}>
                                            Volver al origen
                                        </a>
                                    </div>
                                    :
                                    (!window.opener) ?
                                        <div className="action_buttons">
                                            {/* <a className="btn btn-raised btn-primary forcewidth100 main_bg_color animate__animated animate__delay-3s" href={"https://" + window.location.host + "/" + localStorage.getItem("apikeyOtorgante")}>
                                            TERMINAR
                                        </a> */}
                                            <button type="button" onClick={(e) => {
                                                sendEventClick('Validación de Score', 'TERMINAR', { status: 'FINALIZADO' });
                                                setTimeout(() => {
                                                    history.push('/' + apiKey)
                                                }, 300);
                                            }} className="btn btn-raised btn-primary forcewidth100 main_bg_color" disabled={!finished}>TERMINAR</button>
                                        </div>
                                        :
                                        null
                            }
                        </div>
                    }

                </>}
            {(loading) ? <Loader /> : ("")}
            {showHelp ? <Ayuda hide={() => setShowHelp(false)} /> : ""}
            {/* <Footer /> */}
        </div>
    )

}

export default Finalizado