import React, { useState, useEffect, Fragment } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import Header from "../components/headerNA";
import Footer from "../components/footer";
import { configStyle, obtenerValorUser, obtenerValorConfig } from '../services/configStyle'
import { obtenerPais } from '../services/api.js';
import { isAndroid } from 'react-device-detect';
import moment from 'moment';
import NumberFormat from 'react-number-format';
import { hayExcepcion, agregarInfo, status, statusData, esNumLetras, esNumeros, esSoloLetras } from '../services/data';
import Ayuda from './ayuda';
import FormularioIneSegundaParte from './forms/formulario_ine_segunda_parte'


let ResultadosIdentificacion = (props) => {

    const [dataOtorgante, setDataOtorgante] = useState([])
    const [nombre, setNombre] = useState('')
    const [curp, setCurp] = useState('')
    const [vigencia, setVigencia] = useState('')
    const [esPassport, setEsPassport] = useState(false)
    const [esVigente, setEsVigente] = useState(true)
    const [datosCorrectos, setdatosCorrectos] = useState(true)
    const [noOCR, setNoOCR] = useState(false)
    const [apiKey, setApiKey] = useState('')
    const [esMigratoria, setEsMigratoria] = useState(false)
    const [showHelp, setShowHelp] = useState(false);
    const [esIne, setEsIne] = useState(false);
    const [esIdArgentino, setIdArgentino] = useState(false);

    useEffect(() => {

        let no_OCR = localStorage.getItem("intentsData")
        if (no_OCR !== null) {
            if (no_OCR === "OCR")
                setNoOCR(true)
        }

        if (hayExcepcion()) {
            setNoOCR(true)
        }

        let apikeyOtorgante = localStorage.getItem("apikeyOtorgante")
        if (apikeyOtorgante !== null) {
            setApiKey(apikeyOtorgante)
        }

        let dataOtorganteLS = localStorage.getItem("dataOtorgante")
        if (dataOtorganteLS != null) {
            setDataOtorgante(JSON.parse(dataOtorganteLS))
        }
        let dataOCRFrontLS = localStorage.getItem("ocrFront")
        const ocrFront = JSON.parse(dataOCRFrontLS)
        if (dataOCRFrontLS != null) {
            if (ocrFront.identificacionElectoral)
                setEsIne(true);
            if (ocrFront.identificacionPasaporte)
                setEsPassport(true)
            if (ocrFront.identificacionMigratoria)
                setEsMigratoria(true)
        } else {
            setIdArgentino(true);
        }
        status("datos_personales_ocr_page", "Datos personales OCR");
    }, [])

    useEffect(() => {
        configStyle(dataOtorgante)
    }, [dataOtorgante]);

    useEffect(() => {
        if (noOCR) {
            setdatosCorrectos(true)
        } else {
            if (curp && nombre) {
                if (hayExcepcion()) {
                    if (curp.length < 3 || nombre.length < 10) {
                        setdatosCorrectos(false)
                    } else {
                        setdatosCorrectos(true)
                    }
                } else {
                    if (curp.length < 16 || curp.length > 18 || nombre.length < 10) {
                        setdatosCorrectos(false)
                    } else {
                        setdatosCorrectos(true)
                    }
                }
            } else {
                setdatosCorrectos(false)
            }
        }
        //console.log("DC:",datosCorrectos);
    }, [curp, nombre])


    return (
        <Fragment>
            <div className="main_gradient">
                <Header ruta="resultados_identificacion" show={() => setShowHelp(true)} />
                <div className="main_text_container">
                    <h1>Verifica tu información</h1>
                    <p className="animate__animated">Revisa que tus datos coincidan con los de tu identificación.</p>
                </div>
                {(obtenerValorConfig(dataOtorgante, 'ENABLE_INE', "true") === "true" && esIne) ?
                    <FormularioIneSegundaParte />
                    : (esIne) 
                        // <FormularioIne />
                        // : hayExcepcion() ?
                        //     <FormularioDni />
                            // : (esPassport || esMigratoria) ?
                            //     <FormularioPasaporte />
                            //     : <FormularioIne />
                }
                {/* <Footer /> */}
            </div>
            {showHelp ? <Ayuda hide={() => setShowHelp(false)} /> : ""}
        </Fragment>
    )
}

export default ResultadosIdentificacion