import React, { useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import Header from "../components/headerNA";
import Footer from "../components/footer";
import { obtenerValorUser } from '../services/configStyle'
import { status, statusData, statusError, evento, sendEventClick, generateZip, generarTurno, encolarPeticionRenapo, encolarPeticionINE } from '../services/data';
import { isIOS, isSafari } from 'react-device-detect';
import Ayuda from './ayuda';
import Loader from "../components/loader";
import { mapearError, mapearRespuesta } from '../components/captura_identificacion/utilities/response/map_response';

const LLamada_autorizacion = () => {
    const history = useHistory();
    const [showCancel, setShowCancel] = useState(false)
    const [nombre, setNombre] = useState('')
    const [focusNombre, setFocusNombre] = useState(false)
    const [aut, setAut] = useState(false)
    const [dataUser, setDataUser] = React.useState([]);
    const [capturing, setCapturing] = useState(true);
    const [showHelp, setShowHelp] = useState(false);
    const [apiKey, setApiKey] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        let dataUserLS = localStorage.getItem("data_user");
        if (dataUserLS != null) {
            let dataU = JSON.parse(dataUserLS)
            let nombre = obtenerValorUser(dataU, "nombres") || "";
            let apellidoPaterno = obtenerValorUser(dataU, "apellidoPaterno") || "";
            let apellidoMaterno = obtenerValorUser(dataU, "apellidoMaterno") || "";
            setNombre(`${nombre} ${apellidoPaterno} ${apellidoMaterno}`)
            setDataUser(JSON.parse(dataUserLS))
        }

        let apikeyOtorgante = localStorage.getItem("apikeyOtorgante")
        if (apikeyOtorgante !== null) {
            setApiKey(apikeyOtorgante)
        }

        pedirTurno();

        status("autorizacion_llamada", "Autorización de llamada");
    }, [])

    useEffect(() => {
        localStorage.setItem("data_user", JSON.stringify(dataUser))
    }, [dataUser])

    const statusE = async () => {
        setLoading(true);
        sendEventClick('Autorización de llamada', 'SI', { status: 'CANCELADO' });
        let data = "Cancelado";
        localStorage.setItem('flag', 'cancelado');
        await generateZip('TRUNCOS', 'trunco', isIOS);
        statusError("autorizacion_llamada", data, "cancelado");
        setTimeout(() => {
            history.push("/" + (apiKey));
            setLoading(false);
        }, 300)
    }

    const actualizarDatos = (e) => {
        e.preventDefault();
        if (aut && nombre) {
            evento('Autorización de llamada', 'Click', { description: 'Iniciar llamada ahora', status: 'OK' }, true);
            setDataUser([...dataUser, { description: "call_acepted", value: aut }]);
            let data = { autorizacion_grabacion_aceptada: true }
            statusData("autorizacion_llamada", "Autorización de llamada", data)
            setTimeout(() => {
                history.push('/validacion_final');
            }, 300);
        }
    }

    const pedirTurno = async () => {
        try {
            const response = await generarTurno();
            const { data, status } = response;
            if (status === 200) {
                evento('Obtener Turno', 'Success', mapearRespuesta(status, data), true);
                const idCall = data.payload.id;
                const validacionRenapoTurno = data.payload.renapo;
                const validacionIneTurno = data.payload.ine;
                const idCallLocalStorage = localStorage.getItem('id_call');
                if (!idCallLocalStorage) {
                    localStorage.setItem("id_call", idCall);
                }
                //console.log('RENAPO',validacionRenapoTurno);
                if (validacionRenapoTurno === null) {
                    encolarPeticionRenapo(idCall);
                }
                //console.log('INE',validacionIneTurno);
                if (validacionIneTurno === null) {
                    encolarPeticionINE(idCall);
                }
            }
        } catch (error) {
            let errorMapeado = mapearError(error);
            evento('Obtener Turno', errorMapeado.tipoError, errorMapeado.objetoError, false);
        }
    }

    return (
        <div className="main_gradient">
            <Header ruta="autorizacion_llamada" show={() => setShowHelp(true)} />
            <div className="main_text_container">
                <h1 className="animated fadeIn delay-1s">Validación personal</h1>
                <p className="animated fadeIn delay-2s"><b>Vamos a enlazarte</b> con un asesor en una <b>videollamada</b> para finalizar tu trámite.</p>
            </div>

            <div className="ocr_data_display validacion_personal animate__animated animate__fadeIn animate__delay-2s">
                <form>
                    <div className={["form-group", "bmd-form-group", ((focusNombre || nombre !== "") ? "is-focused" : "")].join(" ")}>
                        <label htmlFor="inputNombreCompleto" className="bmd-label-floating">Nombre completo:</label>
                        <input type="text" className="form-control" id="inputNombreCompleto" value={nombre} onChange={e => setNombre(e.target.value)} onFocus={e => {
                            setFocusNombre(true)
                        }} onBlur={e => {
                            if (nombre === "") {
                                setFocusNombre(false)
                            }
                        }} />
                        <span id="ht-nombre" className="helper-text ht" data-error="wrong" data-success="right"></span>
                    </div>
                </form>
            </div>

            <div className="check_container validacion_personal animate__animated animate__fadeIn animate__delay-2s">
                <form>
                    {(isIOS || isSafari) ?
                        <div>
                            <label htmlFor="check_terms" className="custom-checkbox">
                                <input id="check_terms" type="checkbox" onChange={e => { setAut(e.target.checked); setCapturing(!capturing); evento('Autorizacion de llamada', 'Click', { description: `Autorizo la grabación de mi voz e imagen.` }, true); }} />
                                <span className="checkbox-decorator"><span className="check"></span></span>
                                Autorizo la grabación de mi voz e imagen.
                            </label>
                        </div>
                        :
                        <div className="checkbox">
                            <label htmlFor="check_terms">
                                <input id="check_terms" type="checkbox" onChange={e => { setAut(e.target.checked); setCapturing(!capturing); evento('Autorizacion de llamada', 'Click', { description: `Autorizo la grabación de mi voz e imagen.` }, true); }} />
                                <span className="checkbox-decorator"><span className="check"></span></span>
                                Autorizo la grabación de mi voz e imagen.
                            </label>
                        </div>}
                </form>
            </div>
            <div className="action_buttons validacion_personal bottom animate__animated animate__fadeIn animate__delay-2s">
                <button type="button" disabled={!nombre || !aut} className={["btn btn-raised btn-primary forcewidth100", (capturing ? "gray_bg_color" : "main_bg_color")].join(" ")} onClick={e => actualizarDatos(e)}>
                    INICIAR LLAMADA AHORA</button>
                <button onClick={e => {
                    setShowCancel(true);
                    sendEventClick('Autorización de llamada', 'CANCELAR', {});
                }} className="btn btn-primary forcewidth100 main_color">
                    CANCELAR</button>
            </div>
            <br />
            {/* <Footer /> */}
            {(showCancel) ?
                <div className="modal fade show" style={{ display: "block" }} role="dialog">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Cancelar proceso</h5>
                                <button onClick={e => setShowCancel(false)} className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <p>En este momento se cancelará el proceso, ningún dato será guardado y perderás el avance, esta acción no podrá deshacerse <br /> ¿Deseas cancelar?</p>
                            </div>
                            <div className="modal-footer">
                                <button onClick={e => { setShowCancel(false); sendEventClick('Autorizacion de llamada', 'NO', {}); }} className="btn btn-secondary" data-dismiss="modal">NO</button>
                                <button type="button" onClick={e => statusE()} className="btn btn-raised btn-primary main_bg_color">SI</button>
                            </div>
                        </div>
                    </div>
                </div> : ("")}
            {showHelp ? <Ayuda hide={() => setShowHelp(false)} /> : ""}
            {loading && <Loader />}
        </div>
    )
}

export default LLamada_autorizacion