import React, { useState, useEffect, Fragment } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import Header from "../components/headerNA";
import Footer from "../components/footer";
import { configStyle, obtenerValorConfig } from '../services/configStyle'
import { actualizarEstado } from '../services/api.js';
import { status, statusError, sendEventClick, generateZip } from '../services/data';
import Ayuda from './ayuda';
import Loader from '../components/loader';
import { isIOS } from 'react-device-detect';

const Clausula = () => {
    const history = useHistory();
    const [dataOtorgante, setDataOtorgante] = useState([])
    const [showCancel, setShowCancel] = useState(false)
    const [apiKey, setApiKey] = useState('')
    const { ruta } = useParams();
    const [showHelp, setShowHelp] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        let apikeyOtorgante = localStorage.getItem("apikeyOtorgante")
        if (apikeyOtorgante !== null) {
            setApiKey(apikeyOtorgante)
        }
        let dataOtorganteLS = localStorage.getItem("dataOtorgante")
        if (dataOtorganteLS != null) {
            setDataOtorgante(JSON.parse(dataOtorganteLS))
        }
        status("clausulas", "Cláusula")
    }, [])

    useEffect(() => {
        configStyle(dataOtorgante)
    }, [dataOtorgante])


    const statusE = async () => {
        setLoading(true);
        sendEventClick('Cláusula', 'SI', { status: 'CANCELADO' });
        let data = "Cancelado";
        localStorage.setItem('flag', 'cancelado');
        await generateZip('TRUNCOS', 'trunco', isIOS);
        statusError("Cláusula", data, "cancelado")
        setTimeout(() => {
            window.close()
            history.push("/" + (ruta !== "pdf_video_token" ? apiKey : `pdf_token?uuid=${localStorage.getItem("uuidUser")}&idOtorgante=${localStorage.getItem("idOtorgante")}&fecha=${localStorage.getItem("fechavigencia")}`));
            setLoading(false);
        }, 300);
    }

    return (
        <Fragment>
            {(showCancel) ?
                <div className="modal fade show" style={{ display: "block" }} role="dialog">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Cancelar proceso</h5>
                                <button onClick={() => setShowCancel(false)} className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <p>En este momento se cancelará el proceso, ningún dato será guardado y perderás el avance, esta acción no podrá deshacerse <br /> ¿Deseas cancelar?</p>
                            </div>
                            <div className="modal-footer">
                                <button onClick={() => { setShowCancel(false); sendEventClick('Cláusula', 'NO'); }} className="btn btn-secondary" data-dismiss="modal">NO</button>
                                <button type="button" onClick={() => { statusE(); sendEventClick('Cláusula', 'SI'); }} className="btn btn-raised btn-primary main_bg_color">SI</button>
                            </div>
                        </div>
                    </div>
                </div> : ("")}
            <div className="main_gradient">
                <Header ruta="clausula" show={() => setShowHelp(true)} />
                <div className="main_text_container">
                    <h1 className="animate__animated">{(obtenerValorConfig(dataOtorgante, "TITULO_DEL_CONSENTIMIENTO_2", "FALSO") === "FALSO") ? ("") : obtenerValorConfig(dataOtorgante, "TITULO_DEL_CONSENTIMIENTO_2")}</h1>
                    <p className="animate__animated">Lee con atención el documento y aceptaló <b>si estás de acuerdo</b> con los estipulado,</p>
                </div>
                <div className="main_icon_container animate__animated">
                    <img src="images/bnw/doc_check.svg" alt="" />
                </div>
                <div className="action_buttons noscroll_screen">
                    <Link to="/validacion_documento" className="btn btn-raised btn-primary forcewidth100 main_bg_color" onClick={() => sendEventClick('Cláusula', 'LEER DOCUMENTO')}>LEER DOCUMENTO</Link>
                    <button onClick={() => { setShowCancel(true); sendEventClick('Cláusula', 'CANCELAR'); }} className="btn btn-primary forcewidth100 main_color">
                        CANCELAR</button>
                </div>
                {/* <Footer /> */}
            </div>
            <div className={(showCancel ? "modal-backdrop show" : "")}></div>
            {showHelp ? <Ayuda hide={() => setShowHelp(false)} /> : ""}
            {loading && <Loader />}
        </Fragment>
    )

}

export default Clausula