import React, { useState, useEffect } from "react";
import { useParams, useHistory, useLocation } from "react-router-dom";
import { configStyle } from '../services/configStyle';
import { sendEventClick, sendData, generateZip } from "../services/data";
import Loader from '../components/loader';
import {isIOS} from 'react-device-detect';

const CancelarFlujo = () => {

    const location = useLocation();
    const [dataOtorgante, setDataOtorgante] = useState([]);
    const [apiKey, setApiKey] = useState('');
    const history = useHistory();
    const { ruta } = useParams();
    const [loading, setLoading] = useState(false);


    useEffect(() => {
        let apikeyOtorgante = localStorage.getItem("apikeyOtorgante")
        if (apikeyOtorgante !== null) {
            setApiKey(apikeyOtorgante)
        }
        let dataOtorganteLS = localStorage.getItem("dataOtorgante")
        if (dataOtorganteLS != null) {
            setDataOtorgante(JSON.parse(dataOtorganteLS))
        }


    }, [])

    useEffect(() => {
        configStyle(dataOtorgante)
    }, [dataOtorgante])


    const continuarFlujo = () => {
        sendEventClick('Captura Video Token', 'NO');
        if (location.state) {
            if (location.state.passport) {
                history.push({
                    pathname: '/' + ruta,
                    state: { passport: true }
                })
            } else {
                history.push('/' + ruta)
            }
        } else {
            history.push('/' + ruta)
        }
    }

    const salirFlujo = () => {
        localStorage.setItem('flag', 'cancelado');
        sendEventClick('Captura Video Token', 'SI', { status: 'CANCELADO' });
        statusE();
    }

    const statusE = async () => {
        setLoading(true);
        await generateZip('TRUNCOS', 'trunco', isIOS);
        let json_final = {};
        json_final = {
            "status": 'INCOMPLETED',
            "step": "Captura Video Token",
            "description": 'Cancelado',
            'finished': true
        }
        let event = JSON.stringify(json_final);
        sendData({ 'step': 'captura_video_token', 'event': event });
        if (window.opener) {
            window.opener.postMessage(JSON.stringify({
                step: 'captura_video_token',
                status: 'INCOMPLETED',
                type: "PROCESS",
                error_text: 'Cancelado',
            }), localStorage.getItem("externalReference"))
        }
        setTimeout(() => {
            history.push("/" + (ruta !== "pdf_video_token" ? apiKey : `pdf_token?uuid=${localStorage.getItem("uuidUser")}&idOtorgante=${localStorage.getItem("idOtorgante")}&fecha=${localStorage.getItem("fechavigencia")}`));
            setLoading(false);
        }, 300);
    }

    return (
        <>
            <div className="container">
                <div className="row" style={{ height: '100vh' }}>
                    <div className="col-2"></div>
                    <div className="col">
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <div className="card text-right">
                            <div className="card-body">
                                <h5 className="card-title">Cancelar proceso</h5>
                                <p className="card-text">En este momento se cancelará el proceso, ningún dato será guardado y perderás el avance, esta acción no podrá deshacerse <br /> ¿Deseas cancelar?</p>
                                <button type="button" className="btn btn-secondary" onClick={continuarFlujo}>NO</button>
                                <button type="button" className="btn btn-raised btn-primary main_bg_color" onClick={salirFlujo}>SI</button>
                            </div>
                        </div>
                    </div>
                    <div className="col-2"></div>
                </div>
            </div>
            {loading && <Loader />}
        </>

    )

}

export default CancelarFlujo