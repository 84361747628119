import React, { useState, useEffect, Fragment, useRef } from "react";
import { useHistory } from "react-router-dom";
import Header from "../components/headerNA";
import { obtenerValorConfig, obtenerValorUser } from '../services/configStyle'
import { configStyle } from '../services/configStyle'
import Footer from "../components/footer";
import { obtenerMaps, actualizarScore, obtenerCP } from '../services/api';
import { status, statusData, agregarInfo, evento, validarCampo, validarValoresFormulario, sendEventClick } from '../services/data';
import Ayuda from './ayuda';

let IdentificacionEdit = (props) => {
    const history = useHistory();
    const [dataUser, setDataUser] = React.useState([]);
    const [dataOtorgante, setDataOtorgante] = useState([]);
    const [codigoPostal, setCodigoPostal] = useState("");
    const [focusCodigoPostal, setFocusCodigoPostal] = useState(false);
    const [focusPais, setFocusPais] = useState(false);
    const [focusEstado, setFocusEstado] = useState(false);
    const [focusDelegacion, setFocusDelegacion] = useState(false);
    const [focusColonia, setFocusColonia] = useState(false);
    const [focusCalle, setFocusCalle] = useState(false);
    const [focusNumeroExterior, setFocusNumeroExterior] = useState(false)
    const [Gps, setGps] = useState('[19.4323007,-99.2708557]');
    const [inputActive, setInputActive] = useState(false)
    const [databack, setDataback] = useState(false)
    const [cambioCP, setCambioCP] = useState(false)
    const [otro, setOtro] = useState(false)
    const [optionsSelect, setoptionsSelect] = useState([]);
    const [errorUbicacion, setErrorUbicacion] = useState(false);
    const [showHelp, setShowHelp] = useState(false);
    const [focusNumeroInterior, setFocusNumeroInterior] = useState(false);
    const [inputsValue, setInputsValue] = useState({
        codigoPostal: '',
        pais: '',
        estado: '',
        delegacion: '',
        colonia: '',
        calle: '',
        numeroExterior: '',
        numeroInterior: '',
    });
    const [inputsErrors, setInputsErrors] = useState({});
    const [continuar, setContinuar] = useState(false);

    useEffect(() => {
        let dataOtorganteLS = "", dataUserLS = "", dataOCRFrontLS = "";
        dataOtorganteLS = localStorage.getItem("dataOtorgante")
        if (dataOtorganteLS != null) {
            setDataOtorgante(JSON.parse(dataOtorganteLS))
        }
        dataUserLS = localStorage.getItem("data_user")
        if (dataUserLS != null) {
            setDataUser(JSON.parse(dataUserLS))
        }
        dataOCRFrontLS = localStorage.getItem("ocrFront")
        if (dataOCRFrontLS != null) {
            const ocrFront = JSON.parse(dataOCRFrontLS)
            let codPos = (ocrFront.cp) ? ocrFront.cp.trim() : "";
            setInputsValue(inputsValue => ({ ...inputsValue, ['codigoPostal']: codPos || '' }));
            setInputsValue(inputsValue => ({ ...inputsValue, ['pais']: 'México' }));
            setInputsValue(inputsValue => ({ ...inputsValue, ['calle']: ocrFront.calle || '' }));
            let numExt = (ocrFront.numero) ? ocrFront.numero.trim() : "";
            setInputsValue(inputsValue => ({ ...inputsValue, ['numeroExterior']: numExt || '' }));
            setInputsValue(inputsValue => ({ ...inputsValue, ['estado']: ocrFront.estado || '' }));
            setInputsValue(inputsValue => ({ ...inputsValue, ['delegacion']: ocrFront.municipio || '' }));
            setInputsValue(inputsValue => ({ ...inputsValue, ['colonia']: ocrFront.colonia || '' }));
            setDataback(true);
        }
        status("datos_direccion_ocr_page", "Datos de direccion OCR");
    }, [])

    useEffect(() => {
        configStyle(dataOtorgante)
    }, [dataOtorgante])

    useEffect(() => {
        let tipo = '';
        let informacion = {};
        if (inputsValue.codigoPostal && inputsValue.codigoPostal.length === 5 && !databack && cambioCP) {
            setCambioCP(false)
            obtenerCP(codigoPostal).then((response) => {
                //console.log("data:", response);
                if (response.status === 200) {
                    informacion.status = response.status;
                    informacion.payload = response.data.payload;
                    evento('Obtener Código Postal', 'Success', informacion, true);
                    // setCodigoPostal(codigoPostal);
                    setInputsValue(inputsValue => ({ ...inputsValue, ['codigoPostal']: inputsValue.codigoPostal }));
                    var dir = response.data.payload.address[0];
                    var delegacionW = dir.municipio
                    var estadoW = dir.estado
                    var coloniaW = dir.asentamiento
                    let arreglo = [];
                    let tam = (response.data.payload.address).length
                    if (tam > 1) {
                        for (let i = 0; i < tam; i++) {
                            let colonia = response.data.payload.address[i].asentamiento
                            arreglo.push(colonia);
                        }
                    } else {
                        let colonia1 = dir.asentamiento
                        arreglo.push(colonia1);
                    }
                    setoptionsSelect(arreglo)
                    setInputsValue(inputsValue => ({ ...inputsValue, ['colonia']: coloniaW }));
                    setInputsValue(inputsValue => ({ ...inputsValue, ['delegacion']: delegacionW }));
                    setInputsValue(inputsValue => ({ ...inputsValue, ['estado']: estadoW }));
                    setInputsValue(inputsValue => ({ ...inputsValue, ['pais']: 'México' }));
                }
                //setLoading(false)
            }).catch((error) => {
                //console.log("algun error");
                if (error.response) {
                    let { data: { message }, status } = error.response
                    informacion.mensaje = message;
                    informacion.status = status;
                    tipo = 'Error';
                } else {
                    informacion.error = error.toString();
                    tipo = 'Exception';
                }
                evento('Obtener Código Postal', tipo, informacion, false);
                if (inputActive) {
                    //console.log("inputActive algun error",inputActive);
                }
                //setLoading(false)  identificacsion_edit
            })
            setTimeout(() => {
                setInputsValue(inputsValue => ({ ...inputsValue, ['codigoPostal']: inputsValue.codigoPostal }));
            }, 1000);
        }
    }, [inputsValue.codigoPostal])

    useEffect(() => {
        localStorage.setItem("data_user", JSON.stringify(dataUser))
    }, [dataUser])

    const actualizarDatos = (event) => {
        // if (Gps !== '[19.4323007,-99.2708557]' && calle.trim()) {
        if (event) event.persist(); event.preventDefault(); event.stopPropagation();
        let tipo = '';
        let informacion = {};
        if (continuar && Gps !== '[19.4323007,-99.2708557]') {
            evento('Datos de direccion OCR', 'Click', { description: 'GUARDAR', status: 'OK' }, true);
            agregarInfo(dataUser, { description: "codigoPostal", value: inputsValue.codigoPostal });
            agregarInfo(dataUser, { description: "pais", value: inputsValue.pais });
            agregarInfo(dataUser, { description: "estado", value: inputsValue.estado });
            agregarInfo(dataUser, { description: "delegacion", value: inputsValue.delegacion });
            agregarInfo(dataUser, { description: "calle", value: inputsValue.calle });
            agregarInfo(dataUser, { description: "numeroExterior", value: inputsValue.numeroExterior });
            agregarInfo(dataUser, { description: "calleCompleta", value: inputsValue.calle + " " + inputsValue.numeroExterior });
            agregarInfo(dataUser, { description: "gps", value: Gps });
            let infoFormulario = []
            infoFormulario.push({ description: "codigoPostal", value: inputsValue.codigoPostal })
            infoFormulario.push({ description: "pais", value: inputsValue.pais })
            infoFormulario.push({ description: "estado", value: inputsValue.estado })
            infoFormulario.push({ description: "delegacion", value: inputsValue.delegacion })
            if (inputsValue.otraColonia) {
                infoFormulario.push({ description: "colonia", value: inputsValue.otraColonia });
                agregarInfo(dataUser, { description: "colonia", value: inputsValue.otraColonia });
            } else {
                infoFormulario.push({ description: "colonia", value: inputsValue.colonia });
                agregarInfo(dataUser, { description: "colonia", value: inputsValue.colonia });
            }
            infoFormulario.push({ description: "calle", value: inputsValue.calle });
            infoFormulario.push({ description: "numeroExterior", value: inputsValue.numeroExterior });
            infoFormulario.push({ description: "calleCompleta", value: inputsValue.calle + " " + inputsValue.numeroExterior });
            if (inputsValue.numeroInterior) {
                agregarInfo(dataUser, { description: "numeroInterior", value: inputsValue.numeroInterior });
                infoFormulario.push({ description: "numeroInterior", value: inputsValue.numeroInterior });
            }
            infoFormulario.push({ description: "gps", value: Gps })
            document.getElementById("form-ocr").reset()
            //actualizarScore
            let uuid = localStorage.getItem("uuidUser");
            let empresa = (obtenerValorConfig(dataOtorgante, 'NOMBRE_CORTO', ""));
            let coloniaParam = inputsValue.otraColonia ? inputsValue.otraColonia : inputsValue.colonia;

            actualizarScore(uuid, inputsValue.calle, coloniaParam, inputsValue.codigoPostal, inputsValue.estado, inputsValue.delegacion, inputsValue.numeroExterior, empresa).then((response) => {
                if (response.status === 200) {
                    //console.log(response.data);
                    informacion.status = response.status;
                    informacion.payload = response.data.payload;
                    evento('Actualizar Score', 'Success', informacion, true);
                    sendData(infoFormulario)
                    setTimeout(() => {
                        // history.push('/preparacion_comprobante');
                        history.push('/preparacion_huellas');
                    }, 200);
                }
            }).catch((error) => {
                //console.log("algun error actualizarScore");
                if (error.response) {
                    let { data: { message }, status } = error.response
                    informacion.mensaje = message;
                    informacion.status = status;
                    tipo = 'Error';
                } else {
                    informacion.error = error.toString();
                    tipo = 'Exception';
                }
                evento('Actualizar Score', tipo, informacion, false);
                sendData(infoFormulario)
                setTimeout(() => {
                    // history.push('/preparacion_comprobante');
                    history.push('/preparacion_huellas');
                }, 200);
            })
        } else {
            if (Gps === '[19.4323007,-99.2708557]') {
                setErrorUbicacion(true);
            }
            evento('Datos de direccion OCR', 'Click', { description: 'GUARDAR', status: 'Error' }, true);
            // if (!calle.trim()) {
            //     let frase = "Debes introducir algún valor";
            //     document.getElementById("ht-calle").innerHTML = frase;
            //     document.getElementById("divCalle").classList.add("input-error");
            //     document.getElementById("inputCalle").classList.add("input-error");
            // } else {
            //     document.getElementById("ht-calle").innerHTML = "";
            //     document.getElementById("inputCalle").classList.remove("input-error");
            // }

        }
    }

    const verificarCP = (value) => {
        let name = 'codigoPostal';
        let validacion = validarCampo(value, name);
        if (validacion.correcto) {
            setInputsValue(inputsValue => ({ ...inputsValue, [name]: value }));
            setInputsErrors(inputsErrors => ({ ...inputsErrors, [name]: '' }));
            setCodigoPostal(value)
            setDataback(false)
            setCambioCP(true)
        } else {
            setInputsValue(inputsValue => ({ ...inputsValue, [name]: value }));
            setInputsErrors(inputsErrors => ({ ...inputsErrors, ...validacion.errores }));
        }
    }

    const maxLengthCheck = (object) => {
        if (object.target.value.length > object.target.maxLength) {
            object.target.value = object.target.value.slice(0, object.target.maxLength)
        }
    }

    const revisarColonia = (value) => {
        let name = 'colonia'
        let validacion = validarCampo(value, name);
        if (validacion.correcto) {
            setInputsValue(inputsValue => ({ ...inputsValue, [name]: value }));
            setInputsErrors(inputsErrors => ({ ...inputsErrors, [name]: '' }));
            if (value === "Otro") {
                setOtro(true);
                setInputsValue(inputsValue => ({ ...inputsValue, ['otraColonia']: '' }));
            } else {
                setOtro(false);
                delete inputsValue.otraColonia;
                delete inputsErrors.otraColonia;
            }
        } else {
            setInputsValue(inputsValue => ({ ...inputsValue, [name]: value }));
            setInputsErrors(inputsErrors => ({ ...inputsErrors, ...validacion.errores }));
        }
    }

    const sendData = (infoFormulario) => {
        var jsonObj = {};
        jsonObj.infoDireccion = infoFormulario;
        statusData("datos_direccion_ocr", jsonObj)
    }

    const geoLocalizacion = (event) => {
        if(event) event.persist();
        let output = document.getElementById("out");

        if (!navigator.geolocation) {
            output.innerHTML = "<p>Su dispositivo no soporta la geolocalización</p>";
            return;
        }

        if(event.target.checked){
            function success(position) {
                const { latitude, longitude } = position.coords;
                output.innerHTML = '';
                setGps("[" + latitude + "," + longitude + "]");
                setErrorUbicacion(false);
                let objetoGPS = { description: "gps_aceptado", value: true, gps: "[" + latitude + "," + longitude + "]" }
                evento('Obtener Geolocalización', 'Step', objetoGPS, true);
                statusData("obtener_gps", { description: "gps_aceptado", value: true, gps: "[" + latitude + "," + longitude + "]" });
                
            };
    
            function error(error) {
                const { code, message } = error;
                let objetoError = {
                    message: message
                }
                switch (code) {
                    case 1:
                        // Permiso denegado.
                        objetoError.error = 'PERMISSION_DENIED';
                        output.innerHTML = 'El permiso de localización fue denegado o se encuentra bloqueado en tu sistema. Por favor, permite el acceso e intenta nuevamente.';
                        evento('Obtener Geolocalización', 'Step', objetoError, false);
                        break;
                    case 2:
                        // Posición no disponible.
                        objetoError.error = 'POSITION_UNAVAILABLE';
                        output.innerHTML = 'No pudimos obtener información de localización, podría ser un error de red o de hardware, por favor verifica tu conexión o dispositivo e intenta nuevamente.';
                        evento('Obtener Geolocalización', 'Step', objetoError, false);
                        break;
                    case 3:
                        // Se acabó el tiempo de espera.
                        objetoError.error = 'TIMEOUT';
                        output.innerHTML = 'Se superó el tiempo de espera para obtener información de localización, por favor verifica tu conexión, permisos o dispositivo e intenta nuevamente.';
                        evento('Obtener Geolocalización', 'Step', objetoError, false);
                        break;
                    default:
                        output.innerHTML = 'No hemos podido obtener tu ubicación, por favor intenta nuevamente.';
                        evento('Obtener Geolocalización', 'Step', error, false);
                        break;
                }
            };
            output.innerHTML = "<p>Localizando…</p>";
            navigator.geolocation.getCurrentPosition(success, error, { maximumAge: 600_000, timeout: 10_000 });
        }else{
            setGps('[19.4323007,-99.2708557]');
        }
    }
    const handleChange = (event) => {
        event.persist();
        let validacion = validarCampo(event.target.value, event.target.name);
        if (validacion.correcto) {
            setInputsValue(inputsValue => ({ ...inputsValue, [event.target.name]: event.target.value }));
            setInputsErrors(inputsErrors => ({ ...inputsErrors, [event.target.name]: '' }));
        } else {
            setInputsValue(inputsValue => ({ ...inputsValue, [event.target.name]: event.target.value }));
            setInputsErrors(inputsErrors => ({ ...inputsErrors, ...validacion.errores }));
        }
    };

    useEffect(() => {
        let validacion = validarValoresFormulario(inputsValue);
        if (validacion.correcto) {
            setContinuar(true);
            setInputsErrors(validacion.errores);
        } else {
            setContinuar(false);
            setInputsErrors(validacion.errores);
        }
    }, [inputsValue])

    return (
        <Fragment>
            <div className="main_gradient">
                <Header ruta="identificacion_edit" show={() => setShowHelp(true)} />
                <div className="main_text_container">
                    <h1 className="animate__animated">Por favor verifica y completa tu dirección</h1>
                    <p className="animate__animated">Es muy importante <b>verificar</b> que la información capturada <b>sea fiel</b> a la información de tu <b>documentación</b> así podremos <b>verificarla</b> fácilmente.</p>
                    
                </div>

                <div className="ocr_data_display">
                    <form id="form-ocr">
                        <div className="row_ext">
                            <div className="row">
                                <div className="col-6">
                                    <div className={["form-group", "bmd-form-group", "col-50", ((focusCodigoPostal || inputsValue.codigoPostal !== "") ? "is-focused" : ""), inputsErrors.codigoPostal && 'error'] .join(" ")}>
                                        <label htmlFor="codigoPostal" className="bmd-label-floating">Código Postal:</label>
                                        <input type="text" max="99999" className="form-control none_border" id="codigoPostal" name="codigoPostal" defaultValue={inputsValue.codigoPostal} onChange={e => verificarCP(e.target.value)} onFocus={e => {
                                            setFocusCodigoPostal(true)
                                            setInputActive(true)
                                        }} onBlur={e => {
                                            if (inputsValue.codigoPostal === '') {
                                                setFocusCodigoPostal(false)
                                            }
                                            setInputActive(false)
                                        }
                                        }
                                            maxLength="5" onInput={e => maxLengthCheck(e)} />
                                        {inputsErrors.codigoPostal && (
                                            <span id="ht-codigoPostal" className="helper-text ht" data-error="wrong" data-success="right">{inputsErrors.codigoPostal}</span>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={["form-group", "bmd-form-group", ((focusPais || inputsValue.pais !== "") ? "is-focused" : ""), inputsErrors.pais && 'error'].join(" ")}>
                            <label htmlFor="pais" className="bmd-label-floating">País:*</label>
                            <input type="text" className="form-control none_border" id="pais" name= "pais" defaultValue={inputsValue.pais} onChange={handleChange} onFocus={e => {
                                setFocusPais(true)
                                setInputActive(true)
                            }} onBlur={e => {
                                if (inputsValue.pais === "") {
                                    setFocusPais(false)
                                }
                                setInputActive(false)
                            }} />
                            {inputsErrors.pais && (
                                <span id="ht-pais" className="helper-text ht" data-error="wrong" data-success="right">{inputsErrors.pais}</span>
                            )}
                        </div>
                        <div className={["form-group", "bmd-form-group", ((focusEstado || inputsValue.estado !== "") ? "is-focused" : ""), inputsErrors.estado && 'error'] .join(" ")}>
                            <label htmlFor="estado" className="bmd-label-floating">Estado / Provincia / Región:*</label>
                            <input type="text" className="form-control none_border" id="estado" name="estado" defaultValue={inputsValue.estado} onChange={handleChange} onFocus={e => {
                                setFocusEstado(true)
                                setInputActive(true)
                            }} onBlur={e => {
                                if (inputsValue.estado === '') {
                                    setFocusEstado(false)
                                }
                                setInputActive(false)
                            }} />
                            {inputsErrors.estado && (
                                <span id="ht-codigoPostal" className="helper-text ht" data-error="wrong" data-success="right">{inputsErrors.estado}</span>
                            )}
                        </div>
                        <div className={["form-group", "bmd-form-group", ((focusDelegacion || inputsValue.delegacion !== "") ? "is-focused" : ""), inputsErrors.delegacion && 'error'].join(" ")}>
                            <label htmlFor="delegacion" className="bmd-label-floating">Delegación / Municipio:*</label>
                            <input type="text" className="form-control none_border" id="delegacion" name="delegacion" defaultValue={inputsValue.delegacion} onChange={handleChange} onFocus={e => {
                                setFocusDelegacion(true)
                                setInputActive(true)
                            }} onBlur={e => {
                                if (inputsValue.delegacion === '') {
                                    setFocusDelegacion(false)
                                }
                                setInputActive(false)
                            }} />
                            {inputsErrors.delegacion && (
                                <span id="ht-delegacion" className="helper-text ht" data-error="wrong" data-success="right">{inputsErrors.delegacion}</span>
                            )}
                        </div>
                        {(databack) ?
                            <div className={["form-group", "bmd-form-group", ((focusColonia || inputsValue.colonia !== "") ? "is-focused" : ""), inputsErrors.colonia && 'error'].join(" ")}>
                                <label htmlFor="colonia" className="bmd-label-floating">Colonia / Asentamiento:*</label>
                                <input type="text" className="form-control none_border" id="colonia" name="colonia" defaultValue={inputsValue.colonia} onChange={handleChange} onFocus={e => {
                                    setFocusColonia(true)
                                    setInputActive(true)
                                }} onBlur={e => {
                                    if (inputsValue.colonia === '') {
                                        setFocusColonia(false)
                                    }
                                    setInputActive(false)
                                }} />
                                {inputsErrors.colonia && (
                                    <span id="ht-colonia" className="helper-text ht" data-error="wrong" data-success="right">{inputsErrors.colonia}</span>
                                )}
                            </div>
                            :
                            <div className={["form-group", "bmd-form-group", "is-focused", inputsErrors.colonia && 'error'].join(" ")}>
                                <label htmlFor="colonia" className="bmd-label-floating">Colonia / Asentamiento:*</label>
                                <select className="custom-select" id="colonia" name="colonia" onChange={e => revisarColonia(e.target.value)} value={inputsValue.colonia}>
                                    <option disabled>Seleccionar:</option>
                                    {
                                        optionsSelect.map(
                                            (objeto, index) => (
                                                <option key={`${objeto}`} value={`${objeto}`}>{objeto}</option>
                                            )
                                        )
                                    }
                                    <option value="Otro">Otro</option>
                                </select>
                                {inputsErrors.colonia && (
                                    <span id="ht-colonia" className="helper-text ht" data-error="wrong" data-success="right">{inputsErrors.colonia}</span>
                                )}
                            </div>
                        }
                        {(otro) &&
                            <div className={["form-group", "bmd-form-group", ((focusColonia || inputsValue.otraColonia !== "") ? "is-focused" : ""), inputsErrors.otraColonia && 'error'].join(" ")}>
                                <label htmlFor="otraColonia" className="bmd-label-floating">Colonia / Asentamiento:*</label>
                                <input type="text" className="form-control none_border" id="otraColonia" name="otraColonia" defaultValue={inputsValue.otraColonia} onChange={handleChange} onFocus={e => {
                                    setFocusColonia(true)
                                    setInputActive(true)
                                }} onBlur={e => {
                                    if (inputsValue.colonia === '') {
                                        setFocusColonia(false)
                                    }
                                    setInputActive(false)
                                }} />
                                {inputsErrors.otraColonia && (
                                    <span id="ht-otraColonia" className="helper-text ht" data-error="wrong" data-success="right">{inputsErrors.otraColonia}</span>
                                )}
                            </div>}
                        <div className={["form-group", "bmd-form-group", ((focusCalle || inputsValue.calle !== "") ? "is-focused" : ""), inputsErrors.calle && 'error'].join(" ")}>
                            <label htmlFor="inputCalle" className="bmd-label-floating">Calle:*</label>
                            <input type="text" className="form-control" id="calle" name="calle" defaultValue={inputsValue.calle} onChange={handleChange} onFocus={e => {
                                setFocusCalle(true)
                                setInputActive(true)
                            }} onBlur={e => {
                                if (inputsValue.calle === '') {
                                    setFocusCalle(false)
                                }
                                setInputActive(false)
                            }} />
                            {inputsErrors.calle && (
                                <span id="ht-calle" className="helper-text ht" data-error="wrong" data-success="right">{inputsErrors.calle}</span>
                            )}                        
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className={["form-group", "bmd-form-group", ((focusNumeroExterior || inputsValue.numeroExterior !== "") ? "is-focused" : ""), inputsErrors.numeroExterior && 'error'].join(" ")}>
                                    <label htmlFor="numeroExterior" className="bmd-label-floating">Número exterior:</label>
                                    <input type="text" className="form-control none_border" id="numeroExterior" name="numeroExterior" defaultValue={inputsValue.numeroExterior} onChange={handleChange} onFocus={e => {
                                        setFocusNumeroExterior(true)
                                        setInputActive(true)
                                    }} onBlur={e => {
                                        if (inputsValue.numeroExterior === '') {
                                            setFocusNumeroExterior(false)
                                        }
                                        setInputActive(false)
                                    }} />
                                    {inputsErrors.numeroExterior && (
                                        <span id="ht-numeroExterior" className="helper-text ht" data-error="wrong" data-success="right">{inputsErrors.numeroExterior}</span>
                                    )}
                                </div>
                            </div>
                            {/* <div className="col-6">
                                <div className={["form-group", "bmd-form-group", ((focusNumeroInterior || inputsValue.numeroInterior) ? "is-focused" : ""), inputsErrors.numeroInterior && 'error'].join(" ")}>
                                    <label htmlFor="numeroInterior" className="bmd-label-floating">Número interior:</label>
                                    <input type="text" className="form-control none_border" id="numeroInterior" name="numeroInterior" defaultValue={inputsValue.numeroInterior} onChange={handleChange} onFocus={e => {
                                        setFocusNumeroInterior(true)
                                        setInputActive(true)
                                    }} onBlur={e => {
                                        if (inputsValue.numeroInterior === '') {
                                            setFocusNumeroInterior(false)
                                        }
                                        setInputActive(false)
                                    }} />
                                    {inputsErrors.numeroInterior && (
                                        <span id="ht-numeroInterior" className="helper-text ht" data-error="wrong" data-success="right">{inputsErrors.numeroInterior}</span>
                                    )}
                                </div>
                            </div> */}
                        </div>
                        
                        <div className="break_float"></div>
                        <br />
                        <table className="ubicacionGPS">
                            <tbody>
                                <tr>
                                    <th><img src="images/location_icon.svg" height="30" alt="" /></th>
                                    <th>Validar ubicación<br></br><small>Validar via GPS ahora</small></th>
                                    <th>
                                        <div className="checkboxswitch">
                                            <label htmlFor="check_switch" className="switchDir">
                                                <input id="check_switch" type="checkbox" onChange={geoLocalizacion} />
                                                <span className="sliderDir round"></span>
                                            </label>
                                        </div>
                                    </th>
                                </tr>
                            </tbody>
                        </table>
                        <span id="ht-gps" className="helper-text ht" data-error="wrong" data-success="right"></span>
                        <div id="out"></div>
                        <br /><br />
                        {/* <button type="button" disabled={!codigoPostal || !pais || !estado || !delegacion || !colonia || !colonia2} className={["btn", "btn-raised", "forcewidth100", ((!codigoPostal || !pais || !estado || !delegacion || !colonia || !colonia2) ? "btn-secondary" : "btn-primary main_bg_color")].join(" ")} onClick={e => actualizarDatos(e)}>GUARDAR</button> */}
                        <button type="button" disabled={!continuar} className={["btn", "btn-raised", "forcewidth100", ((!continuar) ? "btn-secondary" : "btn-primary main_bg_color")].join(" ")} onClick={e => actualizarDatos(e)}>GUARDAR</button>
                    </form>
                </div>
                {/* <Footer /> */}
                {errorUbicacion ?
                    <div className="modal fade show" style={{ display: "block", color: "#212529", textAlign: "left", fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif", fontWeight: "400" }} role="dialog">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title">Atención</h5>
                                    <button onClick={e => setErrorUbicacion(false)} className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <p>Necesitamos validar tu ubicación para continuar</p>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" onClick={() => {setErrorUbicacion(false); sendEventClick('Datos de direccion OCR', 'ENTENDIDO')}} className="btn btn-raised btn-primary main_bg_color" >ENTENDIDO</button>
                                </div>
                            </div>
                        </div>
                    </div> : null}
            </div>
            {showHelp ? <Ayuda hide={() => setShowHelp(false)} /> : ""}
        </Fragment>
    )
}

export default IdentificacionEdit