import React, { useState, useEffect, Fragment } from "react";
import { Link, useHistory } from "react-router-dom";
import { configStyle, obtenerValorConfig } from '../../services/configStyle'
import { enviarRenapo, enviarIne } from '../../services/api';
import moment from 'moment';
import { hayExcepcion, agregarInfo, statusData, validarCampo, validarValoresFormulario, evento } from '../../services/data'
import Info from '../../components/encuentra_info';
import Loader from '../../components/loader';


let FormularioIneConsulta = (props) => {
    const history = useHistory();
    const [dataOtorgante, setDataOtorgante] = useState([])
    const [dataOCRBack, setDataOCRBack] = useState({})
    const [dataOCRFront, setDataOCRFront] = useState({})
    const [focusCurp, setFocusCurp] = useState(false)
    const [focusVigencia, setFocusVigencia] = useState(false)
    const [focusClave, setfocusClave] = useState(false);
    const [focusRegistro, setfocusRegistro] = useState(false);
    const [focusEmision, setfocusEmision] = useState(false);
    const [focusOCR, setFocusOCR] = useState(false);
    const [dataUser, setDataUser] = React.useState([])
    const [inputActive, setInputActive] = useState(false)
    const [esPassport, setEsPassport] = useState(false);
    const [esIne, setEsIne] = useState(true);
    const [esCLaboral, setCLaboral] = useState(true);
    const [noOCR, setNoOCR] = useState(false);
    const [apiKey, setApiKey] = useState('');
    const [inputsValue, setInputsValue] = useState({
        ocr: '',
        clave: '',
        fechaRegistro: '',
        numeroEmision: '',
        curp: '',
        vigencia: ''
    });
    const [inputsErrors, setInputsErrors] = useState({});
    const [esVigente, setEsVigente] = useState(true);
    const [loading, setLoading] = useState(false);
    const [sendForm, setSendForm] = useState(false);

    useEffect(() => {

        let no_OCR = localStorage.getItem("intentsData")
        if (no_OCR !== null) {
            if (no_OCR === "OCR")
                setNoOCR(true)
        }

        let apikeyOtorgante = localStorage.getItem("apikeyOtorgante")
        if (apikeyOtorgante !== null) {
            setApiKey(apikeyOtorgante)
        }

        let dataOtorganteLS = localStorage.getItem("dataOtorgante")
        if (dataOtorganteLS != null) {
            setDataOtorgante(JSON.parse(dataOtorganteLS))
        }
        let dataUserLS = localStorage.getItem("data_user")
        if (dataUserLS != null) {
            setDataUser(JSON.parse(dataUserLS))
        }

        let dataOCRFrontLS = localStorage.getItem("ocrFront")
        const ocrFront = JSON.parse(dataOCRFrontLS)
        if (dataOCRFrontLS != null) {
            setDataOCRFront(ocrFront);
            if (ocrFront.claveElector)
                setInputsValue(inputsValue => ({ ...inputsValue, ["clave"]: (ocrFront.claveElector).trim() }));
            if (ocrFront.anioRegistro)
                setInputsValue(inputsValue => ({ ...inputsValue, ["fechaRegistro"]: (ocrFront.anioRegistro).trim() }));
            if (ocrFront.numeroRegistro)
                setInputsValue(inputsValue => ({ ...inputsValue, ["numeroEmision"]: (ocrFront.numeroRegistro).trim() }));
            if (ocrFront.curp)
                if (ocrFront.curp.length > 14)
                    setInputsValue(inputsValue => ({ ...inputsValue, ["curp"]: (ocrFront.curp).trim() }));
            let vigenciaC = ''
            if (ocrFront.vigencia != null) {
                vigenciaC = "20" + ocrFront.vigencia.substr(-2);
                setInputsValue(inputsValue => ({ ...inputsValue, ["vigencia"]: vigenciaC }));
            }
        }

        if (localStorage.getItem("apikeyOtorgante") === "50e6a1b5-fc07-459d-a78a-de4a3cad810b") {
            setCLaboral(false)
        }

        let dataOCRBackLS = localStorage.getItem("ocrBack");

        if (dataOCRBackLS != null) {
            setDataOCRBack(JSON.parse(dataOCRBackLS));
            const ocrBack = JSON.parse(dataOCRBackLS);
            let vigenciaC = '';

            if (ocrBack.ineVigencia != null) {
                vigenciaC = ocrBack.ineVigencia;
                let formatosP = ['DD/MM/YYYY', 'DD/MM/YY'];
                if (moment(vigenciaC, formatosP, true).isValid()) {
                    // setInputsValue(inputsValue => ({ ...inputsValue, ["vigencia"]: vigenciaC }));
                    setInputsValue(inputsValue => ({ ...inputsValue, ["vigencia"]: "20" + vigenciaC.substr(-2) }));
                }
            } else {
                if (ocrFront.vigencia != null) {
                    if (vigenciaC) {
                        setInputsValue(inputsValue => ({ ...inputsValue, ["vigencia"]: "20" + vigenciaC.substr(-2) }));
                    } else {
                        let vi = ""
                        if (ocrFront.vigencia.includes(' '))
                            vi = ocrFront.vigencia.replaceAll(' ', '/');
                        else
                            vi = ocrFront.vigencia;
                        setInputsValue(inputsValue => ({ ...inputsValue, ["vigencia"]: vi }));
                    }
                } else {
                    if (ocrFront.vigencia)
                        vigenciaC = "20" + ocrFront.vigencia.substr(-2);
                    setInputsValue(inputsValue => ({ ...inputsValue, ["vigencia"]: vigenciaC }));
                }
            }
            /*  if (ocrBack.cicMRZ)
                 setInputsValue(inputsValue => ({ ...inputsValue, ["cic"]: (ocrBack.cicMRZ).trim() })); */
            let ocr = '';
            if (ocrBack.seccionMRZ)
                ocr = ocrBack.seccionMRZ.trim();
            if (ocrBack.ciudadanoMRZ)
                ocr = `${ocr}${ocrBack.ciudadanoMRZ.trim()}`;
            setInputsValue(inputsValue => ({ ...inputsValue, ["ocr"]: ocr }));
        }

    }, [])

    useEffect(() => {
        configStyle(dataOtorgante)
    }, [dataOtorgante]);

    useEffect(() => {
        localStorage.setItem("data_user", JSON.stringify(dataUser))
    }, [dataUser]);

    const sendData = (infoFormulario) => {
        var jsonObj = {};
        jsonObj.infoPersonal = infoFormulario;
        statusData("datos_personales_ocr", jsonObj)
    }

    const handleChange = (event) => {
        event.persist();
        let validacion = validarCampo(event.target.value, event.target.name);
        if (validacion.correcto) {
            setInputsValue(inputsValue => ({ ...inputsValue, [event.target.name]: event.target.value }));
            setInputsErrors(inputsErrors => ({ ...inputsErrors, [event.target.name]: '' }));        // Valida en tiempo real los inputs
        } else {
            setInputsValue(inputsValue => ({ ...inputsValue, [event.target.name]: event.target.value }));
            setInputsErrors(inputsErrors => ({ ...inputsErrors, ...validacion.errores }));      // Valida en tiempo real los inputs
        }

    };

    const validarFormulario = (event) => {
        if (event) event.persist(); event.preventDefault(); event.stopPropagation();
        setSendForm(true);
        // console.log("inputsValue",inputsValue);
        let continuar = validarValoresFormulario(inputsValue);
        if (continuar.correcto) {
            evento('Datos personales OCR', 'Click', { description: 'MIS DATOS SON CORRECTOS', status: 'OK' }, true);
            let infoFormulario = []
            let fechaActual = inputsValue.vigencia;
            let lengthString = inputsValue.ocr.length;
            let idCiudadano = inputsValue.ocr.substring(4, lengthString);
            agregarInfo(dataUser, { description: "claveElector", value: inputsValue.clave });
            infoFormulario.push({ description: "claveElector", value: inputsValue.clave });
            agregarInfo(dataUser, { description: "fechaRegistro", value: inputsValue.fechaRegistro });
            infoFormulario.push({ description: "fechaRegistro", value: inputsValue.fechaRegistro });
            agregarInfo(dataUser, { description: "numeroEmision", value: inputsValue.numeroEmision });
            infoFormulario.push({ description: "numeroEmision", value: inputsValue.numeroEmision });
            agregarInfo(dataUser, { description: "ocr", value: inputsValue.ocr });
            infoFormulario.push({ description: "ocr", value: inputsValue.ocr });
            agregarInfo(dataUser, { description: "curp", value: inputsValue.curp });
            infoFormulario.push({ description: "curp", value: inputsValue.curp });
            agregarInfo(dataUser, { description: "vigencia", value: "31/12/20" + inputsValue.vigencia.substr(-2) });
            infoFormulario.push({ description: "vigencia", value: "31/12/20" + inputsValue.vigencia.substr(-2) });
            fechaActual = "20" + inputsValue.vigencia.substr(-2) + "/12/31";
            infoFormulario.push({ description: "idCiudadano", value: idCiudadano });
            agregarInfo(dataUser, { description: "idCiudadano", value: idCiudadano });
            let fechaV = new Date(fechaActual);
            if (fechaV >= Date.now()) {
                sendData(infoFormulario);
            } else {
                // console.log("Vigencia invalida");
                setEsVigente(false)
            }
            setTimeout(() => {
                history.push('/resultados_identificacion');
            }, 300);

        } else {
            evento('Datos personales OCR', 'Click', { description: 'MIS DATOS SON CORRECTOS', status: 'Error', Errores: continuar.errores }, false);
            setInputsErrors(inputsErrors => ({ ...inputsErrors, ...continuar.errores }));
            setSendForm(false);
        }

    }

    const statusSE = () => {
        evento('Datos personales OCR', 'Click', { description: 'CAPTURAR NUEVAMENTE' }, true);
    }

    // Valida el formulario al cargar datos
    useEffect(() => {
        // console.log("objeto", Object.keys(inputsValue));
        let validacion = false;
        if (Object.keys(inputsValue).length > 0) {
            Object.keys(inputsValue).forEach(valor => {
                switch (valor) {
                    case "clave":
                        validacion = validarCampo(inputsValue.clave, valor);
                        if (validacion.correcto) {
                            setInputsErrors(inputsErrors => ({ ...inputsErrors, [valor]: '' }));
                        } else {
                            setInputsErrors(inputsErrors => ({ ...inputsErrors, ...validacion.errores }));
                        }
                        break;
                    case "fechaRegistro":
                        validacion = validarCampo(inputsValue.fechaRegistro, valor);
                        if (validacion.correcto) {
                            setInputsErrors(inputsErrors => ({ ...inputsErrors, [valor]: '' }));
                        } else {
                            setInputsErrors(inputsErrors => ({ ...inputsErrors, ...validacion.errores }));
                        }
                        break;
                    case "numeroEmision":
                        validacion = validarCampo(inputsValue.numeroEmision, valor);
                        if (validacion.correcto) {
                            setInputsErrors(inputsErrors => ({ ...inputsErrors, [valor]: '' }));
                        } else {
                            setInputsErrors(inputsErrors => ({ ...inputsErrors, ...validacion.errores }));
                        }
                        break;
                    case "curp":
                        validacion = validarCampo(inputsValue.curp, valor);
                        if (validacion.correcto) {
                            setInputsErrors(inputsErrors => ({ ...inputsErrors, [valor]: '' }));
                        } else {
                            setInputsErrors(inputsErrors => ({ ...inputsErrors, ...validacion.errores }));
                        }
                        break;
                    case "vigencia":
                        validacion = validarCampo(inputsValue.vigencia, valor);
                        if (validacion.correcto) {
                            setInputsErrors(inputsErrors => ({ ...inputsErrors, [valor]: '' }));
                        } else {
                            setInputsErrors(inputsErrors => ({ ...inputsErrors, ...validacion.errores }));
                        }
                        break;
                    case "ocr":
                        validacion = validarCampo(inputsValue.ocr, valor);
                        if (validacion.correcto) {
                            setInputsErrors(inputsErrors => ({ ...inputsErrors, [valor]: '' }));
                        } else {
                            setInputsErrors(inputsErrors => ({ ...inputsErrors, ...validacion.errores }));
                        }
                        break;
                    default:
                        break;
                }
            })
        }
    }, [inputsValue]);

    const handleKeyUp = (event) => event.target.value = event.target.value.toUpperCase();

    return (
        <Fragment>
            <form id="FormularioIneConsulta">
                <div className="ocr_data_display animate__animated">
                    <div className={["form-group", "bmd-form-group", ((focusClave || inputsValue.clave !== "") ? "is-focused" : ""), inputsErrors.clave && 'error'].join(" ")}>
                        <label htmlFor="clave" className="bmd-label-floating">Clave de elector:<small>(Dato frontal)</small></label>
                        <input type="text" className="form-control" id="clave" name="clave" maxLength="20" defaultValue={inputsValue.clave} onChange={handleChange} onFocus={e => {
                            setfocusClave(true)
                        }} onBlur={e => {
                            if (inputsValue.clave === "") {
                                setfocusClave(false)
                            }
                        }} />
                        {inputsErrors.clave && (
                            <span id="ht-clave" className="helper-text ht" data-error="wrong" data-success="right">{inputsErrors.clave}</span>
                        )}
                    </div>
                    <div className="row">
                        <div className="col">
                            <div className={["form-group", "bmd-form-group", ((focusRegistro || inputsValue.fechaRegistro !== "") ? "is-focused" : ""), inputsErrors.fechaRegistro && 'error'].join(" ")}>
                                <label htmlFor="fechaRegistro" className="bmd-label-floating">Año de registro:<small>(Dato frontal)</small></label>
                                <input type="text" className="form-control" id="fechaRegistro" name="fechaRegistro" maxLength="4" defaultValue={inputsValue.fechaRegistro} onChange={handleChange} onFocus={e => {
                                    setfocusRegistro(true)
                                }
                                } onBlur={e => {
                                    if (inputsValue.fechaRegistro === "") {
                                        setfocusRegistro(false)
                                    }
                                }} />
                                {inputsErrors.fechaRegistro && (
                                    <span id="ht-registro" className="helper-text ht" data-error="wrong" data-success="right">{inputsErrors.fechaRegistro}</span>
                                )}
                            </div>
                        </div>
                        <div className="col">
                            <div className={["form-group", "bmd-form-group", ((focusEmision || inputsValue.numeroEmision !== "") ? "is-focused" : ""), inputsErrors.numeroEmision && 'error'].join(" ")}>
                                <label htmlFor="numeroEmision" className="bmd-label-floating">Número de emisión:<small>(Dato frontal)</small></label>
                                <input type="text" className="form-control" id="numeroEmision" name="numeroEmision" defaultValue={inputsValue.numeroEmision} maxLength="2" onChange={handleChange} onFocus={e => {
                                    setfocusEmision(true)
                                }
                                } onBlur={e => {
                                    if (inputsValue.numeroEmision === "") {
                                        setfocusEmision(false)
                                    }
                                }} />
                                {inputsErrors.numeroEmision && (
                                    <span id="ht-emision" className="helper-text ht" data-error="wrong" data-success="right">{inputsErrors.numeroEmision}</span>
                                )}
                            </div>
                        </div>
                    </div>

                    <div className={["form-group", "bmd-form-group", ((focusOCR || inputsValue.ocr !== "") ? "is-focused" : ""), inputsErrors.ocr && 'error'].join(" ")}>
                        <label htmlFor="ocr" className="bmd-label-floating">OCR:<small>(Dato reverso)</small></label>
                        <input type="text" className="form-control" id="ocr" name="ocr" maxLength="13" defaultValue={inputsValue.ocr} onChange={handleChange} onFocus={e => {
                            setFocusOCR(true)
                        }} onBlur={e => {
                            if (inputsValue.ocr === "") {
                                setFocusOCR(false)
                            }
                        }} />
                        {inputsErrors.ocr && (
                            <span id="ht-ocr" className="helper-text ht" data-error="wrong" data-success="right">{inputsErrors.ocr}</span>
                        )}
                    </div>

                    <div className={["form-group", "bmd-form-group", ((focusCurp || inputsValue.curp !== "") ? "is-focused" : ""), inputsErrors.curp && 'error'].join(" ")}>
                        <label htmlFor="curp" className="bmd-label-floating">CURP:</label>
                        <input type="text" className="form-control uppercase" id="curp" name="curp" defaultValue={inputsValue.curp} maxLength="19" onChange={handleChange} onKeyUp={handleKeyUp} onFocus={e => {
                            setFocusCurp(true)
                        }} onBlur={e => {
                            if (inputsValue.curp === "") {
                                setFocusCurp(false)
                            }
                        }} />
                        {inputsErrors.curp && (
                            <span id="ht-curp" className="helper-text ht" data-error="wrong" data-success="right">{inputsErrors.curp}</span>
                        )}
                    </div>

                    <div className="row">
                        <div className="col-6">
                            <div className={["form-group", "bmd-form-group", ((focusVigencia || inputsValue.vigencia !== "") ? "is-focused" : ""), inputsErrors.vigencia && 'error'].join(" ")}>
                                <label htmlFor="vigencia" className="bmd-label-floating">Vencimiento identificación:</label>
                                <input type="text" className="form-control" id="vigencia" name="vigencia" maxLength="4" defaultValue={inputsValue.vigencia} onChange={handleChange} onFocus={e => {
                                    setFocusVigencia(true)
                                }} onBlur={e => {
                                    if (inputsValue.vigencia === "") {
                                        setFocusVigencia(false)
                                    }
                                }} />
                                {inputsErrors.vigencia && (
                                    <span id="ht-vigencia" className="helper-text ht" data-error="wrong" data-success="right">{inputsErrors.vigencia}</span>
                                )}
                            </div>
                        </div>
                        <div className="col">

                        </div>
                    </div>
                    <Info />
                </div>
                {(!esVigente) ? (
                    <div className="err_finale  animated slideInUp">
                        <div className="center_checks">
                            <h5>Identificación no valida </h5>
                            <p>La <b>vigencia</b> de tu identificación <b>{inputsValue.vigencia}</b> no es valida, por favor <b>renueva tu identificación</b> e intenta otro día, si crees que es un error <b>intenta capturarla nuevamente</b>.
                            </p>
                            <br />
                            <div className="action_buttons noscroll_screen">
                                <Link to={{
                                    pathname: "/" + apiKey
                                }} className="btn btn-secondary forcewidth100">TERMINAR</Link>
                                <Link to={{
                                    pathname: "/identificacion",
                                    state: { passport: esPassport }
                                }} className="btn btn-raised btn-primary forcewidth100 main_bg_color">CAPTURAR NUEVAMENTE</Link>
                            </div>
                        </div>
                    </div>) : ("")}
                {loading && <Loader />}
                <div className="action_buttons animate__animated">
                    <button type="button" className={["btn btn-raised btn-primary forcewidth100 main_bg_color"].join(" ")} onClick={event => validarFormulario(event)} disabled={sendForm ? true : false}>MIS DATOS SON CORRECTOS</button>
                    <Link to={{
                        pathname: "/identificacion",
                        state: { passport: esPassport }
                    }} className="btn btn-primary forcewidth100 main_color" onClick={statusSE} disabled={sendForm ? true : false}>CAPTURAR NUEVAMENTE</Link>
                </div>
            </form>
        </Fragment>
    )
}

export default FormularioIneConsulta