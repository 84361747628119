import { enviarEvento } from "../../services/api";

const token = process.env.REACT_APP_TOKEN_EVENTS;

export const evento = (paso, tipo, data, finished, configuration, uuidOtorgante = '') => {
    let newHeaders = [];
    const dato = JSON.stringify(data);
    let fecha = "";
    let date = new Date();
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();
    let hour = date.getHours();
    let min = date.getMinutes();
    let seconds = date.getSeconds();
    if (month < 10) {
        fecha = `${year}-0${month}-${day}_${hour}:${min}:${seconds}`
    } else {
        fecha = `${year}-${month}-${day}_${hour}:${min}:${seconds}`
    }
    let objeto = {
        uuid_clt: configuration.uuidUser,
        uuid_trx: configuration.uuidTransaccion,
        uuid_oto: configuration.uuidOtorgante? configuration.uuidOtorgante: uuidOtorgante,
        apikey: configuration.apikey,
        paso: paso,
        tipo: tipo,
        dato: dato,
        finished: finished,
        time_stamp: fecha
    }

    newHeaders.push({ key: 'accept', value: 'application/json' });
    newHeaders.push({ key: 'xc-token', value: token });

    enviarEvento(configuration,objeto, newHeaders).then(response => {
        //console.log('RESPONSE', response);
        return
    }).catch(error => console.log('ERROR', error));
}

export const sendEventClick = (paso, description, objectInformation, configuration) => {
    let objeto = { description: description };
    let objetoEvento = {
        ...objeto,
        ...objectInformation
    }
    evento(paso, 'Click', objetoEvento, true, configuration);
}