import React, { Fragment } from "react";

const encuentraInfo = () => {

    const showModal = () => {
        var modal = document.getElementById("myModal");
        var btn = document.getElementById("myBtn");
        var span = document.getElementsByClassName("close")[0];
        var span2 = document.getElementById("closeModal");
    
        btn.onclick = function() {
        modal.style.display = "block";
        }
    
        span.onclick = function() {
        modal.style.display = "none";
        }
    
        span2.onclick = function() {
            modal.style.display = "none";
            }
    
        window.onclick = function(event) {
            if (event.target == modal) {
                modal.style.display = "none";
            }
        }
    }

    return (
        <Fragment>
            <div className="ocr_data_display">
                        <button type="button" id="myBtn"
                        className="btn btn-primary forcewidth100 main_color" 
                        data-toggle="modal" 
                        data-target="#modal_ine" onMouseOver={showModal}>
                            ENCUENTRA TU INFORMACIÓN
                        </button>
                    </div>
	
            <div className="modal" tabIndex="-1" role="dialog" id="myModal" /*id="modal_ine"*/>
                <div className="modal-dialog" role="document" >
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Validación INE</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>

                        <div className="modal-body">
                            <p>Identifica la versión de tu identificación y valida la información.</p>
                            <p><b>Frente</b></p>
                            <img src="./images/ines/credencial-modeloC_f.png" className="img-fluid" alt="Responsive image"/>
                            <p><b>Reverso</b></p>
                            <img src="./images/ines/credencial-modeloEG_v.png" className="img-fluid" alt="Responsive image"/>
                            <img src="./images/ines/credencial-modeloD_v.png" className="img-fluid" alt="Responsive image"/>
                            <img src="./images/ines/credencial-modeloC_v.png" className="img-fluid" alt="Responsive image"/>
                        </div>
                        
                        <div className="modal-footer" id="closeModal">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Entendido</button>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default encuentraInfo;
