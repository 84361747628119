import axios from 'axios';
import {
    obtenerValorConfig
} from '../services/configStyle';

const host = process.env.REACT_APP_URL
const servicio = process.env.REACT_APP_SERVICE
const bucket = process.env.REACT_APP_BUCKET
const bucketHuellas = process.env.REACT_APP_BUCKET_HUELLAS
const tokenHuellas = process.env.REACT_APP_TOKEN_HUELLAS
const correoH = window.location.hostname;
const token = process.env.REACT_APP_TOKEN_EVENTS;



const endpoints = {
    otorgante: `${host}/v1/sec_${servicio}params`,
    otorgantePK: `${host}/v1/sec_${servicio}params_pk`,
    ocr: `${host}/v2/sec_${servicio}ocr`,
    correo: `${host}/v1/sec_${servicio}async_zip_nom_151_correo`,
    pdftoken: `${host}/v1/sec_${servicio}token_numbers_pk`,
    score: `${host}/v2/sec_${servicio}query_score`,
    generarSMS: `${host}/v1/sec_${servicio}token_sms_ac`,
    validarSMS: `${host}/v1/sec_${servicio}verify_token_sms/verify`,
    otp: `${host}/v1/sec_${servicio}otp`,
    actualizarScore: `${host}/v2/sec_${servicio}score_update`,
    gps: `${host}/v1/sec_${servicio}address`,
    actualizarEstado: `${host}/v2/sec_${servicio}update_cycle`,
    getCountries: `${host}/v1/sec_${servicio}countries`,
    getCP: `${host}/v1/sec_${servicio}postalcodes`,
    pedirTurno: `${host}/v1/sec_${servicio}otorgante_general_take_turn`,
    turnoFila: `${host}/v1/sec_${servicio}otorgante_general_get_turn`,
    actualizarBandera: `${host}/v1/sec_${servicio}otorgante_general_update_turn/`,
    sendHuella: `${host}/v1/sec_${servicio}fingerprint`,
    sendFile: `${host}/v1/sec_${servicio}file`,
    sendRenapo: `${host}/v1/sec_${servicio}valid_curp`, //valid_curp,
    sendIne: `${host}/v1/sec_${servicio}valid_ine`, //valid_ine
    sendProfile: `${host}/v1/sec_${servicio}datauser_update`,
    newVideoToken: `${host}/v2/sec_${servicio}video_token`,
    sendHologram: `${host}/v1/sec_${servicio}id_calibrate`,
    sendEvent: `${host}/v1/sec_${servicio}events`,
    evaluateImage: `${host}/v1/sec_${servicio}h_prediction/classify`,
    sendVideoTokenPDF: `${host}/v1/sec_${servicio}public_video_token`,
    renapo: `${host}/v1/sec_${servicio}valid_curp`,
    ine: `${host}/v1/sec_${servicio}valid_ine`,
    sendDataNoco: `${host}/v1/sec_${servicio}neco_huellas`,
    cancelarTurno: `${host}/v1/sec_${servicio}otorgante_general/publicTurno/`,
    generateIceServers: `${host}/v1/sec_${servicio}turn`,
};

let CancelToken = axios.CancelToken;
let cancel = () => {};
let cancelPeticionRenapo = () => {};
let cancelPeticionIne = () => {};
let cancelRevisarTurno = () => {};

/* Obtener headers */
const getHeaders = () => {
    let objeto = {};
    let uuidUser = localStorage.getItem("uuidUser") || '';
    let uuidTrx = localStorage.getItem("uuidTrx") || '';
    let apikeyOtorgante = localStorage.getItem("apikeyOtorgante") || '';

    objeto.cliente = uuidUser;
    objeto.transaccion = uuidTrx;
    objeto.apikey = apikeyOtorgante;

    return objeto;
}


export function actualizarEstado(objeto) {
    var data = JSON.stringify(objeto);
    return axios({
        method: 'post',
        url: endpoints.actualizarEstado,
        headers: {
            'Content-Type': 'application/json',
            'transaccion': getHeaders().transaccion,
            'cliente': getHeaders().cliente,
            'apikey': getHeaders().apikey
        },
        data: data
    });
}

export function obtenerParametrosOtorgante(idOtorgante, apikey) {
    return axios({
        method: 'get',
        url: endpoints.otorgante,
        headers: {

            'Content-Type': 'multipart/form-data',
            'transaccion': getHeaders().transaccion,
            'cliente': getHeaders().cliente,
            'apikey': getHeaders().apikey
        },
        params: {
            "idOtorgante": idOtorgante
        }
    });
}

export function obtenerParametrosOtorgantePK(idOtorgante, apikey) {
    return axios({
        method: 'get',
        url: endpoints.otorgantePK,
        headers: {

            'Content-Type': 'multipart/form-data',
            'transaccion': getHeaders().transaccion,
            'cliente': getHeaders().cliente,

        },
        params: {
            "idOtorgante": idOtorgante
        }
    });
}

export function obtenerOCR(uuid, imagen, side, isIOS, isMobileSafari) {
    var data = new FormData();
    data.append("uuidUser", uuid);
    data.append("ine", imagen, side + "-" + (new Date().getTime()) + ".png");
    data.append("side", side);
    data.append("bucket", bucket ); //dco-bucket-qa  dco-bucket-prod
    data.append("originOS", ((isIOS || isMobileSafari) ? "ios" : "android"));
    //console.log("enviando:"+side + "-" + (new Date().getTime()) + ".png");
    return axios({
        method: 'post',
        url: endpoints.ocr,
        headers: {

            'Content-Type': 'application/x-www-form-urlencoded',
            'transaccion': getHeaders().transaccion,
            'cliente': getHeaders().cliente,
            'apikey': getHeaders().apikey
        },
        data: data
    });
}

export function obtenerTokenVideo(uuid) {
    return axios({
        method: 'post',
        url: endpoints.token,
        headers: {

            'Content-Type': 'application/json',
            'transaccion': getHeaders().transaccion,
            'cliente': getHeaders().cliente,
            'apikey': getHeaders().apikey
        },
        crossdomain: true,
        params: {
            uuid: uuid
        }
    });
}

export function actualizarScore(uuid, calle, colonia, cp, estado, municipio, numeroExt, gps, empresa) {
    var data = JSON.stringify({
        "update": "true",
        "uuid": uuid,
        "transaccion": getHeaders().transaccion,
        //"otorgante_uuid": localStorage.getItem("uuidOtorgante"),
        //"otorgante_name": empresa,
        "calle": calle,
        "colonia": colonia,
        "cp": cp,
        "estado": estado,
        "municipio": municipio,
        "numero": numeroExt,
        "gps": gps,
        "isDesktop": true
    });
    return axios({
        method: 'post',
        url: endpoints.actualizarScore,
        headers: {
            'Content-Type': 'application/json',
            'transaccion': getHeaders().transaccion,
            'cliente': getHeaders().cliente,
            'apikey': getHeaders().apikey
        },
        crossdomain: true,
        data: data
    });
}

export function obtenerTokenPDFVideo(uuid) {
    return axios({
        method: 'post',
        url: endpoints.pdftoken,
        headers: {

            'Content-Type': 'application/json',
            'transaccion': getHeaders().transaccion,
            'cliente': getHeaders().cliente,
            'apikey': getHeaders().apikey
        },
        crossdomain: true,
        data: {
            uuid: uuid
        }
    });
}

export function verificarTokenVideo(uuid, token, video, isIOS, isMobileSafari) {
    var data = new FormData();
    data.append("uuidUser", uuid);
    data.append("created", token.created);
    data.append("expire", token.expire);
    data.append("videoBase64", video, "videoaceptacion.webm");
    data.append("bucket", bucket);
    data.append("folder", "video_aceptacion/");
    data.append("originOS", ((isIOS || isMobileSafari) ? "ios" : "android"));
    data.append("process", "local");
    return axios({
        method: 'post',
        url: endpoints.verify,
        headers: {

            'Content-Type': 'application/x-www-form-urlencoded',
            'transaccion': getHeaders().transaccion,
            'cliente': getHeaders().cliente,
            'apikey': getHeaders().apikey
        },
        data: data
    });
}

export function verificarTokenPDFVideo(uuid, token, video, isIOS, isMobileSafari) {
    var data = new FormData();
    data.append("uuidUser", uuid);
    data.append("created", token.created);
    data.append("expire", token.expire);
    data.append("videoBase64", video, "videoaceptacion.webm");
    data.append("bucket", bucket);
    data.append("folder", "video_aceptacion/");
    data.append("originOS", ((isIOS || isMobileSafari) ? "ios" : "android"));
    data.append("process", "local");
    return axios({
        method: 'post',
        url: endpoints.pdfverify,
        headers: {

            'Content-Type': 'application/x-www-form-urlencoded',
            'transaccion': getHeaders().transaccion,
            'cliente': getHeaders().cliente,
            'apikey': getHeaders().apikey
        },
        data: data
    });
}

export function enviarImagen(uuid, file) {
    var data = new FormData();
    data.append("file", file, (new Date().getTime()) + "_frame.jpg");
    data.append("uuid", uuid);
    //console.log("Creando OTP");
    return axios({
        method: 'post',
        url: endpoints.otp,
        cancelToken: new CancelToken(function executor(cancelar) {
            cancel = cancelar;
        }),
        headers: {

            'Content-Type': 'multipart/form-data',
            'transaccion': getHeaders().transaccion,
            'cliente': getHeaders().cliente,
            'apikey': getHeaders().apikey
        },
        data: data
    });
}

export function enviarComprobante(file) {
    var data = new FormData();
    let ruta = localStorage.getItem("uuidOtorgante") + "/" + getHeaders().cliente + "/" + getHeaders().transaccion + "/domicilio"
    data.append("bucket", bucket);
    data.append("folder", ruta);
    data.append("uuid", getHeaders().cliente);
    data.append("file", file, (new Date().getTime()) + "_comprobante.jpg");
    //console.log("Creando OTP");
    return axios({
        method: 'post',
        url: endpoints.sendFile,
        headers: {
            'Content-Type': 'multipart/form-data',
            'transaccion': getHeaders().transaccion,
            'cliente': getHeaders().cliente,
            'apikey': getHeaders().apikey
        },
        data: data
    });
}

export function crearSMS(uuid, numero, area) {
    return axios({
        method: 'post',
        url: endpoints.generarSMS,
        headers: {

            'Content-Type': 'application/json',
            'transaccion': getHeaders().transaccion,
            'cliente': getHeaders().cliente,
            'apikey': getHeaders().apikey
        },
        crossdomain: true,
        data: {
            "client": {
                "uuid": uuid,
                "platform": "android"
            },
            "phone": {
                "number": numero,
                "areaCode": area
            }
        }
    });
}

export function validarSMSServicio(uuid, numero, area, codigo) {
    return axios({
        method: 'patch',
        url: endpoints.validarSMS,
        headers: {
            'Content-Type': 'application/json',
            'transaccion': getHeaders().transaccion,
            'cliente': getHeaders().cliente,
            'apikey': getHeaders().apikey
        },
        crossdomain: true,
        data: {
            "client": {
                "uuid": uuid,
                "platform": "android"
            },
            "phone": {
                "areaCode": area,
                "number": area + numero,
                "token": codigo
            }
        }
    });
}

export function obtenerScore(uuid, isDesktop) {
    let dataOtorgante = JSON.parse(localStorage.getItem("dataOtorgante"));
    let nombreOtorgante = (obtenerValorConfig(dataOtorgante, 'NOMBRE_CORTO', ""));
    return axios({
        method: 'get',
        url: endpoints.score + '/' + uuid + '/' + getHeaders().transaccion,
        headers: {
            'Content-Type': 'multipart/form-data',
            'transaccion': getHeaders().transaccion,
            'cliente': getHeaders().cliente,
            'apikey': getHeaders().apikey,
            'X-Consumer-Custom-ID': localStorage.getItem("uuidOtorgante"),
            'X-Consumer-Username': nombreOtorgante
        },
        params: {
            isDesktop: isDesktop
        }
    });
}

export function obtenerEstadoEnlace(enlace) {
    return axios({
        method: 'get',
        url: enlace,
        headers: {
            'Content-Type': 'multipart/form-data',
            'transaccion': getHeaders().transaccion,
            'cliente': getHeaders().cliente,
            'apikey': getHeaders().apikey
        }
    });
}

export const convertBlobToBase64 = blob => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onerror = reject;
    reader.onload = () => {
        resolve(reader.result);
    };
    reader.readAsDataURL(blob);
});

export const getImageDimensions = file => new Promise((resolved, rejected) => {
    var i = new Image();
    i.onload = function () {
        resolved({
            w: i.width,
            h: i.height
        });
    };
    i.src = file;
});

export function obtenerMaps(cp, uuid) {
    return axios({
        method: 'post',
        url: endpoints.gps,
        headers: {
            'Content-Type': 'application/json',
            'transaccion': getHeaders().transaccion,
            'cliente': getHeaders().cliente,
            'apikey': getHeaders().apikey
        },
        data: {
            address: {
                cp: cp,
                street: ""
            },
            uuid: uuid
        }
    })
}

export function obtenerPais() {
    return axios({
        method: 'POST',
        url: endpoints.getCountries,
        headers: {
            'Content-Type': 'application/json',
            'apikey': getHeaders().apikey
        },
        data:{
            uuid: getHeaders().cliente
        }
    })
}

export function obtenerCP(cp) {
    return axios({
        method: 'post',
        url: endpoints.getCP,
        headers: {
            'Content-Type': 'application/json',
            'transaccion': getHeaders().transaccion,
            'cliente': getHeaders().cliente,
            'apikey': getHeaders().apikey
        },
        data: {
            address: {
                cp: cp,
                street: ""
            },
            uuid: getHeaders().cliente
        }
    })
}

/** TURNOS */

export function obtenerTurno(nombre, app, apm, telefono, correo, nombreCompleto, linkVideoLlamada) {
    return axios({
        method: 'post',
        url: endpoints.pedirTurno,
        headers: {
            'Content-Type': 'application/json',
            'apikey': getHeaders().apikey
        },
        data: {
            clienteTransaccion: getHeaders().transaccion,
            clienteUuid: getHeaders().cliente,
            'idOtorgante': localStorage.getItem("uuidOtorgante"),
            //"clienteVideoLink": localStorage.getItem("linkIframe"),
            // "clienteVideoLink": "https://wrtc-videocall.dev.devdicio.net:4005/call/" + getHeaders().cliente + "?otorgante=" + localStorage.getItem("uuidOtorgante") + "&cliente=" + getHeaders().cliente + "&apikey=" + getHeaders().apikey + "&transaccion=" + getHeaders().transaccion + "&call=''" ,
            "clienteVideoLink": linkVideoLlamada,
            "nombre": nombre,
            "apellidoPaterno": app,
            "apellidoMaterno": apm,
            "telefono": telefono,
            "correo": correo,
            "nombreCompleto": nombreCompleto
        }
    })
}


export function revisarTurno() {
    return axios({
        method: 'get',
        url: endpoints.turnoFila,
        cancelToken: new CancelToken(function executor(cancelar) {
            cancelRevisarTurno = cancelar;
        }),
        headers: {
            'Content-Type': 'application/json',
            'clienteTransaccion': getHeaders().transaccion,
            'clienteUuid': getHeaders().cliente,
            'apikey': getHeaders().apikey
        }
    })
}

export function enviarSemaforo(bandera) {
    return axios({
        method: 'PUT',
        url: endpoints.actualizarBandera + localStorage.getItem("id_call"),
        headers: {
            'apikey': getHeaders().apikey,
        },
        data: {
            "idStatusScore": bandera
        }
    })
}

/** HUELLAS */

export function enviarImgHuella(telefono, correo, dispositivo, lefthandimage, righthandimage) {
    var data = new FormData();
    data.append("uuidUser", getHeaders().cliente);
    data.append("telefono", telefono);
    data.append("correo", correo);
    data.append("geolocalizacion", "graylog");
    data.append("dispositivo", dispositivo);
    data.append("bucket", bucket);
    // mano izquierda
    if (lefthandimage.length > 0) {
        data.append("lefthandimage1", lefthandimage[0], "lefthandimage1.png");
        //data.append("lefthandimage2", lefthandimage[1], "lefthandimage2.png");
        //data.append("lefthandimage3", lefthandimage[2], "lefthandimage3.png");
    }

    // mano derecha
    if (righthandimage.length > 0) {
        data.append("righthandimage1", righthandimage[0], "righthandimage1.png");
        //data.append("righthandimage2", righthandimage[1], "righthandimage2.png");
        //data.append("righthandimage3", righthandimage[2], "righthandimage3.png");
    }

    return axios({
        method: 'post',
        url: endpoints.sendHuella,
        headers: {

            'Content-Type': 'multipart/form-data',
            'transaccion': getHeaders().transaccion,
            'cliente': getHeaders().cliente,
            'apikey': getHeaders().apikey
        },
        data: data
    });
}

/** IMAGENES DE MANO */

export function enviarPosionMano(turno, curp) {
    return axios({
        method: 'POST',
        url: endpoints.sendRenapo,
        headers: {
            'apikey': getHeaders().apikey,
        },
        data: {
            "uuid": getHeaders().cliente,
            "turn": parseInt(turno),
            "curp": curp
        }
    })
}

/* RENAPO */

export function enviarRenapo(curp, idTurno) {
    return axios({
        method: 'POST',
        url: endpoints.renapo,
        cancelToken: new CancelToken(function executor(cancelar) {
            cancelPeticionRenapo = cancelar;
        }),
        headers: {
            'apikey': getHeaders().apikey,
            'transaccion': getHeaders().transaccion,
            'cliente': getHeaders().cliente,
        },
        data: {
            "uuid": getHeaders().cliente,
            "turn": idTurno,
            "curp": curp
        }
    })
}

export function obtenerResultadoRenapo(jobID) {
    return axios({
        method: 'GET',
        url: endpoints.renapo,
        cancelToken: new CancelToken(function executor(cancelar) {
            cancelPeticionRenapo = cancelar;
        }),
        headers: {
            'apikey': getHeaders().apikey,
        },
        params: {
            job_id: jobID
        }
    })
}

/* INE */

export function enviarIne(ocr, idCiudadano,claveElector, numeroEmision, modeloIdentificacion, idTurno) {
    let data = {
        "ocr": ocr,
        "clave_elector": claveElector,
        "numero_emision": numeroEmision,
        "id_ciudadano": idCiudadano,
    }
    /* if (cic.length === 13) {
        data = {
            "ocr": ocr,
            "clave_elector": claveElector,
            "numero_emision": numeroEmision,
            "id_ciudadano": idCiudadano,
        }
    } else {
        data = {
            "cic": cic,
            "id_ciudadano": idCiudadano,
        }
    } */
    return axios({
        method: 'POST',
        url: endpoints.ine,
        cancelToken: new CancelToken(function executor(cancelar) {
            cancelPeticionIne = cancelar;
        }),
        headers: {
            'apikey': getHeaders().apikey,
            //'Otorgante': getHeaders().cliente,
            'transaccion': getHeaders().transaccion,
            'cliente': getHeaders().cliente,
        },
        data: {
            "uuid": getHeaders().cliente,
            "turn": idTurno,
            "modeloIdentificacion": modeloIdentificacion,
            "data": data,
        }
    })
}

export function obtenerResultadoINE(jobID) {
    return axios({
        method: 'GET',
        url: endpoints.ine,
        cancelToken: new CancelToken(function executor(cancelar) {
            cancelPeticionIne = cancelar;
        }),
        headers: {
            'apikey': getHeaders().apikey,
        },
        params: {
            job_id: jobID
        }
    })
}

/* Enviar video de llamada */
export function enviarVideo(file) {
    var data = new FormData();
    let ruta = localStorage.getItem("uuidOtorgante") + "/" + getHeaders().cliente+ "/" + getHeaders().transaccion + "/videocall_client"
    data.append("bucket", bucket);
    data.append("folder", ruta);
    data.append("uuid", getHeaders().cliente);
    data.append("file", file, (new Date().getTime()) + "videocall_client.mp4");
    return axios({
        method: 'post',
        url: endpoints.sendFile,
        headers: {
            'Content-Type': 'multipart/form-data',
            'transaccion': getHeaders().transaccion,
            'cliente': getHeaders().cliente,
            'apikey': getHeaders().apikey
        },
        data: data
    });
}

// Enviar video
export function enviarVideoRostro(facefile, Acreated, Aexpire, isIOS ) {
    let so = isIOS ? 'ios' : 'android';
    var data = new FormData();
    data.append("uuidUser", getHeaders().cliente);
    data.append("created", Acreated);
    data.append("expire", Aexpire);
    data.append("originOS",so);
    data.append("service_call", "videotoken-embedding");
    const ocrFront = JSON.parse(localStorage.getItem("ocrFront"))
    let passFlag = "false"
    if (ocrFront != null) {
        if (ocrFront.identificacionPasaporte){
            passFlag = "true"
        }
    }
    data.append("identificacion_pasaporte", passFlag);
    let FECHA_UNICA = new Date().getTime();
    let VIDEO_OUTPUT_FILE = FECHA_UNICA + 'recording.webm';
    if (isIOS) {
        VIDEO_OUTPUT_FILE = FECHA_UNICA + 'recording.mp4';
    }
    data.append("videotoken", facefile, VIDEO_OUTPUT_FILE);

    return axios({
        method: 'POST',
        url: endpoints.newVideoToken, 
        cancelToken: new CancelToken(function executor(cancelar) {
            cancel = cancelar;
        }),
        headers: {
            'Content-Type': 'multipart/form-data',
            'transaccion': getHeaders().transaccion,
            'cliente': getHeaders().cliente,
            'apikey': getHeaders().apikey,

        },
        data: data
    });
    //return requestValidateVideo(dataRequest);
}

// Enviar video holograma
export const enviarHologramaIdentificacion = (hologramFile,isIOS) => {
    let uuidUser = getHeaders().cliente;
    let so = isIOS ? 'ios' : 'android';
    let data = new FormData();
    data.append("originOS", so);

    let FECHA_UNICA = new Date().getTime();
    let VIDEO_OUTPUT_FILE = FECHA_UNICA + 'recording.webm';
    if (isIOS) {
        VIDEO_OUTPUT_FILE = FECHA_UNICA + 'recording.mp4';
    }
    data.append("ine", hologramFile, VIDEO_OUTPUT_FILE);
    
    return axios({
        method: 'POST',
        url: endpoints.sendHologram,
        headers: {
            'Content-Type': 'multipart/form-data',
            'transaccion': getHeaders().transaccion,
            'cliente': getHeaders().cliente,
            apikey: getHeaders().apikey

        },
        data: data
    });
}

export function sendFile(file, route, fileName) {
    let data = new FormData();
    data.append("bucket", bucketHuellas);
    data.append("folder", route);
    data.append("uuid", getHeaders().cliente);
    data.append("file", file, fileName);

    return axios({
        method: 'post',
        url: endpoints.sendFile,
        headers: {
            'Content-Type': 'multipart/form-data',
            'transaccion': getHeaders().transaccion,
            'cliente': getHeaders().cliente,
            'apikey': getHeaders().apikey,
        },
        data: data
    });
}

export const enviarEvento = (objeto) =>{
    let uuidUser = getHeaders().cliente;
    let uuidTrx = getHeaders().transaccion;
    let apikeyOtorgante = getHeaders().apikey;
    //let data = JSON.stringify(objeto);
    return axios({
        method: 'post',
        url: endpoints.sendEvent,
        headers: {
            'Content-Type': 'application/json',
            'accept': 'application/json',
            'transaccion': uuidTrx,
            'cliente': uuidUser,
            'apikey':apikeyOtorgante,
            'xc-token': token,
        },
        data: objeto
    });
}

export function getDocuments() {
    return axios({
        method: 'post',
        url: endpoints.getDocuments,
        headers: {
            'Content-Type': 'application/json',
            'apikey': getHeaders().apikey,
            'transaccion': getHeaders().transaccion,
            'cliente': getHeaders().cliente,
        },
        data: {
            uuidUser: getHeaders().cliente
        }
    })
}

//Evaluar imagen
export const enviarImagenEvaluar = (image, model, name, side) => {
    let uuidUser = getHeaders().cliente;
    let data = new FormData();
    data.append("model", model);
    data.append("photo", image, name);
    data.append("useScore", true);
    data.append("event", side);
    return axios({
        method: 'POST',
        url: endpoints.evaluateImage,
        headers: {
            'Content-Type': 'multipart/form-data',
            'transaccion': getHeaders().transaccion,
            'cliente': uuidUser,
            apikey: getHeaders().apikey

        },
        data: data
    });
}

export function finalUpdateScore(objeto) {
    let uuidTrx = getHeaders().transaccion;
    let cliente = getHeaders().cliente;
    let apikeyOtorgante = getHeaders().apikey;
    objeto.uuid = cliente;
    objeto.transaccion = uuidTrx;
    let data = JSON.stringify(objeto);
    return axios({
        method: 'post',
        url: endpoints.actualizarScore,
        headers: {
            'Content-Type': 'application/json',
            'transaccion': uuidTrx,
            'cliente': cliente,
            'apikey': apikeyOtorgante
        },
        crossdomain: true,
        data: data
    });
}

export const enviarInformacionNoco = (objeto) => {
    objeto.bucket = bucketHuellas;
    return axios({
        method: 'post',
        url: endpoints.sendDataNoco,
        headers: {
            'Content-Type': 'application/json',
            'accept': 'application/json',
            'transaccion': getHeaders().transaccion,
            'cliente': getHeaders().cliente,
            'apikey': getHeaders().apikey,
            'xc-token': tokenHuellas,
        },
        data: objeto
    });
}

// Enviar video
export function enviarVideoRostroPDF(headers, data) {
    return axios({
        method: 'POST',
        url: endpoints.sendVideoTokenPDF,
        headers: headers,
        data: data
    });
}

//uuid, mailto, nombre, nacimiento, curp, otorgante, vigencia, coincidencia, liveness,textoConsentimiento, apiKey 
export function enviarCorreoAceptacion(data) {
    return axios({
        method: 'post',
        url: endpoints.correo,
        headers: {
            'Content-Type': 'application/json',
            'apikey': getHeaders().apikey,
            'transaccion': getHeaders().transaccion,
            'cliente': getHeaders().cliente,
            'mail-host': correoH,
            'Accept': 'application/json'
        },
        data: data
    });
}

export function enviarZipAmarillos(datos) {
    return axios({
        method: 'post',
        url: endpoints.correo,
        headers: {
            'Content-Type': 'application/json',
            'transaccion': getHeaders().transaccion,
            'cliente': getHeaders().cliente,
            'apikey': getHeaders().apikey,
        },
        data: datos
    });
}

export function actualizarScoreInicioFlujo() {
    let uuid =  getHeaders().cliente;
    let transaccion = getHeaders().transaccion;
    let otorgante_uuid = localStorage.getItem('uuidOtorgante')
    let data = JSON.stringify({
        "uuid": uuid,
        "transaccion": transaccion,
        otorgante_uuid: otorgante_uuid,
    });
    return axios({
        method: 'post',
        url: endpoints.actualizarScore,
        headers: {
            'Content-Type': 'application/json',
            'transaccion': getHeaders().transaccion,
            'cliente': getHeaders().cliente,
            'apikey': getHeaders().apikey,
        },
        crossdomain: true,
        data: data
    });
} 

export function cancelarRequest() {
    cancel();
}

export function cancelarTurno(idTurno) {
    return axios({
        method: 'put',
        url: endpoints.cancelarTurno+idTurno,
        headers: {
            'Content-Type': 'application/json',
            'clienteTransaccion': getHeaders().transaccion,
            'clienteUuid': getHeaders().cliente,
            'apikey': getHeaders().apikey
        },
        data: {
            "idStatusTurno":"CANCELADO"
        }
    })
}

export function cancelarRequestRenapo() {
    cancelPeticionRenapo();
}

export function cancelarRequestIne() {
    cancelPeticionIne();
}

export function actualizarTurno(objeto) {
    return axios({
        method: 'PUT',
        url: endpoints.actualizarBandera + localStorage.getItem("id_call"),
        headers: {
            'apikey': getHeaders().apikey,
        },
        data: objeto,
    })
}

/* Generar ICE servers */

export const generarIceServers = () => {
    const o = {
        format: 'urls',
    }
    const bodyString = JSON.stringify(o);
    const apikey = localStorage.getItem("apikeyOtorgante");
    return axios({
        method: 'put',
        url: endpoints.generateIceServers,
        headers: {
            'Content-Type': 'application/json',
            // eslint-disable-next-line max-len
            'Authorization': 'Basic ' + btoa('ortizs:64d00bac-3af0-11eb-abd8-0242ac150002'),
            'apikey': apikey,
        },
        data: bodyString,
    })
}

export function cancelarRequestTurno() {
    cancelRevisarTurno();
}

/* TURNOS Actualizado */

export const pedirTurno = (data) => {
    return axios({
        method: 'post',
        url: endpoints.pedirTurno,
        headers: {
            'Content-Type': 'application/json',
            'apikey': getHeaders().apikey
        },
        data: data
    })
}