import React, { useState, useEffect, Fragment } from "react";
import { useHistory } from "react-router-dom";
import Header from "../components/headerNA"
import Footer from "../components/footer";
import Ayuda from './ayuda';
import {sendEventClick} from '../services/data'

const HuellasInvalidas = (props) => {

    const history = useHistory();
    const [caso, setCaso] = useState('')
    const [apiKey, setApiKey] = useState('')
    const [showHelp, setShowHelp] = useState(false);

    useEffect(() => {

        let apikeyOtorgante = localStorage.getItem("apikeyOtorgante")
        if (apikeyOtorgante !== null) {
            setApiKey(apikeyOtorgante)
        }

    }, [])

    return (
        <Fragment>
            <div className="id_progress  animate__animated animate__slideInUp">

                <div className="main_gradient">

                    <Header ruta="loader_fail_huellas" show={() => setShowHelp(true)}/>


                    <div className="main_text_container">

                        <h1>Huellas imposibilitadas</h1>

                        <p>Si por cualquier razón no te es posible capturar tus dedos indices indica la razón a continuación:</p>

                    </div>

                    <div className="ocr_data_display">

                        <form>

                            <div className="form-group">
                                <label htmlFor="exampleTextarea" className="bmd-label-floating">Describe tu caso:</label>
                                <textarea className="form-control custom-color-text" id="exampleTextarea" rows="3" onChange={e => {setCaso(e.target.value)}} defaultValue={caso}></textarea>
                            </div>

                        </form>

                    </div>


                    <div className="action_buttons">
                    <button type="button" className="btn btn-raised btn-primary forcewidth100 main_bg_color" onClick={() => {history.push('/preparacion_comprobante'); sendEventClick('Huella Descripción','CONTINUAR', {});}} disabled={!caso}>CONTINUAR</button>
                        <button type="button" className="btn btn-primary forcewidth100 main_color" onClick={() => {history.go('/preparacion_huellas'); sendEventClick('Huella Descripción','CAPTURAR NUEVAMENTE', {});}}>CAPTURAR NUEVAMENTE</button>
                    </div>

                    {/* <Footer /> */}

                </div>

            </div>
            {showHelp ? <Ayuda hide={() => setShowHelp(false)} /> : ""}
        </Fragment>
    );
}

export default HuellasInvalidas;