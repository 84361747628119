import React, { /*useContext,*/ useState, useEffect } from 'react';
import ComponenteCapturaIdentificacion from './componente_captura_identificacion';
import { changeValueOfConfiguration } from './utilities/configuration/configuration';
import { /*configStyle,*/ obtenerValorConfig } from './utilities/styles/configStyle';

const Index = () => {

    const [apiKey, setApiKey] = useState('');
    const [uuidUser, setUuidUser] = useState('');
    const [uuidTransaccion, setUuidTransaccion] = useState('');
    const [finished, setFinished] = useState(false);
    const [uuidOtorgante, setUuidOtorgante] = useState('');
    const [nombreOtorgante, setNombreOtorgante] = useState('');
    const [dataOtorgante, setDataOtorgante] = useState([]);
    const [dataUser, setDataUser] = useState([]);
    const [ocr, setOcr] = useState(false);
    const [fullOcr, setFullOcr] = useState(true);
    const [classify, setClassify] = useState(true);
    /* let configuracion = {
        apikey: apiKey,
        uuidUser: uuidUser,
        uuidTransaccion: uuidTransaccion,
    } */

    const getSetHook = (key) => {
        switch (key) {
            case 'apikey':
                return setApiKey;
            case 'uuidUser':
                return setUuidUser;
            case 'uuidTransaccion':
                return setUuidTransaccion;
            case 'uuidOtorgante':
                return setUuidOtorgante;
            case 'nombreOtorgante':
                return setNombreOtorgante;
            case 'finished':
                return setFinished;
            case 'ocr':
                return setOcr;
            case 'fullOcr':
                return setFullOcr;
            case 'classify':
                return setClassify;
            default:
                break;
        }
    }

    useEffect(() => {
        //obtenerIformacionOtorgante()
        let apikeyLocalStorage = localStorage.getItem("apikeyOtorgante");
        if (apikeyLocalStorage) {
            setApiKey(apikeyLocalStorage);
        }
        let uuidUserLocalStorage = localStorage.getItem("uuidUser");
        if (uuidUserLocalStorage) {
            setUuidUser(uuidUserLocalStorage);
        }
        let uuidTrxLocalStorage = localStorage.getItem("uuidTrx");
        if (uuidTrxLocalStorage) {
            setUuidTransaccion(uuidTrxLocalStorage);
        }
        let dataUserLocalStorage = localStorage.getItem("data_user");
        if (dataUserLocalStorage) {
            let dataUserJSON = JSON.parse(dataUserLocalStorage);
            setDataUser(dataUserJSON);
        }
        let dataOtorganteLocalStorage = localStorage.getItem("dataOtorgante")
        if (dataOtorganteLocalStorage) {
            let dataOtorganteJSON = JSON.parse(dataOtorganteLocalStorage);
            setDataOtorgante(dataOtorganteJSON);
        }
        return () => { };
    }, []);

    useEffect(() => {
        if (dataOtorgante.length > 0) {
            let nuevoUuidOtorgante = obtenerValorConfig(dataOtorgante, 'ID_OTORGANTE', "");
            setUuidOtorgante(nuevoUuidOtorgante);
            let nombreOtorgante = obtenerValorConfig(dataOtorgante, 'NOMBRE_CORTO', "");
            setNombreOtorgante(nombreOtorgante);
        }
        return () => { };
    }, [dataOtorgante]);

    /* const obtenerIformacionOtorgante = () => {
        //setLoading(true)
        obtenerParametrosOtorgante(configuracion).then(({ status, data, data: { payload } }) => {
            if (status === 200) {
                configStyle(payload);
                setDataOtorgante(payload);
                let nuevoUuidOtorgante = obtenerValorConfig(payload, 'ID_OTORGANTE', "");
                setUuidOtorgante(nuevoUuidOtorgante);
                configuracion.uuidOtorgante = nuevoUuidOtorgante;
                let nombreOtorgante = obtenerValorConfig(payload, 'NOMBRE_CORTO', "");
                setNombreOtorgante(nombreOtorgante);
                evento('Obtener parametros otorgante', 'Succes', mapearRespuesta(status, data), true, configuracion, uuidOtorgante);
                obtenerInformacionDispositivo();
            }
            //setLoading(false)
        }).catch((error) => {
            //console.log('Error', error);
            //statusError('inicio_page', 'Error al obtener los parámetros del otorgante', 'error', configuration);
            let errorMapeado = mapearError(error);
            evento('Obtener parametros otorgante', errorMapeado.tipo, errorMapeado.objetoError, true, configuracion);

            //setLoading(false)
        });
    }

    const obtenerInformacionDispositivo = () => {
        evento('Dispositvo', 'Step', deviceDetect(), true, configuracion);
        evento('Dimensiones viewport', 'Información', { innerWidth: window.innerWidth, innerHeight: window.innerHeight }, true, configuracion);
    } */

    return (
        <>
            <ComponenteCapturaIdentificacion
                apikey={apiKey}
                uuidUser={uuidUser}
                uuidTransaccion={uuidTransaccion}
                uuidOtorgante={uuidOtorgante}
                nombreOtorgante={nombreOtorgante}
                finished={finished}
                dataOtorgante={dataOtorgante}
                dataUser={dataUser}
                fullOcr={fullOcr}
                classify={classify}
                ocr={ocr}
                conexionPivote={true}
                changeValue={(key, value) => changeValueOfConfiguration(value, getSetHook(key))}
                nextStep='/resultados_cic'
            />
        </>
    );
}

export default Index;
