import React, { Fragment, useState, useEffect } from 'react'
import { Link } from "react-router-dom";
import { configStyle, obtenerValorConfig } from '../services/configStyle'

const Header = (props) => {
    //process.env.PUBLIC_URL + '/images/wl/comp_logo.png'
    const [dataOtorgante, setDataOtorgante] = useState([]);
    const [hayCookies] = useState(navigator.cookieEnabled);
    const [pathname, setpathname] = useState('');

    useEffect(() => {
        let host = window.location.pathname;
        setpathname(host)

        if(hayCookies){
            let dataOtorganteLS = localStorage.getItem("dataOtorgante")
            if (dataOtorganteLS != null) {
                setDataOtorgante(JSON.parse(dataOtorganteLS))
                configStyle(JSON.parse(dataOtorganteLS))
            }
        }
        // //console.log(pathname);
        
    }, []);

    useEffect(() => {
        configStyle(dataOtorgante)
    }, [dataOtorgante])

    return (
        <Fragment>
            <div id="head_container">
                <img id="imagenLogo" className="comp_logo animate__animated animate__slideInDown" src={(obtenerValorConfig(dataOtorgante, 'LOGO_OTORGANTE', "") != "") ? obtenerValorConfig(dataOtorgante, 'LOGO_OTORGANTE', process.env.PUBLIC_URL + '/images/wl/comp_logo.png') : process.env.PUBLIC_URL + '/images/wl/comp_logo.png'} alt="" />
                {
                    /* (pathname === "/Cw2qjkHFRvpLPryvUWXDcOPMEgHIYI9X" || pathname === "/7d59c982-2ada-4630-9f93-c5093d6bf6bb") ? 
                        <Link to={'/ayuda/' + props.ruta} className="help_btn animate__animated animate__fadeIn animate__delay-1s"><img src={process.env.PUBLIC_URL + '/images/help_icon.png'} alt="" /></Link>
                        :
                        "" */
                        (pathname === "/3DYrUMnehQLfahxKS78W75hfJYRS2cP6") ? 
                        <a className="help_btn animate__animated animate__fadeIn animate__delay-1s"><img src={process.env.PUBLIC_URL + '/images/btn_circle_help_c2all.svg'} alt="" onClick={e => props.show()}/></a>
                        :
                        ""
                }
            </div>
        </Fragment>
    );
}

export default Header;