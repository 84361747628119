import React, { useState, useEffect, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
    revisarTurno, obtenerScore, enviarSemaforo, finalUpdateScore,
    cancelarRequestRenapo, cancelarRequestIne, cancelarRequestTurno, actualizarTurno
} from '../services/api.js';
import { configStyle, obtenerValorUser, obtenerValorConfig } from '../services/configStyle'
import { status, statusError, evento, sendEventClick, generateZip, enviarTurnoCancelar } from '../services/data'
import { isMobile, isIOS } from 'react-device-detect';
import Loader from "../components/loader";
import Header from "../components/headerNA";
import Ayuda from './ayuda';
import { mapearError, mapearRespuesta } from '../components/captura_identificacion/utilities/response/map_response';

//const webrtc_call = process.env.REACT_APP_WEBRTC_CALL;
//let interval;
let consultarTurno;

let ValidacionFinal = (props) => {
    const history = useHistory();
    const location = useLocation();

    const [dataOtorgante, setDataOtorgante] = useState([])
    const [dataUser, setDataUser] = React.useState([])
    const [showCancel, setShowCancel] = useState(false)
    const [apiKey, setApiKey] = useState('')

    const [turno, setTurno] = useState('--')//
    const [llamadaLista, setllamadaLista] = useState(false);
    const [linkIframe, setlinkIframe] = useState("")

    const [contador, setContador] = useState(0);
    const [loading, setLoading] = useState(false);
    const [showHelp, setShowHelp] = useState(false);
    const [uuid, setUuid] = useState('');
    const videoRef = useRef();

    /* const wss = 'wss://api.devdicio.net:8444/v1/sec_dev_signal/call/'
    const apikey = localStorage.getItem('apikeyOtorgante');
    const uuidTransaccion = localStorage.getItem('uuidTrx'); */

    var eventMethod = window.addEventListener
        ? "addEventListener"
        : "attachEvent";
    var eventer = window[eventMethod];
    var messageEvent = eventMethod === "attachEvent"
        ? "onmessage"
        : "message";

    useEffect(() => {

        let apikeyOtorgante = localStorage.getItem("apikeyOtorgante")
        if (apikeyOtorgante !== null) {
            setApiKey(apikeyOtorgante)
        }
        let dataOtorganteLS = localStorage.getItem("dataOtorgante")
        if (dataOtorganteLS != null) {
            setDataOtorgante(JSON.parse(dataOtorganteLS))
        }
        let dataUserLS = localStorage.getItem("data_user")
        if (dataUserLS != null) {
            setDataUser(JSON.parse(dataUserLS))
        }
        let uuidUser = localStorage.getItem("uuidUser")
        if (uuidUser != null) {
            setUuid(uuidUser);
        }

        status("validacion_final", "Validación por videollamada");

        consultarTurno = true;

        actualizarEstadoTurno();

        return () => {
            consultarTurno = false;
         }

    }, [])

    useEffect(() => {
        configStyle(dataOtorgante)
    }, [dataOtorgante]);


    useEffect(() => {
        if (llamadaLista) {
            setTimeout(() => {
                history.push('/videollamada');
            }, 200);
        }
    }, [llamadaLista]);

    /* useEffect(() => {
        if (dataUser.length > 0 && dataOtorgante.length > 0) {
            pedirLugar();
        }
    }, [dataUser, dataOtorgante]); */

    const statusE = async () => {
        setLoading(true);
        cancelarRequestRenapo();
        cancelarRequestIne();
        cancelarRequestTurno();
        let idTurno = localStorage.getItem('id_call');
        await enviarTurnoCancelar(idTurno);
        sendEventClick('Validación por videollamada', 'SI', { status: 'CANCELADO' });
        let data = "Cancelado";
        localStorage.setItem('flag', 'cancelado');
        await generateZip('TRUNCOS', 'trunco', isIOS);
        statusError("validacion_final", data, "cancelado");
        setTimeout(() => {
            window.close();
            history.push("/" + apiKey);
            setLoading(false);
        }, 300);
    }

    /* const pedirLugar = async () => {
        let tipo = '';
        let informacion = {};
        let nombre = obtenerValorUser(dataUser, "nombres") || "";
        let app = obtenerValorUser(dataUser, "apellidoPaterno") || "";
        let apm = obtenerValorUser(dataUser, "apellidoMaterno") || "";
        let telefono = obtenerValorUser(dataUser, "telefono") || "";
        let correo = obtenerValorUser(dataUser, "email") || "";
        let nombreCompleto = ""//obtenerValorUser(dataUser, "email");
        setLoading(true);
        const linkVideoLlamada = wss + uuidTransaccion + "?apikey=" + apikey;
        obtenerTurno(nombre, app, apm, telefono, correo, nombreCompleto, linkVideoLlamada).then(async (response) => {
            if (response.status === 200) {
                let idCall = response.data.payload.id;
                informacion.status = response.status;
                informacion.payload = response.data.payload;
                evento('Obtener Turno', 'Success', informacion, true);
                localStorage.setItem("id_call", idCall);
                setlinkIframe(linkVideoLlamada);
                actualizarScore();
                await encolarPeticionRenapo(idCall);
                encolarPeticionINE(idCall);
                consultarLugar();
            }
        }).catch((error) => {
            setLoading(false);
            // console.log("algun error pedir lugar " + e);
            if (error.response) {
                let { data, status } = error.response
                informacion.data = data;
                informacion.status = status;
                tipo = 'Error';
            } else {
                informacion.error = error.toString();
                tipo = 'Exception';
            }
            evento('Obtener Turno', tipo, informacion, false);

        })
    } */

    const actualizarSemaforo = async (bandera) => {
        let tipo = '';
        let informacion = {};
        enviarSemaforo(bandera.toUpperCase()).then((response) => {
            if (response.status === 200) {
                informacion.status = response.status;
                informacion.payload = response.data.payload;
                evento('Actualizar Semáforo', 'Success', informacion, true);
            }
        }).catch((error) => {
            if (error.response) {
                let { data, status } = error.response
                informacion.data = data;
                informacion.status = status;
                tipo = 'Error';
            } else {
                informacion.error = error.toString();
                tipo = 'Exception';
            }
            evento('Actualizar Semáforo', tipo, informacion, false);
        })
    }

    const actualizarScore = () => {
        let tipo = '';
        let informacion = {};
        let objeto = { createScore: true }
        finalUpdateScore(objeto).then((response) => {
            if (response.status === 200) {
                informacion.status = response.status;
                informacion.payload = response.data.payload;
                evento('Actualizar Score', 'Success', informacion, true);
                setTimeout(() => {
                    obtenerScoreUser();
                }, 300);
            }
        }).catch((error) => {
            if (error.response) {
                let { data, status } = error.response
                informacion.data = data;
                informacion.status = status;
                tipo = 'Error';
            } else {
                informacion.error = error.toString();
                tipo = 'Exception';
            }
            evento('Actualizar Score', tipo, informacion, false);
        })
    }

    const obtenerScoreUser = async () => {
        let uuidUser = localStorage.getItem("uuidUser")
        let tipo = '';
        let informacion = {};
        obtenerScore(uuidUser, !isMobile).then((response) => {
            if (response.status === 200) {
                informacion.status = response.status;
                informacion.payload = response.data.payload;
                evento('Obtener Score', 'Success', informacion, true);
                //console.log("200:", response.data.payload);
                if (response.data.payload.flag) {
                    actualizarSemaforo(response.data.payload.flag)
                    status("consulta_score_page", "Validación de Score");
                    localStorage.setItem("flag", response.data.payload.flag);
                } else {
                    setContador(parseInt(contador) + 1)
                }
            }
        }).catch((error) => {
            setContador(parseInt(contador) + 1)
            if (error.response) {
                let { data, status } = error.response
                informacion.data = data;
                informacion.status = status;
                tipo = 'Error';
            } else {
                informacion.error = error.toString();
                tipo = 'Exception';
            }
            evento('Obtener Score', tipo, informacion, false);
        })
    }

    useEffect(() => {
        if (parseInt(contador) > 0 && parseInt(contador) < 10) {
            actualizarScore();
        } else {
            statusError("score_check", "Sin valores en el score", "error")
        }
    }, [contador])

    const consultarLugar = () => {
        if (consultarTurno) {
            let tipo = '';
            let informacion = {};
            revisarTurno().then((response) => {
                if (response.status === 200) {
                    informacion.status = response.status;
                    informacion.payload = response.data.payload;
                    evento('Revisar turno', 'Success', informacion, true);
                    if (response.data.payload.idStatusTurno === "ASIGNADO") {
                        setllamadaLista(true);
                        //revisarFinalizar();
                    } else {
                        const lugar = response.data.payload.lugar;
                        if (lugar !== 'POR_ASIGNAR') {
                            setTurno(lugar);
                        }
                        setLoading(false);
                        setTimeout(() => {
                            consultarLugar();
                        }, 5000);
                    }
                }
            }).catch((error) => {
                setLoading(false);
                if (error.response) {
                    let { data, status } = error.response
                    informacion.data = data;
                    informacion.status = status;
                    tipo = 'Error';
                } else {
                    informacion.error = error.toString();
                    tipo = 'Exception';
                }
                evento('Revisar turno', tipo, informacion, false);
                if ((error.response && error.response !== 404) && (!error.response && error.toString() !== 'Cancel')) {
                    setTimeout(() => {
                        consultarLugar();
                    }, 5000);
                } else {
                    return
                }
            });
        } else {
            return;
        }
    }

    /* const revisarFinalizar = async () => {
        let tipo = '';
        let informacion = {};
        revisarTurno().then(async (response) => {
            if (response.status === 200) {
                informacion.status = response.status;
                informacion.payload = response.data.payload;
                evento('Asignacion de Turno', 'Success', informacion, true);
                if (response.data.payload.idStatusTurno === "TERMINADO") {
                    clearInterval(interval);
                    //stop();
                    finalizar();
                } else {
                    console.log("sigue en llamada");
                }
            }
        }).catch((error) => {
            clearInterval(interval);
            if (error.response) {
                let { data, status } = error.response
                informacion.data = data;
                informacion.status = status;
                tipo = 'Error';
            } else {
                informacion.error = error.toString();
                tipo = 'Exception';
            }
            evento('Asignacion de Turno', tipo, informacion, false);
            // if (e.includes("401")) {
            //     setTimeout(() => {
            //         //history.push('/index');
            //     }, 200);
            // }
        })
    } */

    /* const finalizar = () => {
        //typeof(Android)!=='undefined' ? Android.starActivityAutorization() : ""
        setTimeout(() => {
            history.push('/finalizado');
        }, 500);
    } */

    const actualizarEstadoTurno = async () => {
        try {
            let objeto = { idStatusTurno: 'EN_ESPERA' };
            const response = await actualizarTurno(objeto);
            const { status } = response;
            if (status === 200) {
                evento('Actualizar Turno', 'Success', response, true);
                actualizarScore();
                consultarLugar();
            }
        } catch (error) {
            let errorMapeado = mapearError(error);
            evento('Actualizar Turno', errorMapeado.tipoError, errorMapeado.objetoError, false);
        }
    }

    return (
        <div id="webview" className="main_gradient">
            {/* {(llamadaLista) ?
                <div className="contentIframe" >
                    {<iframe
                        allowusermedia="true"
                        src={linkIframe}
                        width="100%"
                        height="100%"
                        frameBorder="0"
                        marginHeight="0"
                        marginWidth="0"
                        className="iframeV"
                        allow="camera; microphone; autoplay"
                    />}
                </div>
                : }*/}
            <div>
                <Header ruta="validacion_final" show={() => setShowHelp(true)} />
                <div className="main_text_container">
                    <h1 className="animated fadeIn delay-1s">Validación personal</h1>
                    <p className="animated fadeIn delay-2s"><b>Todos los agentes</b> se encuentran <b>ocupados</b>, puedes <b>esperar tu turno</b> en la fila por unos minutos.</p>
                    <br />
                    <div className="turno_bx"><p>{turno}</p></div>
                    <div className="turno_txt_bx">turno</div>
                    {loading ? <Loader /> : ("")}
                </div>
                <div className="action_buttons noscroll_screen">
                    <button onClick={e => {
                        setShowCancel(true);
                        sendEventClick('Validación por videollamada', 'CANCELAR', {});
                    }} className="btn btn-primary forcewidth100 main_color">
                        CANCELAR</button>
                    {//<button type="button" className="btn btn-raised btn-primary forcewidth100 main_bg_color animate__animated animate__fadeIn animate__delay-3s">NOTIFICARME EN MI TURNO</button>				
                    }
                </div>
            </div>
            {(showCancel) ?
                <div className="modal fade show" style={{ display: "block" }} role="dialog">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Cancelar proceso</h5>
                                <button onClick={e => setShowCancel(false)} className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <p>En este momento se cancelará el proceso, ningún dato será guardado y perderás el avance, esta acción no podrá deshacerse <br /> ¿Deseas cancelar?</p>
                            </div>
                            <div className="modal-footer">
                                <button onClick={e => { setShowCancel(false); sendEventClick('Validación por videollamada', 'NO', {}); }} className="btn btn-secondary" data-dismiss="modal">NO</button>
                                <button type="button" onClick={e => statusE()} className="btn btn-raised btn-primary main_bg_color">SI</button>
                            </div>
                        </div>
                    </div>
                </div> : ("")}
            {showHelp ? <Ayuda hide={() => setShowHelp(false)} /> : ""}
            <video ref={videoRef} playsInline autoPlay hidden />
        </div>
    )
}

export default ValidacionFinal