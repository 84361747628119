import React, { Fragment, useState, useEffect } from 'react'
import { Link, useLocation } from "react-router-dom";
import { configStyle, obtenerValorConfig } from '../services/configStyle'

const HeaderNA = (props) => {
    const location = useLocation();
    const [dataOtorgante, setDataOtorgante] = useState([]);
    const [pass, setPass] = useState(false)
    const [pathname, setpathname] = useState('');

    useEffect(() => {
        let dataOtorganteLS = localStorage.getItem("dataOtorgante")
        if (dataOtorganteLS != null) {
            setDataOtorgante(JSON.parse(dataOtorganteLS))
            configStyle(JSON.parse(dataOtorganteLS))
        }
        if (location.state) {
            if (location.state.passport) {
                setPass(true)
                //console.log("passaprote true");
            } else {
                setPass(false)
                //console.log("passaprote false");
            }
        }
        let host = localStorage.getItem("apikeyOtorgante");
        setpathname(host)
    }, []);

    useEffect(() => {
        configStyle(dataOtorgante)
    }, [dataOtorgante])

    return (
        <Fragment>
            <div id="head_container">
                <img id="imagenLogo" className="comp_logo" src={(obtenerValorConfig(dataOtorgante, 'LOGO_OTORGANTE', "") != "") ? obtenerValorConfig(dataOtorgante, 'LOGO_OTORGANTE', process.env.PUBLIC_URL + '/images/wl/comp_logo.png') : process.env.PUBLIC_URL + '/images/wl/comp_logo.png'} alt="" />
                {
                    /* (pathname === "Cw2qjkHFRvpLPryvUWXDcOPMEgHIYI9X" || pathname === "7d59c982-2ada-4630-9f93-c5093d6bf6bb") ? 
                    <Link to={{
                        pathname: '/ayuda/' + props.ruta,
                        state: { passport: pass  }
                        }} className="help_btn"><img src={process.env.PUBLIC_URL + '/images/help_icon.png'} alt="" /></Link>
                        :
                        "" */
                    //(pathname === "3DYrUMnehQLfahxKS78W75hfJYRS2cP6") ? 
                    <a className="help_btn"><img src={process.env.PUBLIC_URL + '/images/btn_circle_help_c2all.svg'} alt="" onClick={e => props.show()} /></a>
                    // :
                    // ""
                }
            </div>
        </Fragment>
    );
}

export default HeaderNA;