/* eslint-disable */
import React, { Component, Fragment } from 'react';
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import 'webrtc-adapter';
import { configStyle, obtenerValorUser } from '../services/configStyle';
import Loader from "./loader";
import { sendFile } from '../services/api.js';
import { isIOS, isMobile, withOrientationChange, isSafari, isAndroid } from 'react-device-detect';
import { status, statusData, calculateHeight, calculateWidth, enviarImagenHuella, enviarHuellas, evento } from '../services/data';
//import UserContext from '../services/UserContext';
// import { transform } from 'typescript';

let modelHandsMediaPipe;

class ComponenteCapturaHuellas extends Component {

    constructor(props) {
        super(props);
    }

    static propTypes = {
        match: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired
    };

    //static contextType = UserContext

    state = {
        loading: false,
        uuid: null,
        dataUser: [],
        dataOtorgante: [],
        dataOCRFront: {},
        isStart: false,
        side: "left",
        proccessing: false,
        desktop: false,
        altura: 960,
        ancho: 720,
        intentsFront: 0,
        intentsBack: 0,
        apikey: "",
        hasResponse: false,
        showHoloAnimation: true,
        intentsData: 0,
        errorF: "",
        errorMsg: false,
        errorVideo: false,
        streamStarted: false,
        pathname: "",
        invalidRight: false,
        invalidLeft: false,
        arrayHuellasIzq: [],
        arrayHuellasDer: [],
        camaraLabel: "",
        cameraSettings: "",
        verBtnPreview: false,
        preview: false,
        imageUrl: null,
        checkDisabled: false,
        capturing: false,
        huellaIzquierda: false,
        huelladDerecha: false,
        rutaIndiceIzquierdo: '',
        rutaIndiceDerecho: '',
        blobImage: null,
        coordenadasXPunto6: 0,
        coordenadasYPunto6: 0,
        coordenadasXPunto7: 0,
        coordenadasYPunto7: 0,
        coordenadasXPunto8: 0,
        coordenadasYPunto8: 0,
        errorFingerPrint: false
    }

    componentDidMount() {
        const component = this;

        let apikeyOtorgante = localStorage.getItem("apikeyOtorgante")
        if (apikeyOtorgante !== null) {
            this.setState({ apikey: apikeyOtorgante })
        }
        let uuidUser = localStorage.getItem("uuidUser")
        if (uuidUser != null) {
            this.setState({ uuid: uuidUser })
        }
        let dataUserLS = localStorage.getItem("data_user")
        if (dataUserLS != null) {
            this.setState({ dataUser: JSON.parse(dataUserLS) })
        }
        let dataOtorganteLS = localStorage.getItem("dataOtorgante")
        if (dataOtorganteLS != null) {
            let dataOtorganteJSON = JSON.parse(dataOtorganteLS)
            this.setState({ dataOtorgante: JSON.parse(dataOtorganteLS) })
            configStyle(dataOtorganteJSON)
        }
        this.state.pathname = window.location.pathname;
        //console.log("bloque: " + bloqueo);
        if (!isMobile) {
            //console.log("es desktop");
            this.state.desktop = true;
            this.state.altura = 720;
            this.state.ancho = 960;
            /*  var element = document.getElementById("myVideo");
             element.classList.add("myVideo_rotate"); */
            this.setState({ showHoloAnimation: false })
            this.setState({ isStart: true })
            var element = document.getElementById("video_wrt");
            element.classList.add("myVideo_rotate");
        } else {
            this.setState({ isStart: true, showHoloAnimation: false })
        }

        let deviceFuncional = "";
        navigator.mediaDevices.enumerateDevices().then(function (devices) {
            for (var i = 0; i < devices.length; i++) {
                var device = devices[i];
                if (device.kind === 'videoinput') {
                    if (device !== undefined) {
                        component.setState({ camaraLabel: device.label })
                        //console.log("deviceFuncional1", device);
                    }
                    if ((device.label).includes("back")) {
                        if ((device.label).includes("0")) {
                            deviceFuncional = device.deviceId;
                        }
                    }
                }
            }
            component.start(deviceFuncional);
        });
        let fingerFrame = document.getElementById('finger_frame');
        if (window.innerHeight >= 640) {
            fingerFrame.style.top = calculateHeight(window.innerHeight, 20, "string");
            fingerFrame.style.left = calculateWidth(window.innerWidth, 45, "string");
            fingerFrame.style.width = (calculateWidth(window.innerWidth, 45 / 1.5, "string"));
            fingerFrame.style.height = (calculateHeight(window.innerHeight, 30 / 1.5, "string"));

        } else {
            fingerFrame.style.top = calculateHeight(window.innerHeight, 22, "string");
            fingerFrame.style.left = calculateWidth(window.innerWidth, 45, "string");
            fingerFrame.style.width = (calculateWidth(window.innerWidth, 45 / 1.1, "string"));
            fingerFrame.style.height = (calculateHeight(window.innerHeight, 15 / 1.1, "string"));
        }

        status("captura_huellas", "Captura Huellas");

        modelHandsMediaPipe = this.props.modelHands;
        modelHandsMediaPipe.onResults(this.onResults);
    }

    start = (deviceFuncional) => {
        const that = this;
        if (window.stream) {
            //console.log("cerrando");
            window.stream.getTracks().forEach(track => {
                track.stop();
            });
        }
        const constraints = {
            audio: false,
            video: (deviceFuncional) ? {
                deviceId: { exact: deviceFuncional },
                width: { ideal: 640 },
                height: { ideal: 480 },
                advanced: [
                    { width: 1280, height: 720 },
                    { aspectRatio: 4 / 3 }
                ]
            } : {
                facingMode: "environment",
                width: { ideal: 640 },
                height: { ideal: 480 },
                advanced: [
                    { width: 1280, height: 720 },
                    { aspectRatio: 4 / 3 }
                ]
            }
        };

        //console.log("Reconfiguracion", constraints);
        that.localStream = navigator.mediaDevices.getUserMedia(constraints)
            .catch(
                (handleError) => {
                    that.setState({ errorVideo: true })
                }
            );

        that.canvas = document.querySelector('#canvas_video');
        that.width = 320;
        that.height = 0;
        that.video = document.querySelector('video');
        that.elemento = document.getElementById("cuadro");
        that.cuenta = document.getElementById("numeros");
        that.titulo = document.getElementById("head_shop");
        let component = that;
        that.localStream.then(function (mediaStream) {
            component.video.srcObject = mediaStream;
            component.video.onloadedmetadata = function (e) {
                component.video.play();
                if (mediaStream.getVideoTracks().length > 0) {
                    var videoTracks = mediaStream.getVideoTracks();
                    let constraints = JSON.stringify(videoTracks[0].getSettings())
                    component.setState({ streamStarted: true, errorVideo: false, cameraSettings: constraints })
                } else {
                    //console.log("No disponibles");    
                    component.setState({ errorVideo: true })
                }
            };
            component.video.addEventListener('canplay', function (ev) {
                component.height = component.video.videoHeight / (component.video.videoWidth / component.width);
                component.canvas.setAttribute('width', component.video.videoWidth * 1.5);
                component.canvas.setAttribute('height', component.video.videoHeight * 1.5);
            }, false);
        }).catch(e => {
            that.setState({ errorVideo: true })
        })
    }

    cancelarCapturaHuellas = () => {
        this.props.history.push("/preparacion_captura_huellas")
    }

    takeFotoLeft = () => {
        var urlCreator = window.URL || window.webkitURL;
        //let fingerFrame = document.getElementById('finger_frame');
        let x, y, width, height, altura, ancho = 0;
        let original = new MarvinImage();
        let image;
        let blobImage;
        const that = this;
        setTimeout(async () => {
            let contextCanvas = this.canvas.getContext('2d');
            contextCanvas.drawImage(this.video, 0, 0, this.video.videoWidth * 1.5, this.video.videoHeight * 1.5);
            await modelHandsMediaPipe.send({ image: this.canvas });
            altura = that.calcularDimensiones(that.state.coordenadasYPunto7, 145, 'sumar') - that.calcularDimensiones(that.state.coordenadasYPunto7, 145, 'restar');
            ancho = that.calcularDimensiones(that.state.coordenadasXPunto8, 120, 'sumar') - that.calcularDimensiones(that.state.coordenadasXPunto7, 55, 'restar');
            x = Math.ceil(that.calcularDimensiones(that.state.coordenadasXPunto7, 55, 'restar'));
            y = Math.ceil(that.calcularDimensiones(that.state.coordenadasYPunto7, 145, 'restar'));
            width = Math.ceil(ancho);
            height = Math.ceil(altura);
            if (that.state.coordenadasXPunto7 === 0 || that.state.coordenadasYPunto7 === 0 || that.state.coordenadasXPunto8 === 0 || that.state.coordenadasYPunto8 === 0) {
                this.setState({ loading: false, proccessing: false, capturing: false, errorFingerPrint: true });
                return;
            } else {
                await this.canvas.toBlob((blob) => {
                    original.load(urlCreator.createObjectURL(blob), () => {
                        image = original.clone();
                        Marvin.crop(original, image, x, y, width, height);
                        blobImage = image.toBlob();
                        blobImage.lastModifiedDate = new Date();
                        blobImage.name = that.generateDataImage('left').nombre;
                        this.setState({ blobImage: blobImage });
                        // const link = document.createElement('a')
                        // link.href = urlCreator.createObjectURL(blobImage)
                        // link.download = 'crop'
                        // document.body.appendChild(link)
                        // link.click()
                        // document.body.removeChild(link)
                    });
                });
                /*contextCanvas.strokeStyle = "#FF0000";
                contextCanvas.lineWidth = 10;
                let cuadroW = ((window.innerWidth)/4) * (this.video.videoWidth*1.5)/window.innerWidth
                let cuadroH = ((window.innerHeight)/8 ) * (this.video.videoHeight*1.5)/window.innerHeight
                let vajustew = ((2*(window.innerWidth)/3) * (this.video.videoWidth * 1.5))/window.innerWidth
                let vajusteh = (((window.innerHeight)/3) * (this.video.videoHeight * 1.5))/window.innerHeight
                contextCanvas.strokeRect(vajustew,vajusteh,cuadroW,cuadroH);*/
                contextCanvas.clearRect(0, 0, this.canvas.width, this.canvas.height);
                contextCanvas.drawImage(this.video, 0, 0, this.video.videoWidth * 1.5, this.video.videoHeight * 1.5);
                /* Cuadro huella */
                contextCanvas.beginPath();
                /* Lado izquierdo */
                contextCanvas.moveTo(that.state.coordenadasXPunto7 - 55, that.state.coordenadasYPunto7 + 145);
                contextCanvas.lineTo(that.state.coordenadasXPunto7 - 55, that.state.coordenadasYPunto7 + 25);
                contextCanvas.moveTo(that.state.coordenadasXPunto7 - 67.5, that.state.coordenadasYPunto7 + 145);
                contextCanvas.lineTo(that.state.coordenadasXPunto7 + 75, that.state.coordenadasYPunto7 + 145);
                contextCanvas.moveTo(that.state.coordenadasXPunto7 - 55, that.state.coordenadasYPunto7 - 145);
                contextCanvas.lineTo(that.state.coordenadasXPunto7 - 55, that.state.coordenadasYPunto7 - 25);
                contextCanvas.moveTo(that.state.coordenadasXPunto7 - 67.5, that.state.coordenadasYPunto7 - 145);
                contextCanvas.lineTo(that.state.coordenadasXPunto7 + 75, that.state.coordenadasYPunto7 - 145);
                /* Lado Derecho */
                contextCanvas.moveTo(that.state.coordenadasXPunto8 + 120, that.state.coordenadasYPunto7 + 145);
                contextCanvas.lineTo(that.state.coordenadasXPunto8 + 120, that.state.coordenadasYPunto7 + 25);
                contextCanvas.moveTo(that.state.coordenadasXPunto8 + 132.5, that.state.coordenadasYPunto7 + 145);
                contextCanvas.lineTo(that.state.coordenadasXPunto7 + 150, that.state.coordenadasYPunto7 + 145);
                contextCanvas.moveTo(that.state.coordenadasXPunto8 + 120, that.state.coordenadasYPunto7 - 145);
                contextCanvas.lineTo(that.state.coordenadasXPunto8 + 120, that.state.coordenadasYPunto7 - 25);
                contextCanvas.moveTo(that.state.coordenadasXPunto8 + 132.5, that.state.coordenadasYPunto7 - 145);
                contextCanvas.lineTo(that.state.coordenadasXPunto7 + 150, that.state.coordenadasYPunto7 - 145);
                contextCanvas.lineWidth = 25;
                contextCanvas.strokeStyle = '#70d404';
                contextCanvas.stroke();
                contextCanvas.closePath();
                // /* Cuadro recorte */
                /* contextCanvas.rect(x, y, width, height);
                contextCanvas.lineWidth = 15;
                contextCanvas.strokeStyle = 'red';
                contextCanvas.stroke(); */
                /* Puntos dedo 6,7,8 */
                /* contextCanvas.beginPath();
                contextCanvas.moveTo(that.state.coordenadasXPunto6, that.state.coordenadasYPunto6);
                contextCanvas.lineTo(that.state.coordenadasXPunto8, that.state.coordenadasYPunto8);
                contextCanvas.strokeStyle = 'blue';
                contextCanvas.lineWidth = 20;
                contextCanvas.stroke();
                contextCanvas.closePath();
                contextCanvas.drawImage(fingerFrame, x, y, width, height); */
                this.canvas.toBlob((blob) => {
                    blob.lastModifiedDate = new Date();
                    blob.name = "lefthandimage.png";
                    //arrayBacki.push(blob)
                    //console.log("name:" + blob.name);
                    //console.log("i:", i);
                    this.setState({ imageUrl: urlCreator.createObjectURL(blob) })
                    /* if (i === 0) {
                        var url = URL.createObjectURL(blob);
                        var a = document.createElement("a");
                        document.body.appendChild(a);
                        a.style = "display: none";
                        a.href = url;
                        a.download = "left" + i.toString() + ".png";
                        a.click();
                        window.URL.revokeObjectURL(url);
                    } */
                    //if (arrayBacki.length === 3) {
                    this.setState({ loading: false, hasTextFront: true, intentsFront: 0, intentsData: 0, preview: true, proccessing: false, checkDisabled: true, huellaIzquierda: true });
                    this.reposicionarMascara();
                    //}
                }, 'image/png', 0.95);
            }

            //     }
        }, 1350);

    }

    takeFotoRight = () => {
        //console.log("en foto");
        let x, y, width, height, altura, ancho = 0;
        let urlCreator = window.URL || window.webkitURL;
        //let fingerFrame = document.getElementById('finger_frame');
        let original = new MarvinImage();
        let image;
        let blobImage;
        const that = this;
        setTimeout(async () => {
            this.setState({ checkDisabled: false })
            //this.setState({ proccessing: true })
            //for (let i = 0; i < 3; i++) {
            //console.log("tomando foto der");
            let contextCanvas = this.canvas.getContext('2d');
            contextCanvas.drawImage(this.video, 0, 0, this.video.videoWidth * 1.5, this.video.videoHeight * 1.5);
            await modelHandsMediaPipe.send({ image: this.canvas });
            altura = that.calcularDimensiones(that.state.coordenadasYPunto7, 145, 'sumar') - that.calcularDimensiones(that.state.coordenadasYPunto7, 145, 'restar');
            ancho = that.calcularDimensiones(that.state.coordenadasXPunto7, 55, 'sumar') - that.calcularDimensiones(that.state.coordenadasXPunto8, 120, 'restar');
            x = Math.ceil(that.calcularDimensiones(that.state.coordenadasXPunto8, 120, 'restar'));
            y = Math.ceil(that.calcularDimensiones(that.state.coordenadasYPunto7, 145, 'restar'));
            width = Math.ceil(ancho);
            height = Math.ceil(altura);
            if (that.state.coordenadasXPunto7 === 0 || that.state.coordenadasYPunto7 === 0 || that.state.coordenadasXPunto8 === 0 || that.state.coordenadasYPunto8 === 0) {
                this.setState({ loading: false, proccessing: false, capturing: false, errorFingerPrint: true });
                return;
            } else {
                //setTimeout(() => {
                await this.canvas.toBlob((blob) => {
                    original.load(urlCreator.createObjectURL(blob), () => {
                        image = original.clone();
                        Marvin.crop(original, image, x, y, width, height);
                        blobImage = image.toBlob();
                        blobImage.lastModifiedDate = new Date();
                        blobImage.name = "righthandimage.png";
                        that.setState({ blobImage: blobImage });
                        // const link = document.createElement('a')
                        // link.href = urlCreator.createObjectURL(blobImage)
                        // link.download = 'crop'
                        // document.body.appendChild(link)
                        // link.click()
                        // document.body.removeChild(link)
                    });
                });
                //}, 1500);
                /*console.log("w:"+this.video.videoWidth);
                console.log("h:"+this.video.videoHeight);
                contextCanvas.strokeStyle = "#FF0000";
                contextCanvas.lineWidth = 10;
                let cuadroW = ((window.innerWidth)/4) * (this.video.videoWidth*1.5)/window.innerWidth
                let cuadroH = ((window.innerHeight)/8 ) * (this.video.videoHeight*1.5)/window.innerHeight
                let vajustew = (((window.innerWidth)/7) * (this.video.videoWidth * 1.5))/window.innerWidth
                let vajusteh = (((window.innerHeight)/3) * (this.video.videoHeight * 1.5))/window.innerHeight
                contextCanvas.strokeRect(vajustew,vajusteh,cuadroW,cuadroH);*/

                contextCanvas.clearRect(0, 0, this.canvas.width, this.canvas.height);
                contextCanvas.drawImage(this.video, 0, 0, this.video.videoWidth * 1.5, this.video.videoHeight * 1.5);
                /* Cuadro huella */
                contextCanvas.beginPath();
                /* Lado izquierdo */
                contextCanvas.moveTo(that.state.coordenadasXPunto7 + 55, that.state.coordenadasYPunto7 + 145);
                contextCanvas.lineTo(that.state.coordenadasXPunto7 + 55, that.state.coordenadasYPunto7 + 25);
                contextCanvas.moveTo(that.state.coordenadasXPunto7 + 67.5, that.state.coordenadasYPunto7 + 145);
                contextCanvas.lineTo(that.state.coordenadasXPunto7 - 75, that.state.coordenadasYPunto7 + 145);
                contextCanvas.moveTo(that.state.coordenadasXPunto7 + 55, that.state.coordenadasYPunto7 - 145);
                contextCanvas.lineTo(that.state.coordenadasXPunto7 + 55, that.state.coordenadasYPunto7 - 25);
                contextCanvas.moveTo(that.state.coordenadasXPunto7 + 67.5, that.state.coordenadasYPunto7 - 145);
                contextCanvas.lineTo(that.state.coordenadasXPunto7 - 75, that.state.coordenadasYPunto7 - 145);
                /* Lado Derecho */
                contextCanvas.moveTo(that.state.coordenadasXPunto8 - 120, that.state.coordenadasYPunto7 + 145);
                contextCanvas.lineTo(that.state.coordenadasXPunto8 - 120, that.state.coordenadasYPunto7 + 25);
                contextCanvas.moveTo(that.state.coordenadasXPunto8 - 132.5, that.state.coordenadasYPunto7 + 145);
                contextCanvas.lineTo(that.state.coordenadasXPunto7 - 150, that.state.coordenadasYPunto7 + 145);
                contextCanvas.moveTo(that.state.coordenadasXPunto8 - 120, that.state.coordenadasYPunto7 - 145);
                contextCanvas.lineTo(that.state.coordenadasXPunto8 - 120, that.state.coordenadasYPunto7 - 25);
                contextCanvas.moveTo(that.state.coordenadasXPunto8 - 132.5, that.state.coordenadasYPunto7 - 145);
                contextCanvas.lineTo(that.state.coordenadasXPunto7 - 150, that.state.coordenadasYPunto7 - 145);
                contextCanvas.lineWidth = 25;
                contextCanvas.strokeStyle = '#70d404';
                contextCanvas.stroke();
                contextCanvas.closePath();
                /* Cuadro recorte */
                /* contextCanvas.rect(x, y, width, height);
                contextCanvas.lineWidth = 15;
                contextCanvas.strokeStyle = 'red';
                contextCanvas.stroke(); */
                /* Puntos dedo 6,7,8 */
                /* contextCanvas.beginPath();
                contextCanvas.moveTo(that.state.coordenadasXPunto6, that.state.coordenadasYPunto6);
                contextCanvas.lineTo(that.state.coordenadasXPunto8, that.state.coordenadasYPunto8);
                contextCanvas.strokeStyle = 'blue';
                contextCanvas.lineWidth = 20;
                contextCanvas.stroke();
                contextCanvas.closePath(); */
                this.canvas.toBlob((blob) => {
                    blob.lastModifiedDate = new Date();
                    blob.name = "righthandimage.png";
                    // arrayBackd.push(blob)
                    // console.log("name:" + blob.name);
                    //console.log("i:", i);
                    this.setState({ imageUrl: urlCreator.createObjectURL(blob) })
                    // if (i === 0) {
                    //     var url = URL.createObjectURL(blob);
                    //     var a = document.createElement("a");
                    //     document.body.appendChild(a);
                    //     a.style = "display: none";
                    //     a.href = url;
                    //     a.download = "right" + i.toString() + ".png";
                    //     a.click();
                    //     window.URL.revokeObjectURL(url);
                    // }
                    //if (arrayBackd.length === 3) {
                    //console.log("arrayBackd:" + arrayBackd.length);
                    this.setState({ loading: false, preview: true, checkDisabled: true, huelladDerecha: true });
                    //}
                }, 'image/png', 0.95);
            }

            //}
        }, 1350);
    }

    initRecord = (side) => {
        this.setState({ side: side, loading: true, capturing: true })
        if (side === "right") {
            this.sendEventClick('CAPTURAR ÍNDICE DERECHO', {});
            this.takeFotoRight();
        } else {
            this.takeFotoLeft();
            this.sendEventClick('CAPTURAR ÍNDICE IZQUIERDO', {});
        }
    }
    // destroy player on unmount
    componentWillUnmount() {
        if (this.localStream) {
            this.localStream.then(function (mediaStream) {
                if (mediaStream) mediaStream.getTracks().forEach(track => track.stop());
            });
            this.localStream = null;
        }
        window.removeEventListener("beforeunload", () => {//console.log("Se quito componente video");
        });
    }

    capturarNext = () => {
        this.sendEventClick('GUARDAR', {});
        if (this.state.side === "left") {
            let rutaCompleta = `${this.generateDataImage('left').ruta}/${this.generateDataImage('left').nombre}`;
            this.setState({ rutaIndiceIzquierdo: rutaCompleta });
            enviarImagenHuella(this.state.blobImage, this.generateDataImage('left').ruta, this.generateDataImage('left').nombre);
            this.setState({ preview: false, side: "right", verBtnPreview: false, imageUrl: null });
        } else {
            let rutaCompleta = `${this.generateDataImage('right').ruta}/${this.generateDataImage('right').nombre}`;
            this.setState({ rutaIndiceDerecho: rutaCompleta });
            enviarImagenHuella(this.state.blobImage, this.generateDataImage('right').ruta, this.generateDataImage('right').nombre);
            enviarHuellas(this.state.rutaIndiceIzquierdo, rutaCompleta);
            setTimeout(() => {
                this.setState({ proccessing: true });
                setTimeout(() => {
                    this.props.history.push({
                        // pathname: '/finalizado'
                        pathname: '/preparacion_comprobante'
                    });
                }, 500);
            }, 300);
        }
    }

    capturarNuevo = () => {
        this.sendEventClick('CAPTURAR DE NUEVO', {});
        this.setState({ preview: false, verBtnPreview: false, capturing: false })
        //this.initRecord()
        let fingerFrame = document.getElementById('finger_frame');
        if (window.innerHeight >= 640) {
            fingerFrame.style.top = ((window.innerHeight * 20) / 100).toString() + "px";
            fingerFrame.style.width = (((window.innerWidth * 45) / 100) / 1.5).toString() + "px";
            fingerFrame.style.height = (((window.innerHeight * 30) / 100) / 1.5).toString() + "px";
            if (this.state.side === "left") {
                fingerFrame.style.left = ((window.innerWidth * 45) / 100).toString() + "px";
            } else {
                fingerFrame.style.left = ((window.innerWidth * 10) / 100).toString() + "px";
            }

        } else {
            fingerFrame.style.top = ((window.innerHeight * 22) / 100).toString() + "px";
            fingerFrame.style.width = (((window.innerWidth * 45) / 100) / 1.1).toString() + "px";
            fingerFrame.style.height = (((window.innerHeight * 15) / 100) / 1.1).toString() + "px";
            if (this.state.side === "left") {
                fingerFrame.style.left = ((window.innerWidth * 45) / 100).toString() + "px";
            } else {
                fingerFrame.style.left = ((window.innerWidth * 7) / 100).toString() + "px";
            }
        }
    }

    revisarAlto = () => {
        let anchoPantala = window.screen.width;
        if (isMobile) {
            if (isAndroid) {
                if (window.screen.height === anchoPantala) {
                    //console.log("true");
                    return true
                } else {
                    //console.log("false");
                    return false
                }
            } else {
                //console.log("SCREEN:"+window.screen.width + " " + window.screen.height);
                //console.log("INNER:"+window.innerWidth + " " + window.innerHeight);
                if (window.innerWidth !== window.screen.width) {
                    //console.log("true");
                    return true
                } else {
                    //console.log("false");
                    return false
                }
            }
        }
        else {
            function updateSize() {
                //console.log(window.innerWidth + " " + window.innerHeight);
            }
            window.addEventListener('resize', updateSize);
            updateSize();
            //return () => window.removeEventListener('resize', updateSize);
            //return size;
            return true;
        }
    }

    comprobarIndices = () => {
        if (this.state.invalidLeft) {
            this.setState({ loading: false, proccessing: false, side: "right", hasTextFront: true, intentsFront: 0, intentsData: 0, invalidRight: true });
        } else {
            this.capturarNext();
        }
    }

    reposicionarMascara = () => {
        let fingerFrame = document.getElementById('finger_frame');
        if (window.innerHeight >= 640) {
            fingerFrame.style.left = ((window.innerWidth * 10) / 100).toString() + "px";
        } else {
            fingerFrame.style.left = ((window.innerWidth * 7) / 100).toString() + "px";
        }
    }

    generateDataImage = (type) => {
        let objeto = {};
        let ruta = '';
        let nombre = '';
        /* Ruta */
        let uuidOtorgante = localStorage.getItem("uuidOtorgante") || '';
        let uuidUser = localStorage.getItem("uuidUser") || '';
        let uuidTrx = localStorage.getItem("uuidTrx") || '';
        /* Nombre */
        let cic = obtenerValorUser(this.state.dataUser, 'cic', null);
        let ocr = obtenerValorUser(this.state.dataUser, 'ocr', null);
        let claveElector = obtenerValorUser(this.state.dataUser, 'claveElector', null);
        let numeroEmision = obtenerValorUser(this.state.dataUser, 'numeroEmision', null);
        switch (type) {
            case 'left':
                ruta = `${uuidOtorgante}/${uuidUser}/${uuidTrx}/indice_izquierdo`;
                objeto.ruta = ruta;
                nombre = `${cic}-${ocr}-${claveElector}-${numeroEmision}-indice_izquierdo.png`;
                objeto.nombre = nombre;
                break;
            case 'right':
                ruta = `${uuidOtorgante}/${uuidUser}/${uuidTrx}/indice_derecho`;
                objeto.ruta = ruta;
                nombre = `${cic}-${ocr}-${claveElector}-${numeroEmision}-indice_derecho.png`
                objeto.nombre = nombre;
                break;
            default:
                break;
        }
        return objeto;
    }

    onResults = (results) => {
        if (results.multiHandLandmarks.length > 0) {
            this.setState({
                coordenadasXPunto6: (results.multiHandLandmarks[0][6].x * this.video.videoWidth * 1.5),
                coordenadasYPunto6: (results.multiHandLandmarks[0][6].y * this.video.videoHeight * 1.5),
                coordenadasXPunto7: (results.multiHandLandmarks[0][7].x * this.video.videoWidth * 1.5),
                coordenadasYPunto7: (results.multiHandLandmarks[0][7].y * this.video.videoHeight * 1.5),
                coordenadasXPunto8: (results.multiHandLandmarks[0][8].x * this.video.videoWidth * 1.5),
                coordenadasYPunto8: (results.multiHandLandmarks[0][8].y * this.video.videoHeight * 1.5),
            });
        } else {
            this.setState({
                coordenadasXPunto6: 0,
                coordenadasYPunto6: 0,
                coordenadasXPunto7: 0,
                coordenadasYPunto7: 0,
                coordenadasXPunto8: 0,
                coordenadasYPunto8: 0,
            });
        }
    }

    calcularDimensiones = (coordenada, numeroPixeles, type) => {
        let newTotal = 0;
        switch (type) {
            case 'sumar':
                newTotal = coordenada + numeroPixeles;
                break;
            case 'restar':
                newTotal = coordenada - numeroPixeles;
                break;
            default:
                break;
        }
        return newTotal;
    }

    sendEventClick = (description, objectInformation) => {
        let objeto = { description: description };
        let objetoEvento = {
            ...objeto,
            ...objectInformation
        }
        evento('Captura Huellas', 'Click', objetoEvento, true);
    }

    render() {
        const { isLandscape } = this.props;
        return (
            <div>

                <div className="module_container overflow_hddn desk_id_capture" style={{ position: 'fixed' }}>
                    <div className="camera_capture_frame">
                        <video id="video_wrt" playsInline></video>
                        <canvas id="canvas_video"></canvas>
                    </div>
                    <img className="finger-frame animate__animated animate__fadeIn animate__delay-2s" id="finger_frame" src='images/finger_frame.svg' alt="" />
                    <div className="module_gradient_overlay"></div>
                    <div className="module_title animate__animated animate__slideInDown">
                        {(this.state.side === "right") ?
                            (<p>Captura tu <b>índice derecho</b></p>) : (<p>Captura tu <b>índice izquierdo</b></p>)}
                    </div>

                    <div className="alert_instruction animate__animated animate__fadeIn animate__delay-1s">
                        <p>Alinea <b>tu huella</b> en la guía</p>
                    </div>

                    {/* <img className="ine_mask_cel animate__animated animate__fadeIn animate__delay-2s" src='/images/id_mask_capture_hollo.svg' alt="" /> */}

                    {(!this.state.loading) ? <img className="praw-print-instructions animate__animated animate-fadeInOut" src='/images/bnw/stable_b.svg' alt="" /> : ""}

                    <div className="module_icon_container animate__animated animate__fadeIn animate__delay-3s">

                        {(this.state.side === "left") ? <img src={process.env.PUBLIC_URL + '/images/left_scan.svg'} alt="" /> : <img src={process.env.PUBLIC_URL + '/images/right_scan.svg'} alt="" />}

                    </div>
                </div>

                {
                    (!this.state.preview) &&
                    <div className="module_buttons animate__animated animate__fadeIn animate__delay-1s" style={{ position: 'fixed' }}>
                        {(this.state.side === "left") ?
                            <button type="button" onClick={() => this.initRecord("left")} className={["btn btn-raised btn-primary forcewidth100 continuar", (this.state.capturing ? "gray_bg_color" : "main_bg_color"), (isMobile) ? "" : "desk_id_capture_btn"].join(" ")} disabled={this.state.proccessing || this.state.loading}>{(this.state.proccessing || this.state.loading ? "PROCESANDO..." : "CAPTURAR ÍNDICE IZQUIERDO")}</button>
                            :
                            (this.state.invalidRight && this.state.invalidLeft) || this.state.invalidRight ?
                                ""
                                :
                                <button type="button" onClick={() => this.initRecord("right")} className={["btn btn-raised btn-primary forcewidth100 main_bg_color continuar", (this.state.capturing ? "gray_bg_color" : "main_bg_color"), (isMobile) ? "" : "desk_id_capture_btn"].join(" ")} disabled={this.state.proccessing || this.state.loading}>{(this.state.proccessing || this.state.loading ? "PROCESANDO..." : "CAPTURAR ÍNDICE DERECHO")}</button>}
                        {/*(this.state.side === "left") ?
                                <button type="button" disabled={this.state.proccessing} onClick={() => { this.setState({ loading: false, proccessing: false, side: "right", hasTextFront: true, intentsFront: 0, intentsData: 0, invalidLeft: true }); this.reposicionarMascara() }} className={["btn btn-raised btn-primary forcewidth100 cancel_btn", (isMobile) ? "" : "desk_id_capture_btn"].join(" ")} >ÍNDICE IZQUIERDO IMPOSIBILITADO</button>
                                :
                                (this.state.invalidRight && this.state.invalidLeft) || this.state.invalidRight ?
                                    ""
                                    :
                                    <button type="button" disabled={this.state.proccessing} onClick={() => this.comprobarIndices()} className={["btn btn-raised btn-primary forcewidth100 cancel_btn", (isMobile) ? "" : "desk_id_capture_btn"].join(" ")}>ÍNDICE DERECHO IMPOSIBILITADO</button>
                */}
                    </div>}

                {(this.state.proccessing) && <Loader />}
                {/*(this.state.invalidRight && this.state.invalidLeft) || this.state.invalidRight ? <HuellasInvalidas /> : ("")*/}

                {/*((this.state.loading && !this.state.showHoloAnimation) ? <div className="sprite_stay_a animado"></div> : (""))*/}
                {((this.state.loading && !this.state.showHoloAnimation) && <Loader />)}
                {
                    (isLandscape && this.revisarAlto()) ? (
                        <div className="rotate_device">
                            <div className="center_info_bx">
                                <img src="images/rotate_device.svg" height="100" alt="" />
                                <p>Por favor usa tu dispositivo en vertical<br />
                                    <small>Gira tu dispositivo para continuar</small>
                                </p>
                            </div>
                        </div>
                    ) : ("")
                }
                {(this.state.intentsData === 1) ?
                    <div className="overlay_box">
                        <div className="alert_box">
                            <p className="animate__animated animate__slideInDown">Revisa tu conexión a internet y/o no se pudo leer correctamente tus huellas, por favor vuelve a intentar</p>
                            <button type="button" onClick={(e) => {
                                this.setState({ intentsData: 0 });
                                this.sendEventClick('VOLVER A INTENTAR', {});
                            }} className="btn btn-raised btn-primary forcewidth100 main_bg_color alert_btn  animate__animated animate__fadeIn animate__delay-2s">VOLVER A INTENTAR</button>
                        </div>
                    </div>
                    :
                    ""
                }
                {(this.state.errorMsg) ?
                    <div className="overlay_box">
                        <div className="alert_box">
                            <p className="animate__animated animate__slideInDown">Se ha detectado un bloqueo de red, verifica tu conexión de internet</p>
                            <button type="button" onClick={(e) => {
                                this.setState({ errorMsg: false });
                                this.sendEventClick('VOLVER A INTENTAR', {});
                            }} className="btn btn-raised btn-primary forcewidth100 main_bg_color alert_btn  animate__animated animate__fadeIn animate__delay-2s">VOLVER A INTENTAR</button>
                        </div>∫
                    </div>
                    :
                    ""
                }
                {(this.state.errorVideo) ?
                    <div className="overlay_box">
                        <div className="alert_box">
                            <p className="animate__animated animate__slideInDown">Hemos detectado que la <b>cámara de tu dispositivo</b> está siendo usada por otra web en <b>alguna pestaña</b>, por favor asegúrate de <b>cerrar las pestañas</b> abiertas e inténtalo nuevamente.</p>
                            <button type="button" onClick={(e) => {
                                this.setState({ errorVideo: false })
                                window.location.reload();
                                this.sendEventClick('VOLVER A INTENTAR', {});
                            }} className="btn btn-raised btn-primary forcewidth100 main_bg_color alert_btn  animate__animated animate__fadeIn animate__delay-2s">VOLVER A INTENTAR</button>
                        </div>
                    </div>
                    :
                    ""
                }
                {(this.state.preview) &&
                    <Fragment>
                        <div className="main_gradient">
                            <div className="module_container overflow_hddn fondo_negro">
                                <div className="module_gradient_overlay comp_dom_bg"></div>
                                <div className="module_title">
                                    <p><b>Verifica</b> tu captura</p>
                                </div>

                                <div className="alert_instruction"></div>

                                <img className={['id_comp_capture', (isIOS && window.innerHeight < 600) && 'scale_IOS'].join(' ')} src={this.state.imageUrl} width="283" alt="" />

                                {/* <img className="id_mask_preview" src="/images/doc_check_msk_c.png" alt="" /> */}

                                <div className="module_buttons">

                                    <button type="button" disabled={!this.state.verBtnPreview} className={["btn btn-raised", "btn-primary", "forcewidth100", (this.state.capturing ? "gray_bg_color" : "main_bg_color")].join(" ")} onClick={e => this.capturarNext()}>GUARDAR</button>
                                    <button type="button" className="btn btn-raised btn-primary forcewidth100 cancel_btn" onClick={e => this.capturarNuevo()}>CAPTURAR DE NUEVO</button>


                                </div>


                            </div>

                            <div className="check_container comp_box">

                                <form>
                                    {(isIOS || isSafari) ?
                                        <div>
                                            <label htmlFor="check_terms" className="custom-checkbox">
                                                <input id="check_terms" type="checkbox" onChange={e => { this.setState({ verBtnPreview: e.target.checked, capturing: !this.state.capturing }); evento('Captura Huella', 'Click', { description: `Confirmo que mi huella se ve clara y completa (${this.state.side})` }, true); }} disabled={!this.state.checkDisabled} />
                                                <span className="checkbox-decorator"><span className="check"></span></span>
                                                Confirmo que mi huella se ve clara y completa.
                                            </label>
                                        </div>
                                        :
                                        <div className="checkbox">
                                            <label htmlFor="check_terms">
                                                <input id="check_terms" type="checkbox" onChange={e => { this.setState({ verBtnPreview: e.target.checked, capturing: !this.state.capturing }); evento('Captura Huella', 'Click', { description: `Confirmo que mi huella se ve clara y completa (${this.state.side})` }, true); }} disabled={!this.state.checkDisabled} />
                                                <span className="checkbox-decorator"><span className="check"></span></span>
                                                Confirmo que mi huella se ve clara y completa.
                                            </label>
                                        </div>}
                                </form>

                            </div>
                        </div>
                    </Fragment>}
                {(this.state.errorFingerPrint &&
                    <div className="overlay_box">
                        <div className="alert_box">
                            <p className="animated slideInDown">No logramos detectar tu huella, separa tus dedos e intenta sobre un fondo claro libre de objetos.</p>
                            <button type="button" onClick={(e) => {
                                this.setState({ errorFingerPrint: false });
                                this.sendEventClick('VOLVER A INTENTAR', {});
                            }} className="btn btn-raised btn-primary forcewidth100 main_bg_color alert_btn  animated fadeIn delay-2s">VOLVER A INTENTAR</button>
                        </div>
                    </div>)}
            </div>
        );
    }


}
ComponenteCapturaHuellas = withOrientationChange(ComponenteCapturaHuellas)
export default withRouter(ComponenteCapturaHuellas)