import React, { useState, useEffect, Fragment, createContext } from "react";
import { useHistory, useParams } from "react-router-dom";
import { configStyle } from '../services/configStyle';
import { status, statusError, sendEventClick } from '../services/data';
import ComponenteCapturaHuellas from '../components/componente_captura_huellas';
import hands from '@mediapipe/hands';

const mediaPipeHands = new hands.Hands({
    locateFile: (file) => {
        return `https://cdn.jsdelivr.net/npm/@mediapipe/hands/${file}`;
    }
});

mediaPipeHands.setOptions({
    maxNumHands: 1,
    modelComplexity: 1,
    minDetectionConfidence: 0.5,
    minTrackingConfidence: 0.5,
});


const CapturaHuellas = () => {
    const history = useHistory();
    const [dataOtorgante, setDataOtorgante] = useState([]);
    const [apiKey, setApiKey] = useState('');

    useEffect( () => {
        let apikeyOtorgante = localStorage.getItem("apikeyOtorgante")
        if (apikeyOtorgante !== null) {
            setApiKey(apikeyOtorgante)
        }
        let dataOtorganteLS = localStorage.getItem("dataOtorgante")
        if (dataOtorganteLS != null) {
            setDataOtorgante(JSON.parse(dataOtorganteLS))
        }

        cargarModeloManos();
       
    }, []);

    const cargarModeloManos  = async () =>{
        let canvasElement = document.createElement('canvas');
        return await mediaPipeHands.send({ image: canvasElement });
    }

    useEffect(() => {
        configStyle(dataOtorgante)
    }, [dataOtorgante])

    return (
        <>
            <ComponenteCapturaHuellas modelHands={mediaPipeHands} />
        </>
    )

}


export default CapturaHuellas